<template>
    <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_stock_report")' >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div v-else class="drug-register-table table-container" style="width: 95.5vw; border-top-right-radius: 0;" :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
        <div class="filters">
          <div class="text-search-container search-container">
            <input v-model="form.filters.drug" placeholder="Drug..." style="position: relative" />
            <div class="drug-search-result-container" v-if='form.filters.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
              <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
            </div>
            
          </div>
          <!-- <div class="date-search-container search-container">
            <Calendar v-model="date" selectionMode="range" placeholder="Date..." maxDateCount=31 dateFormat="dd/mm/yy" />
          </div> -->
          <div class="select-filters-container">

                <p @click="()=>{filters.s8 = !filters.s8; autoAll()}" 
                v-bind:class="[filters.s8 == true ? 'active-select-filter' : '']" class="select-filter-item">Include S8</p>

                <p @click="()=>{form.filters.in_stock = !form.filters.in_stock;}" 
                v-bind:class="[form.filters.in_stock == true ? 'active-select-filter' : '']" class="select-filter-item">Display In Stock Only</p>
            </div>
            <button class="button submit-button" @click="loadReport()">Search</button>
            <button class="button clear-button" @click="clearFilteredDrugRegister()" style="margin-right: 2em">Clear</button>
            <button class="button clear-button"  v-if="$store.state.user.role.permissions.includes('can_export_stock_report')  && $store.state.currentLocation.state !='WA'" @click="downloadStockReport()" style=" background-color: #0870DC; color: white">Download CSV</button>
            <button class="button clear-button"  v-if="$store.state.user.role.permissions.includes('can_export_stock_report')" @click="printElem('transaction-table')" style="background-color: #0870DC; color: white">Print</button>
            <button v-if="$store.state.company.type !== 'warehouse'" class="button" @click="form.filters.imprest_only = !form.filters.imprest_only, loadReport()"
            :class="form.filters.imprest_only ? 'blue-button' : 'outline-button'"
            :style="form.filters.imprest_only ? { color: 'white' } : { color: '#0870DC' }" style="margin: 6px 0px 6px 10px !important; padding: 0 30px;"
            >Imprest Only</button>
            <!-- GRAPH TYPE -->
            <select @change="selectAGraph()" name="graphs" id="select-graph" style="font-size: 15px; height: 30px; border-radius: 4px; padding: 5px 25px;" v-show='toggleGraph'>
                <option value="Quantity In Stock" >Quantity In Stock</option>
                <option value="Quantity Used">Quantity Used</option>
            </select>
            <div style='margin-right: 20em'>

            </div>
            <!-- TOGGLE GRAPH/TABLE -->
            <!-- TOGGLE GRAPH/TABLE -->
            <!-- TOGGLE GRAPH/TABLE -->
            <!-- <div>
                <i class="pi-chart-bar pi toggle-icon" @click="toggleGraph = true; callGraphData('Quantity In Stock')"></i>
                <i class="pi-table pi toggle-icon" @click="toggleGraph = false"></i>
            </div> -->
            <!-- TOGGLE GRAPH/TABLE -->
            <!-- TOGGLE GRAPH/TABLE -->
            <!-- TOGGLE GRAPH/TABLE -->
        </div>
        <div class="drug-variances-container" style="display: flex;" v-show='$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length'>
          <p style="padding: 5px 10px; margin-right: 10px;">Refine your search: </p>
          <p v-for="(item, index) in $store.state.drugEntries.drug_aliases" :key="index" @click="aliasSearch(item)" class="drug-variance-option">{{item}}</p>
          <!-- <p style="padding: 5px 10px; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer" @click="aliasSearch('Endone 10mg')" >Endone 10mg</p> -->
        </div>
        <div style='overflow-y: scroll' v-show="!toggleGraph">
            <!-- <table class="data-table tx-table" cellspacing="0"> -->
              <!-- LOADING SPINNER -->
            <div v-if="$store.state.loadingStockTakeData">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
            </div>
            <table v-else  class="data-table tx-table" cellspacing="0">
                <tr class="table-headers">
                    <th style="width: 30em">
                    <h3 @click="sortBy('drug', 'desc')">Drug</h3>
                    <div v-if="form.col == 'drug'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort drug-sort" v-if="form.col =='drug' && form.sort_direction =='asc'" @click="sortBy('drug', 'desc')"></i>
                        <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug', 'asc')"></i>
                    </div>
                    </th>
                    <th v-if="$store.state.company.type != 'warehouse'">
                    <h3 @click="sortBy('resident', 'desc')">Resident</h3>
                    <div v-if="form.col == 'resident'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort resident-sort" v-if="form.col =='resident' && form.sort_direction =='asc'" @click="sortBy('resident', 'desc')"></i>
                        <i class="pi pi-sort resident-sort" v-else @click="sortBy('resident', 'asc')"></i>
                    </div>
                    </th>
                    <th style="max-width: 8em">
                    <h3 @click="sortBy('stock_level', 'desc')">Stock Level</h3>
                    <div v-if="form.col == 'stock_level'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort stock_level-sort" v-if="form.col =='stock_level' && form.sort_direction =='asc'" @click="sortBy('stock_level', 'desc')"></i>
                        <i class="pi pi-sort stock_level-sort" v-else @click="sortBy('stock_level', 'asc')"></i>
                    </div>
                    </th>
                    <th style="max-width: 8em">
                    <h3 @click="sortBy('qty_pending', 'desc')">Pending {{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}}</h3>
                    <div v-if="form.col == 'qty_pending'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort qty_pending-sort" v-if="form.col =='qty_pending' && form.sort_direction =='asc'" @click="sortBy('qty_pending', 'desc')"></i>
                        <i class="pi pi-sort qty_pending-sort" v-else @click="sortBy('qty_pending', 'asc')"></i>
                    </div>
                    </th>
                    <th>
                    <h3 style="max-width: 8em">Qty Safe</h3>
                    <div style="display: inline; cursor: pointer">
                    </div>
                    </th>
                    <!-- <th style="max-width: 8em">
                    <h3 @click="sortBy('qty_used', 'desc')">Qty Used</h3>
                    <div v-if="form.col == 'qty_used'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort qty_used-sort" v-if="form.col =='qty_used' && form.sort_direction =='asc'" @click="sortBy('qty_used', 'desc')"></i>
                        <i class="pi pi-sort qty_used-sort" v-else @click="sortBy('qty_used', 'asc')"></i>
                    </div>
                    </th> -->
                    <th>
                        <h3 @click="sortBy('created_at', 'desc')">Last Stock Take</h3>
                        <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
                            <i class="pi pi-sort  created_at-sort" v-if="form.col =='created_at' && form.sort_direction =='asc'" @click="sortBy('created_at', 'desc')"></i>
                            <i class="pi pi-sort created_at-sort" v-else @click="sortBy('created_at', 'asc')"></i>
                        </div>
                    </th>
                </tr>

            <tbody class="table-data" v-for="(item, index) in getDrugEntries.data" :key="index">
                <tr :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']" >
                    <td class="left-side-table">{{item.drug.slice(0,1).toUpperCase()}}{{item.drug.slice(1)}}</td>
                    <td v-if="$store.state.company.type != 'warehouse'">{{item.resident}}</td>
                    <td>{{item.stock_level}}</td>
                    <td>{{item.qty_pending}}</td>
                    <td>{{item?.stock_level + item?.qty_pending}}</td>
                    <!-- <td>{{item.qty_used}}</td> -->
                    <td>{{item.last_stocktake ? item.last_stocktake : '--'}}</td>
                </tr>
            </tbody>
            </table>
            <div v-if="$store.state.reportError">
              <h3 style="color: #E04F39">{{$store.state.reportError}}</h3>
            </div>
        </div>
        <!-- PRINT STOCK TABLE -->
        <div >
            <table class="data-table print-tx-table tx-table" id="transaction-table" cellspacing="0">
                <tr>
                    <th style="width: 25em">Drug</th>
                    <th style="width: 20em">Resident</th>
                    <th style="width: 10em">Stock Level</th>
                    <th style="width: 10em">Pending {{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}}</th>
                    <th style="width: 10em">Qty Safe</th>
                    <!-- <th style="width: 10em">Qty Used</th> -->
                    <th style="width: 15em">Last Stock Take</th>
                </tr>
                <tr v-for="(item, index) in getDrugEntries.data" :key="index">
                    <td>{{item.drug.slice(0,1).toUpperCase()}}{{item.drug.slice(1)}}</td>
                    <td>{{item.resident}}</td>
                    <td>{{item.stock_level}}</td>
                    <td>{{item.qty_pending}}</td>
                    <td>{{item?.stock_level + item?.qty_pending}}</td>
                    <!-- <td>{{item.qty_used}}</td> -->
                    <td>{{item.last_stocktake ? item.last_stocktake : '--'}}</td>
                </tr>
            </table>
        </div>
        
        <div class="pagination-links">
            <p class="pagination-arrow" v-show=" getDrugEntries.current_page > 1 " @click=" displayPage(form.page - 1) "> &larr;
            </p>
            <p>Page {{ getDrugEntries.current_page }} of {{ getDrugEntries.page_count }}</p>
            <p class="pagination-arrow" v-show=" getDrugEntries.current_page < getDrugEntries.page_count "
              @click=" displayPage(getDrugEntries.current_page + 1) "> &rarr; </p>
        </div>

        <!-- <div v-if='toggleGraph'>
            <QuantityInStock v-if="graphType =='Quantity In Stock'" :graphData="graphData" :lineOrBar="lineOrBar"  />
            <QuantityUsed v-else :graphData="graphData" :lineOrBar="lineOrBar"  />
        </div> -->
        
    </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
// import QuantityInStock from '@/components/graphs/QuantityInStock.vue';
// import QuantityUsed from '@/components/graphs/QuantityUsed.vue';

export default {
  components: {
      // QuantityInStock,
      // QuantityUsed
  },
  data(){
    return{
      drugNames: [],
      registerData: [],
      date:[],
      toggleGraph:false,
      graphType:'Quantity In Stock',
      lineOrBar: 'area',
      filters:{
        s8:true,
      },
      graphData:[],
      clearForm:{
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters:{
          tags:['s8'],
          drug:'',
          in_stock: true,
          date:{
            start:'',
            end:''
          },
        }
      },
      form:{
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters:{
          tags:['s8'],
          drug:'',
          in_stock: true,
          date:{
            start:'',
            end:''
          },
          imprest_only: false
        }
      },
      testData:[]
    }
  },
  methods:{
    // SORT DATA BY COLUMN AND ORDER
    callGraphData(graphType){
        if(graphType == 'Quantity In Stock'){
            this.lineOrBar = 'area';
            this.graphData= [{
                    name:'Quantity In Stock',
                    data: [{
                        x: "2018-09-19T00:00:00.000Z",
                        y: 54
                    }, {
                        x:  "2018-09-22T01:30:00.000Z",
                        y: 66
                    },
                    {
                        x:  "2018-09-24T02:30:00.000Z",
                        y: 213
                    },
                    {
                        x:  "2018-09-30T03:30:00.000Z",
                        y: 99
                    }],
                }]
        } 
        if(graphType == 'Quantity Used'){
            this.lineOrBar = 'bar';
            this.graphData= [{
                    name:'Quantity Used',
                    data: [{
                        x: "2018-09-19T00:00:00.000Z",
                        y: 200
                    }, {
                        x:  "2018-09-22T01:30:00.000Z",
                        y: 500
                    },
                    {
                        x:  "2018-09-24T02:30:00.000Z",
                        y: 300
                    },
                    {
                        x:  "2018-09-30T03:30:00.000Z",
                        y: 30
                    }],
                }]
        }
    },
    selectAGraph(){
        this.graphType = document.getElementById('select-graph').value;
        console.log('selected graph type', this.graphType);
        this.callGraphData(this.graphType);
    },
    autoAll(){
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if(value == true){
                this.form.filters.tags.push(key)
            }
        }
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getStockReportEntries', this.form)
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadReport(){
      console.log('this is the date', this.date);
      
      if(this.date.length){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
        }
        setTimeout(()=>{
          this.graphData= [{
                    name:'Quantity Used',
                    data: [{
                        x: "2018-09-19T00:00:00.000Z",
                        y: 900
                    }, {
                        x:  "2018-09-22T01:30:00.000Z",
                        y: 200
                    },
                    {
                        x:  "2018-09-24T02:30:00.000Z",
                        y: 900
                    },
                    {
                        x:  "2018-09-30T03:30:00.000Z",
                        y: 300
                    }],
                }]
      },3000)
      setTimeout(()=>{
          this.graphData= [{
                    name:'Quantity Used',
                    data: [{
                        x: "2018-09-19T00:00:00.000Z",
                        y: 200
                    }, {
                        x:  "2018-09-22T01:30:00.000Z",
                        y: 900
                    },
                    {
                        x:  "2018-09-24T02:30:00.000Z",
                        y: 100
                    },
                    {
                        x:  "2018-09-30T03:30:00.000Z",
                        y: 1600
                    }],
                }]
      },5000)
        this.$store.dispatch('getStockReportEntries', this.form);
      } else{
        this.form.filters.date.start ='';
        this.form.filters.date.end = '';
        this.$store.dispatch('getStockReportEntries', this.form);
      }
    },
    aliasSearch(alias){
      this.form.filters.drug = alias;
      this.$store.dispatch('getStockReportEntries', this.form);
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getStockReportEntries', this.form);
    },
    updateTransactionList(){
        this.form.filters.transactions = [];
        console.log('updating transaction list')
        this.selectedTransactions.forEach(transactionObject=>{
            for (const [key, value] of Object.entries(transactionObject)) {
                console.log('this is the key', key);
                this.form.filters.transactions.push(value.toLowerCase());
             }
        })
    },
    clearFilteredDrugRegister(){
      this.form = {
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters:{
          tags:['s8'],
          drug:'',
          in_stock: true,
          date:{
            start:'',
            end:''
          },
          imprest_only: false
        }
      };
      this.date = [];
      this.$store.dispatch('getStockReportEntries', this.form);
    },
    downloadStockReport(){
        let csv;

        axios.post('/export-stock-report', this.form, this.$store.state.header).then((res) => {
            csv = res.data;
        }).then(() => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = `stk_report_${new Date().toLocaleDateString().split('/').join('')}_${this.$store.state.currentLocation.name}.csv`;
          anchor.click();
        })
    },
    printElem(elem){
        console.log('this is the eleme', elem);
        window.print();
    }
  },
   mounted(){
    this.loadReport();
  },
  computed:{
    getDrugEntries (){
      // getStockReport
      return this.$store.getters.getStockReport
    },
    getDrugNames(){
      return this.$store.getters.getDrugNames
    }
  }
}
</script>