<template>
    <div style="padding: 23px; padding-left: 0px;" class="print">
        <div v-if="$store.state.user.role.permissions.includes('can_view_prescription')">
            <div class="filters" style="align-items: center; display: flex;">
                <SelectButton v-model="selected_view" :options="history_admin" @change="getRelevantData()" aria-labelledby="single" />
                <div class="date-search-container search-container" style="display: flex; align-items: center;">
                    <!-- <input type="date" v-if="selected_view == 'Admin History'" v-model="adminHistoryFormDate" placeholder="Date..." dateFormat="dd/mm/yy" /> -->
                    <Calendar v-if="selected_view == 'Admin History'" v-model="adminHistoryFormDate" selectionMode="range" placeholder="Date Range..." dateFormat="dd/mm/yy" />
                    <Calendar v-else v-model="date" selectionMode="range" placeholder="Ceased On Range..." dateFormat="dd/mm/yy" />
                    <p class="blue-button" style="margin-left: 5px" @click="selected_view == 'Rx History' ? sortMedicationCharts() : sortAdministrations()">Update</p>
                </div>
                <div class="text-search-container search-container">
                    <Dropdown v-if="selected_view == 'Rx History'" @change="sortMedicationCharts()  " v-model="form.filters.drug" :options="drugs"
                        placeholder="Medication" style="position: relative" />
                    <Dropdown v-else @change="sortAdministrations() " v-model="adminHistoryForm.drug" :options="drugs"
                        placeholder="Medication" style="position: relative" />
                </div>
                <div v-if="selected_view == 'Rx History'" class="text-search-container search-container">
                    <Dropdown @change="sortMedicationCharts()" v-model="form.filters.route" :options="routes"
                        placeholder="Route" style="position: relative" />
                </div>
                <div v-if="selected_view == 'Rx History'" class="text-search-container search-container">
                    <Dropdown v-model="form.filters.type" :options="typeOptions" optionValue="chart_name" optionLabel="name"
                        @change="sortMedicationCharts()" placeholder="Type" style="position: relative" />
                </div>
                <button v-tooltip.bottom="'Reset Filters'" class="black-button" @click="clearFilters()">
                    Clear
                </button>
                <!-- <button v-tooltip.bottom="'Download'" class="button clear-button"
                    @click="displayExportCSVModal = true, loadReport()"
                    style="background-color: transparent; color: white; margin-top: 0px;"><i
                        class="pi pi-download"></i></button> -->
                <button v-tooltip="'Print'" class="button clear-button" @click="printElem()"
                    style="margin-right: 1em; background-color: transparent; color: white" v-if="selected_view =='Admin History'">
                    <i class="pi pi-print" style="font-size: 1.1em !important; border-radius: 50%; padding: 8px; background-color: #0870DC; margin-top: -4px;"></i>
                </button>
            </div>
            <div v-if="selected_view == 'Rx History'">
                <div style="padding-top: 10px">
                    <div v-if="loading">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    </div>
                    <table v-else class="medication-history-table">
                        <tr>
                            <th style="width: 8em">Start Date</th>
                            <th style="width: 8em">Ceased On</th>
                            <th>Medication</th>
                            <th>Frequency</th>
                            <th>Route</th>
                            <th>Prescriber</th>
                            <!-- <th>Notes</th> -->
                            <th>Type</th>
                        </tr>
                        <!-- @click="displayExpiredChartModal = true; displayChart = item" -->
                        <tr v-for="(item, index) in charts" :key="index" >
                            <td>{{ item.start_date.slice(0,-7) }}</td>
                            <td>{{ item.deleted_at ? item.deleted_at.slice(0,-7) : item.stop_date.slice(0,-7) }}</td>
                            <td>{{ item.alias.name }} <span style="font-weight: bold">x {{ item.dose }} {{item.dose_unit}}</span></td>
                            <td>{{item.frequency_shorthand}}</td>
                            <td>{{ item.route }}</td>
                            <td>{{ item.signatures.length ? item.signatures[0].person : '' }}</td>
                            <!-- <td>{{item.notes}}</td> -->
                            <td>
                                <div v-tooltip.left="item.chart_name" style="height: 20px; width: 20px; border-radius: 3px;"
                                    :style="{ backgroundColor: item.color + ' !important' }"></div>
                            </td>
                            <td><p v-if="$store.state.user.type === 'prescriber'" class="green-button" style="margin-right: 10px;" @click="mappingChart(item), setChartType(item)">Renew</p></td>
                        </tr>
                    </table>
                </div>
                <div class="pagination-links">
                    <p class="pagination-arrow" v-show="pages.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
                    <p>Page {{ pages.current_page }} of {{ pages.page_count }}</p>
                    <p class="pagination-arrow" v-show="pages.current_page < pages.page_count"
                        @click="displayPage(pages.current_page + 1)"> &rarr; </p>
                </div>
            </div>
            <div v-if="selected_view =='Admin History'" class="print">
                <div style="padding-top: 10px">
                    <div v-if="loading">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    </div>
                    <div v-else>
                        <div v-if="!administrations.length">
                            <h3>No administrations found.</h3>
                        </div>
                        <table v-else class="medication-history-table">
                            <tr>
                                <th>Date & Time</th>
                                <th>Type</th>
                                <th>Drug</th>
                                <th>Quantity</th>
                                <th>Administered</th>
                                <th>DOC</th>
                                <th>Administrator</th>
                                <th>Notes</th>
                            </tr>
                            <tr v-for="(item, index) in administrations" :key="index" :style="!item.quantity ? {backgroundColor: 'rgba(224, 79, 57, 0.2)'} : {}">
                                <td>{{ item.administered_at}}</td>
                                <td>
                                    <div v-tooltip.left="item.chart_name" style="height: 20px; width: 20px; border-radius: 3px;"
                                        :style="{ backgroundColor: item.color + ' !important' }"></div>
                                </td>
                                <td><span class="red-tag" v-if="!item.quantity">EXPIRED MED</span> {{ item.drug.name }}</td>
                                <td>{{ item.quantity ? item.quantity : '-' }}</td>
                                <td>{{item.quantity ? (!item.doc && item.administrator ? 'Y' : 'N') : '-'}} 
                                    <span v-tooltip.bottom="`This administration event was reverted on ${item.deleted_at}`" class="red-tag" v-if="item.deleted_at">
                                        (Reverted {{ item.deleted_at }})
                                        <i style="padding: 0px;" class="pi pi-question-circle"></i>
                                    </span>
                                </td>
                                <td>{{ item.doc ? item.doc : '-' }}</td>
                                <td>{{item.administrator && item.administrator.personal_information && item.administrator.personal_information.first_name ? item.administrator.personal_information.first_name : ''}} 
                                    {{item.administrator && item.administrator.personal_information && item.administrator.personal_information.last_name ? item.administrator.personal_information.last_name : ''}}</td>
                                <td><p class="blue-button" v-if="item.note" 
                                style="padding: 5px 0px;
                                    width: fit-content;
                                    display: flex;
                                    align-items: center;" @click="modalNotes = item.note, additionalNotes=item.additional_notes, displayNotesModal = true" ><i class="pi pi-align-justify" ></i></p></td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
                <div class="pagination-links" v-if="administrations.length && !this.loading">
                    <p class="pagination-arrow" v-show="pages.current_page > 1" @click="displayAdminPage(adminHistoryForm.page - 1)"> &larr; </p>
                    <p>Page {{ pages.current_page }} of {{ pages.page_count }}</p>
                    <p class="pagination-arrow" v-show="pages.current_page < pages.page_count"
                        @click="displayAdminPage(pages.current_page + 1)"> &rarr; </p>
                </div>
            </div>
            <PrescriptionRenewModal :renewScript="true" :hideClientPanel="true" v-if="displayPrescriptionRenewModal" :renewingCancelledScript="true" @close="close" :prescription="mappedPrescription" @successfullySubmitted="successfullySubmitted" />
            <ExpiredChartModal :chart="displayChart" v-if="displayExpiredChartModal" @close="close" :frequencyOptions="frequencyOptions" />
            <AdminHistoryNotesModal v-if="displayNotesModal" :notes="modalNotes" :additionalNotes="additionalNotes" @close="close" />
        </div>
        <div v-else style="margin-top: 10em;">
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                You do not have permission to access this feature. Please talk to your admin if you require access.
            </h3>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ExpiredChartModal from '@/components/modals/ExpiredChartModal.vue';
import AdminHistoryNotesModal from '@/components/modals/AdminHistoryNotesModal.vue';
import PrescriptionRenewModal from '@/components/modals/PrescriptionRenewModal.vue';

export default {
    props: ['patient', 'frequencyOptions'],
    components: {
        ExpiredChartModal,
        AdminHistoryNotesModal,
        PrescriptionRenewModal
    },
    data() {
        return {
            displayPrescriptionRenewModal: false,
            mappedPrescription: null,
            displayNotesModal: false,
            modalNotes: '',
            additionalNotes: null,
            selected_view:'Admin History',
            history_admin:[
                'Admin History',
                'Rx History',
            ],
            displayExpiredChartModal: false,
            displayChart: '',
            loading: false,
            typeOptions: [],
            charts: [],
            drugs: [],
            frequencies: [],
            types: [],
            routes: [],
            date: [],
            adminHistoryFormDate:[],
            administrations:[],
            pages: {
                current_page: 1,
                page_count: 1,
            },
            form: {
                page: 1,
                filters: {
                    drug: '',
                    type: '',
                    route: '',
                    date: {
                        start: '',
                        end: ''
                    }
                },
                /**
                 * Sending deleted administration events to rx history is redundant
                 * as they don't show up in the table anyway
                 */
                include_deleted: false
            },
            adminHistoryForm:{
                drug:'',
                date:{
                    start:'',
                    end:''
                },
                page:1
            }

        }
    },
    methods: {
        close(value) {
            if (value) {
                this.displayExpiredChartModal = false;
                console.log('expired chart should be closed');
                this.displayNotesModal = false;
                this.displayPrescriptionRenewModal = false;
            }
        },
        mappingChart(value){
            console.log('mapping chart', value, this.patient);
            value.activePresChart = {
                chart_payload: {
                    prescription_id: value.uuid,
                    chart_form: {
                        type: value.chart_type,
                        route: value.route,
                        indication: value.indication,
                        tags: []
                    }
                },
                prescription_payload: {
                    scheduled: value.alias.tags.includes('S8') ? true : false,
                    patient_id: this.patient.uuid,
                    patient: this.patient,
                    prescriber: value.doctor?.uuid ? value.doctor?.uuid : '',
                    drug_uuid: value.alias.alias_id,
                    substitution_not_permitted: value.substitution_not_permitted ? true : false,
                    chart_id: value.chart_id,
                    authority_prescription_number: value.authority_prescription_number ? value.authority_prescription_number  : '',
                    pbs_code: value.pbs_code ? value.pbs_code  : '',
                    pbs_manufacturer_code: value.pbs_manufacturer_code ? value.pbs_manufacturer_code  : '',
                    pbs_authority_code: value.pbs_authority_code ? value.pbs_authority_code  : '',
                    days_of_week: value.days_of_week,
                    interval: value.interval,
                    num_repeats_authorised: value.num_repeats_authorised,
                    repeat_interval: value.repeat_interval,
                    frequency: value.frequency,
                    frequency_shorthand: value.frequency_shorthand,
                    times: value.times,
                    dose: value.dose,
                    dose_unit: value.dose_unit,
                    route: value.route,
                    sliding_scale: value.sliding_scale,
                    max_admin_dose: value.max_admin_dose,
                    max_daily_admin: value.max_daily_admin,
                    is_paper: value.is_paper ? true : false,
                    paper_prescription_number : value.paper_prescription_number ? value.paper_prescription_number : value.is_paper ? '1' : '',
                    reason: value.reason,
                    max_dose: value.max_dose,
                    start_date: new Date(value.start_date).toLocaleDateString('en-AU'),
                    stop_date: new Date(value.stop_date).toLocaleDateString('en-AU'),
                    additional_regulatory_checks: '',
                    administrations: value.administrations,
                    // created_date: !isNaN(Date.parse(value.created_at)) ? new Date(value.created_at).toLocaleDateString('en-AU') : '' ,
                    created_date: new Date(value.created_at).toLocaleDateString('en-AU'),
                    dose_times : value.dose_times,
                    last_administration: value.last_administration ? value.last_administration  :'',
                    administration_history: value.administration ? value.administration : '',
                    prescribed_quantity: value.prescribed_quantity,
                    notes: value.instructions && value.instructions.text ? value.instructions.text : '',
                    chart_tags: value.chart_tags,
                    signed: true
                }
            };
            value.patient = this.patient;
            console.log('mapped chart', value);
            this.mappedPrescription = value;
            this.displayPrescriptionRenewModal = true;
        },
        setChartType(value){
            let chartTypes = this.$store.getters.getChartTypes;
            chartTypes.forEach(chartType => {
                if ((value.chart_type == chartType.chart_name) || (value.chart_type == chartType.name)) {
                    console.log('LOOPING THROUGH THE CHART ITEMS', value.chart_type, chartType.chart_name);
                    value.chart_type = chartType.chart_name;
                    value.type = chartType.chart_name;
                    value.color = chartType.color;
                    value.chart_name = chartType.name;
                    value.selectedChart = chartType;
                    console.log('this is the color now', value.color);
                }
            })
        },
        printElem() {
            
            // window.print();
            // let elementToPrint = document.getElementById('medication-history-table');
            // let cloned = elementToPrint.cloneNode(true);
            // document.body.appendChild(cloned);
            // cloned.classList.add("printable");
            // console.log('this is the eleme', cloned);
            // window.print();
            // document.body.removeChild(cloned);
            var mywindow = window.open('', 'PRINT', 'height=600,width=1000');

            mywindow.document.write('<html><head><title>' + 'ADMINISTRATION HISTORY' + '</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<table class="medication-history-table">');
            mywindow.document.write('<tr>');
            mywindow.document.write('<th style="width: 10em"> Date & Time </th>');
            mywindow.document.write('<th style="width: 20em"> Drug </th>');
            mywindow.document.write('<th> Quantity </th>');
            mywindow.document.write('<th> Administered</th>');
            mywindow.document.write('<th style="width: 5em"> DOC </th>');
            mywindow.document.write('<th > Administrator </th>');
            mywindow.document.write('</tr>');
            this.administrations.forEach(admin=>{
                mywindow.document.write('<tr>');
                mywindow.document.write('<td>'+admin.administered_at+'</td>');
                mywindow.document.write('<td>'+admin.drug.name+'</td>');
                mywindow.document.write('<td>'+admin.quantity+'</td>');
                if(!admin.doc && admin.administrator){
                    if (admin.deleted_at) mywindow.document.write('<td>'+'Y '+`(Reverted ${admin.deleted_at})`+'</td>');
                    else mywindow.document.write('<td>'+'Y'+'</td>');
                } else{
                    if (admin.deleted_at) mywindow.document.write('<td>'+'N '+`(Reverted ${admin.deleted_at})`+'</td>');
                    else mywindow.document.write('<td>'+'N'+'</td>');
                }
                if(admin.doc){
                    mywindow.document.write('<td style="width: 5em; text-align: center">'+admin.doc+'</td>');
                } else{
                    mywindow.document.write('<td style="width: 5em; text-align: center">'+'--'+'</td>');
                }
                if(admin.administrator && admin.administrator.personal_information && admin.administrator.personal_information.first_name){
                    mywindow.document.write('<td>'+admin.administrator.personal_information.first_name+ ' '+admin.administrator.personal_information.last_name+'</td>')
                } else{
                    mywindow.document.write('<td>'+'N/A'+'</td>');
                }
                // mywindow.document.write('<td>'+admin.administrator && admin.administrator.personal_information && admin.administrator.personal_information.first_name ? admin.administrator.personal_information.first_name + ' '+admin.administrator.personal_information.last_name : 'N/A' +'</td>');
                mywindow.document.write('</tr>');
            })
            mywindow.document.write('</table>');
            // mywindow.document.write(elementToPrint);
            // <table v-else id="medication-history-table">
            //     <tr>
            //         <th>Date & Time</th>
            //         <th>Drug</th>
            //         <th>Quantity</th>
            //         <th>Administered</th>
            //         <th>DOC</th>
            //         <th>Administrator</th>
            //     </tr>
            //     <tr v-for="(item, index) in administrations" :key="index">
            //         <td>{{ item.administered_at}}</td>
            //         <td>{{ item.drug.name }}</td>
            //         <td>{{ item.quantity }}</td>
            //         <td>{{ !item.doc && item.administrator ? 'Y' : 'N'}}</td>
            //         <td>{{ item.doc ? item.doc : '-' }}</td>
            //         <td>{{item.administrator && item.administrator.personal_information && item.administrator.personal_information.first_name ? item.administrator.personal_information.first_name : ''}} 
            //             {{item.administrator && item.administrator.personal_information && item.administrator.personal_information.last_name ? item.administrator.personal_information.last_name : ''}}</td>
            //     </tr>
            // </table>
            mywindow.document.write('</body></html>');

            // mywindow.document.close(); // necessary for IE >= 10
            // mywindow.focus(); // necessary for IE >= 10*/

            // window.print();
            // mywindow.close();

            // return true;
        },
        getRelevantData(){
            if(this.selected_view == 'Rx History'){
                this.retrieveMedicationHistory();
            } else{
                this.retrieveAdministrationHistory();
            }
        },
        clearFilters() {
            this.date= []
            this.form = {
                page: 1,
                filters: {
                    drug: '',
                    type: '',
                    route: '',
                    date: {
                        start: '',
                        end: ''
                    }
                }
            }
            this.adminHistoryFormDate= '';

            this.adminHistoryForm = {
                drug:'',
                date:{
                    start:'',
                    end:''
                },
                page:1
            }
            if(this.selected_view == 'Admin History'){
                this.retrieveAdministrationHistory();
            } else{
                this.retrieveMedicationHistory();
            }
            
        },
        displayPage(page) {
            this.$store.dispatch('getRefreshToken');
            this.form.page = page;
            this.retrieveMedicationHistory();
        },
        displayAdminPage(page) {
            this.$store.dispatch('getRefreshToken');
            this.adminHistoryForm.page = page;
            this.retrieveAdministrationHistory();
        },
        sortMedicationCharts() {
            this.$store.dispatch('getRefreshToken');
            if (this.date.length) {
                if (typeof this.date == "string") {
                    this.form.filters.date.start = this.date.split("-")[0]
                    this.form.filters.date.end = this.date.split("-")[1]
                } else {
                    
                    this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
                    this.form.filters.date.end = (this.date[1] == null) ? new Date(this.date[0]).toLocaleDateString('en-AU') : new Date(this.date[1]).toLocaleDateString('en-AU');
                }
            } else {
                this.form.filters.date.start = '';
                this.form.filters.date.end = '';
            }
            console.log('this is the filter', this.form.filters);
            this.retrieveMedicationHistory();
        },
        sortAdministrations() {
            this.$store.dispatch('getRefreshToken');
            if (this.adminHistoryFormDate.length) {
                if (typeof this.adminHistoryFormDate == "string") {
                    this.adminHistoryForm.date.start = this.adminHistoryFormDate.split("-")[0]
                    this.adminHistoryForm.date.end = this.adminHistoryFormDate.split("-")[1]
                } else {
                    this.adminHistoryForm.date.start = new Date(this.adminHistoryFormDate[0]).toLocaleDateString('en-AU');
                    this.adminHistoryForm.date.end = (this.adminHistoryFormDate[1] == null) ? new Date(this.adminHistoryFormDate[0]).toLocaleDateString('en-AU') : new Date(this.adminHistoryFormDate[1]).toLocaleDateString('en-AU');
                }
            } else {
                this.adminHistoryForm.date.start = '';
                this.adminHistoryForm.date.end = '';
            }
            // if (this.adminHistoryFormDate && this.adminHistoryFormDate.includes('-')) {
            //     this.adminHistoryForm.date = new Date(this.adminHistoryFormDate).toLocaleDateString('en-AU');
            // } 
            console.log('this is the filter', this.form.filters);
            this.retrieveAdministrationHistory();
        },
        retrieveAdministrationHistory(){
            this.$store.dispatch('getRefreshToken');
            this.administrations = [];
            this.loading = true;
            let payload = {
                filters: this.adminHistoryForm,
                page: this.adminHistoryForm.page,
                include_deleted: true // the deleted will be labelled as 'undone' in the Admin hx table
            }
            delete payload.filters.page;
            axios.post(`/person/${this.$route.params.id}/administrations`,payload, this.$store.state.header).then(res=>{
                console.log('this is the admin history', res.data);
                this.administrations = res.data.data;
                this.pages.current_page = res.data.header.current_page;
                this.pages.page_count = res.data.header.page_count;
                let chartTypes = this.$store.getters.getChartTypes;
                for (let i = 0; i < this.administrations.length; i++) {
                    this.drugs.push(this.administrations[i].drug.name);

                    if(this.administrations[i].prescription_type) {
                        if(this.administrations[i].prescription_type == 'nim') {
                            
                            this.administrations[i].chart_type = 'nim';
                            this.administrations[i].color = "#274188"
                            this.administrations[i].chart_name = "Nurse Initiated Meds";
                        } else {
                            chartTypes.forEach(chartType => {
                                if ((this.administrations[i].prescription_type == chartType.chart_name) || (this.administrations[i].prescription_type == chartType.name)) {
                                    this.administrations[i].chart_type = chartType.chart_name;
                                    this.administrations[i].color = chartType.color;
                                    this.administrations[i].chart_name = chartType.name;
                                }
                            })
                        }
                    }
                }
                this.getFilterValues();
                this.loading = false;
            })
        },
        retrieveMedicationHistory() {
            this.$store.dispatch('getRefreshToken');
            this.loading = true;
            axios.post(`/person/${this.$route.params.id}/expired-prescriptions`, this.form, this.$store.state.header).then(res => {
                
                this.charts = res.data.prescriptions;
                this.pages.current_page = res.data.header.current_page;
                this.pages.page_count = res.data.header.page_count;
                console.log('this is the patient history', res.data);

                
            }).then(() => {
                // MAPPING CHART COLOURS AND FILLING IN FILTER ARRAYS  (DUPLICATES ARE FIXED IN GET FILTER VALUES)
                console.log('GETTING MEDICATION HISTORY');
                // this.charts.forEach(chartItem=>{
                // this.drugs.push(chartItem.alias.name);
                // this.frequencies.push(chartItem.frequency);
                // this.types.push(chartItem.chart.chart_type);
                // this.routes.push(chartItem.route);

                let chartTypes = this.$store.getters.getChartTypes;
                for (let i = 0; i < this.charts.length; i++) {
                    this.drugs.push(this.charts[i].alias.name);
                    this.frequencies.push(this.charts[i].frequency);
                    this.types.push(this.charts[i].chart_type);
                    this.routes.push(this.charts[i].route);
                    chartTypes.forEach(chartType => {
                        if ((this.charts[i].chart_type == chartType.chart_name) || (this.charts[i].chart_type == chartType.name)) {
                            console.log('LOOPING THROUGH THE CHART ITEMS', this.charts[i].chart_type, chartType.chart_name);
                            this.charts[i].chart_type = chartType.chart_name;
                            this.charts[i].color = chartType.color;
                            this.charts[i].chart_name = chartType.name;
                            console.log('this is the color now', this.charts[i].color);
                        }
                    })
                    console.log('looping through this this.charts[i]', this.charts[i], this.charts.length);
                    // this.remapPresChart(this.charts[i]);
                }
                // });
                this.loading = false;
                this.getFilterValues();
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
            // setTimeout(() => {


            // }, 500);

        },
        // GETFILTERVALUES FUNCTION DROPS ALL DUPLICATES IN FILTER ARRAYS
        getFilterValues() {
            if(this.drugs.length){
                this.drugs = this.drugs.filter((v, i, a) => a.indexOf(v) === i);
            }
            if(this.routes.length){
                this.routes = this.routes.filter((v, i, a) => a.indexOf(v) === i);
            }
            if(this.types.length){
                this.types = this.types.filter((v, i, a) => a.indexOf(v) === i);
            }
            if(this.frequencies.length){
                this.frequencies = this.frequencies.filter((v, i, a) => a.indexOf(v) === i);
            }
            
            if(this.types.length){
                let chartTypes = this.$store.getters.getChartTypes;
                this.types.forEach(type => {
                    chartTypes.forEach(chartType => {
                        if (type == chartType.chart_name) {
                            this.typeOptions.push(chartType);
                            console.log('this is the list of TYPES', this.typeOptions);
                        }
                    })
                })
            }
            
        },
    },
    mounted() {
        this.retrieveAdministrationHistory();
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.medication-history-table {
    margin: 0;
    padding: 5px;
    border-collapse: collapse;
    background-color: #F3F4F4;
    border-radius: 12px;

    th,
    td {
        padding: 10px 0px 10px 10px !important;
        font-size: 1em;
    }

    ;
    border-collapse: collapse;

    th {
        border-bottom: 2px solid #E7E9E8;
    }

    td {
        border-bottom: 1px solid #E7E9E8;
    }

}

@media print {
  body *:not(.printable) {
    // hide everything but printable elements and their children
    display: none;
  }
}
</style>
