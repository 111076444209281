<template>
    <div class="active-chart-table-container" style="display: flex; flex-wrap: wrap; margin-left: 0; background-color: white; margin-left: 1em; border-radius: 8px; overflow-y: auto">
        <div class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin-left: 0; width: 100%; border-radius: 8px;" :style="loadingPatient || !patient ? {backgroundColor: '#f3f4f4'}:{}">
         <div v-if="!loadingPatient && patient && patient.personal_information && patient.regulatory_information" style="width: 100%">
           <div class="admin-chart-form-container">
           <div class="admin-chart-form" >
                <!-- PATIENT SUMMARY HEADER -->
                <!-- GENERAL INFO -->
                <div class="general-patient"
                    style="grid-area: initial; border-top-left-radius: 8px; border-bottom-left-radius: 8px; display: grid; grid-template-columns: 1fr 4fr; grid-gap: 10px;">
                    <div>
                        <router-link :to="'/patient/' + patient.uuid" style="text-decoration: none; color: #0870DC">
                            <h2 class="selected-resident-name" style="padding-top: 0px; margin: 0; color: #0870DC; font-size: 1.2em;">
                                {{ patient.personal_information.first_name }} {{ patient.personal_information.last_name }}
                            </h2>
                        </router-link>
                        <div>
                        </div>
                        <img
                            onerror="this.onerror=null; this.className='resident-image-default resident-image large admin'"
                            class="resident-image large admin"
                            v-if="patient.image_information && patient.image_information.url"
                            :src="patient.image_information.url"
                            alt="Resident Image"
                            style="margin-bottom: 10px !important"
                        >
                        <img v-else class="resident-image-default resident-image large admin" style="margin-bottom: 10px !important">
                        
                        <div>
                            <div style="border: 2px solid #E04F39; border-radius: 8px;">
                                <!-- PATIENT INFO/ADR TAB SELECT -->
                                <div style="display: flex; justify-content: space-around; align-items: center; border-bottom: 2px solid #E04F39;">
                                    <h4
                                        style="cursor: pointer; width: 100%; margin: 0; padding: 5px 0; width: 50%;"
                                        @click="infoADRTab = 'INFO'"
                                        :style="infoADRTab == 'INFO' ? { backgroundColor: '#E04F39', color: 'white', borderTopLeftRadius: '4px' } : {}"
                                    >
                                        INFO
                                    </h4>
                                    <h4
                                        style="cursor: pointer; width: 50%; margin: 0; padding: 5px 0;"
                                        @click="infoADRTab = 'ADR'"
                                        :style="infoADRTab == 'ADR' ? { backgroundColor: '#E04F39', color: 'white', borderTopRightRadius: '4px' } : {}"
                                    >
                                        ADR
                                    </h4>
                                </div>
                                <!-- PATIENT STICKERS -->
                                <div style="height: 200px; overflow: auto;" v-if="infoADRTab == 'ADR'">
                                    <table style="padding: 0 10px; border-collapse: collapse;" v-if="patient.adr && patient.adr.length">
                                        <tr>
                                            <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                Drug
                                            </th>
                                            <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                Year
                                            </th>
                                        </tr>
                                        <tr v-for="(item, index) in patient.adr" :key="index">
                                            <td style="padding: 0 10px;">
                                                {{ item.drug ? item.drug.toUpperCase() : item.custom ? item.custom.toUpperCase() : 'Non Spec.'}}
                                            </td>
                                            <td style="padding: 0 10px;">
                                                {{ item.date ? item.date.split(',')[1] : '----' }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <!-- PATIENT INFO -->
                                <div style="height: 200px; text-align: left; overflow-x: auto; background-color: white !important;border-radius: 8px"
                                    v-if="infoADRTab == 'INFO'">
                                    <div>
                                         <div class="general-patient-info">
                                            <p class="general-patient-info-key">DOB</p>
                                            <p class="general-patient-info-value">{{ patient.personal_information.dob }}</p>
                                        </div>
                                        <div class="general-patient-info">
                                            <p class="general-patient-info-key">Age</p>
                                            <p class="general-patient-info-value">{{ patient.personal_information.age }}</p>
                                        </div>
                                        <div class="general-patient-info">
                                            <p class="general-patient-info-key">Sex</p>
                                            <p class="general-patient-info-value">{{ patient.personal_information.sex }}</p>
                                        </div>
                                        <div class="general-patient-info">
                                            <p class="general-patient-info-key">Preferred Name</p>
                                            <p class="general-patient-info-value">
                                                {{ patient.personal_information.preferred_name }}</p>
                                        </div>
                                        <div class="general-patient-info" v-for="(item, index) in patient.contact_methods"
                                            :key="index">
                                            <p class="general-patient-info-key">{{ item.type }}</p>
                                            <p class="general-patient-info-value">{{ item.data }}</p>
                                        </div>
                                        <div class="general-patient-info"
                                            v-for="(item, index) in patient.regulatory_information" :key="index">
                                            <p class="general-patient-info-key">{{ item.regulatory_type }}</p>
                                            <p class="general-patient-info-value">{{ item.data }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- SPECIAL CONSID.-->
                            <div>
                                <div v-if="spec_cons && $store.state.user.patient_admin_active">
                                    <div
                                        v-if="spec_cons.special_considerations && spec_cons.special_considerations.length"
                                        style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 2px; overflow: auto; max-height: 200px;"
                                    >
                                        <p
                                            class="field-value"
                                            v-for="(item, index) in uniqueSpecCons" :key="index"
                                            style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 0px; margin-right: 5px;"
                                        >
                                            {{item.name.toUpperCase() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- END SPECIAL CONSID.  -->
                        </div>
                        <!-- <div>
                            <div v-if="patient.adr.length"
                                style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 10px; overflow: scroll; max-height: 200px;">
                                <p class="field-value" v-for="(item, index) in patient.adr" :key="index"
                                    style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 5px; margin-right: 5px;">
                                    {{ item.drug.toUpperCase() }}
                                </p>
                            </div>
                        </div> -->
                    </div>
                    <!-- <hr style="border:none; border-left:2px solid #e8e8e8; height:90%; width:2px; align-self: center"> -->
                    <!-- PATIENT ADMINISTRATION COMPONENTS -->
                <div>
                    <!-- EXPIRED MEDICATIONS -->
                    <div class="medication-group" v-if="expiredCharts && expiredCharts.length && $store.state.user?.role?.permissions?.includes('can_view_expired_charts')">
                                    <div class="red-background medication-group-header" 
                                    style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px; background-color: #7d2b1f">
                                        <h2 style="margin: 0;">Expired Medications</h2>
                                    </div>
                                    <div class="medication-group" >
                                <div>
                                    <table style="border-collapse: collapse;" v-if="!patient.completed" >
                                        <tr class="non-packed-medication-row" style="background-color: rgba(171, 58, 41, 0.3) !important" v-for="(drug, i) in expiredCharts" :key="i">
                                        <td v-if="mimsApiKey" style="width: 10em">
                                            <MimsImage
                                                :alias_id="drug.alias ? drug.alias.alias_id : ''"
                                                off_market="true"
                                                width="90px"
                                                height="60px">
                                            </MimsImage>
                                        </td>
                                        <td style="padding: 10px"><p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}} <span class="green-button" style="margin: 3px; background-color: rgb(0, 178, 162) !important; padding: 2px 10px;" v-if="drug.type == 'prn'">PRN</span></p>
                                        <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                            <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                            <p style="font-size: 1em; color: #E04F39; font-weight: bold;">{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                            <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[0].administered_at  : 'N/A'}}</b></p>
                                            <div style="display: flex; flex-wrap: wrap">
                                                <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                    <p class="consideration-tag" style="width: fit-content" v-if="tag.toUpperCase() != 'S4' && tag.toUpperCase() != 'S8' && tag.toUpperCase() != 'S4VIC'" >{{tag.toUpperCase()}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width: 5em">x {{drug.dose}}</td>
                                        <td style="width: 7em;">{{drug.route}}</td>
                                       <td style="width: 1em">
                                            <div>
                                                <p class="blue-button" v-if="$store.state.user?.role?.permissions?.includes('can_administer_expired_charts')" style="background-color: rgb(171 58 41) !important" @click="createExpiredNote(drug), displayExpiredNoteModal=true">ACTION</p>
                                                <!-- <Dropdown placeholder="ACTION" :options="expiredOptions" optionLabel="name" optionValue="value" v-model="drug.selectedExpiredOption" /> -->
                                            </div>
                                        </td>
                                        <td style="width: 1em">
                                        </td>
                                        <!-- <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                            <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                        </td>
                                        <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                            <div ><i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i></div>
                                        </td> -->
                                        <td style="width: 0em"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                                </div>
                    <!-- PRN MEDICATIONS -->
                    <div class="medication-group" >
                        <div>
                            <div v-for="(drug, i) in patient.prns" :key="i" style="margin-bottom: 1em;">
                                <div class="medication-group-header"  
                                style="display: grid; grid-template-columns:30% 70%; align-items:center; background-color: rgb(0, 178, 162); padding: 10px 30px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                    <h2 style="margin: 0;">PRN</h2>
                                    <span class="tag" style="justify-self:end; width: auto;font-weight: 700; font-size: 15px; border-left: 40%;">
                                        <span v-if="drug.amount_administered==drug.max_dose"><p style="color:#E04F39;font-weight: 16px;padding: 0 0; margin: 0 0;"> {{parseFloat(drug.amount_administered)}}/{{drug.max_dose ? drug.max_dose : 'null'}} {{drug.dose_unit}}{{ drug.max_dose && drug.max_dose > 1 ? 's' : ''}} administered in the last 24 hours</p></span>
                                        <span style="color: #2c3e50;font-weight: 16px;" v-else><p style="font-weight: 16px;padding: 0 0; margin: 0 0;">{{parseFloat(drug.amount_administered)}}/{{drug.max_dose ? drug.max_dose : 'null'}} {{drug.dose_unit}}{{ drug.max_dose && drug.max_dose > 1 ? 's' : ''}} administered in the last 24 hours</p></span>
                                    </span>
                                </div>
                                <div v-if="drug.completed  && !drug.effectiveness" style="display: flex; justify-content: space-between; background-color: #E04F39; height: 45px; padding: 0 30px; align-items: center;">
                                    <p style="font-size: 16px; font-weight: bold;">{{drug.last_chart_administrations && drug.last_chart_administrations.length ? drug.last_chart_administrations[0].quantity : 'N/A'}} {{drug.dose_unit}}/s was administered at {{drug.last_chart_administrations && drug.last_chart_administrations.length ? drug.last_chart_administrations[0].administered_at : ''}}. Was this effective?</p>
                                    <span style="display: grid; grid-gap: 10px; grid-template-columns: 1fr 1fr">
                                        <div ><i class="pi pi-check" @click="addEffective(1, drug)"></i></div>
                                        <div ><i class="pi pi-times" @click="addEffective(0, drug)" ></i></div>
                                    </span>
                                </div>
                                <div>
                                    <table style="border-collapse: collapse;" >
                                        <tr class="prn-medication-row">
                                        <td style="width: 10em"><div class="drug-image"><img src="../../assets/icons/medPlaceholder.png" alt=""></div></td>
                                        <td style="padding:10px">
                                            <p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                            <p v-if="expiryBool(drug.stop_date)" style="font-size: 1em;color: #E04F39;font-weight: bold"> Expiring in {{ formatExpiry(drug.stop_date) }} </p>
                                            <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                            <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[0].administered_at  : 'N/A'}}</b></p>
                                            <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                            <p>{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                            <div style="display: flex; flex-wrap: wrap">
                                                <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                    <p class="consideration-tag" style="width: fit-content" v-if="!hiddenTags.includes(tag)" >{{tag.toUpperCase()}}</p>
                                                </div>
                                            </div>
                                            <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                            <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')">RESUPPLY REQUESTED</p>
                                        </td>
                                        <td style="width: 6em; font-weight: bold;">x {{drug.dose}} <span style="font-size: 0.9em;">{{drug.dose_unit}}</span></td>
                                        <td style="width: 9em; font-weight: bold;">{{drug.route}}</td>
                                        <!-- @click="submitPrimeSignature(drug, 'approve')"  -->
                                        <td style="width: 1em"></td>
                                            <!-- <div ><i class="pi pi-check" :id="drug.chart_id+ '-approve'"></i></div> -->
                                        <!-- <td style="width: 1em"
                                            v-if="(drug.alias.tags.includes('S8') || drug.alias.tags.includes('s8')) && !drug.removed && !drug.completed"
                                        >
                                            <div @click="displayPRNAdminModal = true, selectPRN(drug)" class="administer-button" v-if="drug.stock_items && drug.stock_items.length && drug.stock_items[0].qty_left > 0">
                                                REMOVE FROM REG
                                            </div>
                                            <div v-else style="cursor: inherit; color: #E04F39" >
                                                NO STOCK
                                            </div>
                                        </td>
                                        <td v-if="(!drug.alias.tags.includes('S8') && !drug.alias.tags.includes('s8') || drug.removed)  && !(drug.amount_administered==drug.max_dose) && !drug.completed" @click="displayPRNAdminModal = true, selectPRN(drug)" style="width: 1em">
                                            <div class="administer-button">
                                                ADMINISTER
                                            </div>
                                        </td>
                                        <td v-if="(!drug.alias.tags.includes('S8') && !drug.alias.tags.includes('s8') || drug.removed) && !drug.completed && (drug.amount_administered==drug.max_dose)" style="width: 207px">
                                            <span style="cursor: inherit; color: #E04F39; font-weight: bold;">
                                                MAX DOSE REACHED
                                            </span>
                                        </td>
                                        <td  style="width: 1em" v-if="drug.completed">
                                            <div class="administer-button">
                                                COMPLETED
                                            </div>
                                        </td> -->

                                        <td style="width: 1em" v-if="((drug.alias.tags.includes('S8') || drug.alias.tags.includes('s8')) || drug.stock_items?.length) && !drug.removed && !drug.completed">
                                            <div @click="displayPRNAdminModal = true, selectPRN(drug)" class="administer-button" v-if="(drug.stock_items?.length && drug.stock_items[0].quantity > 0)">REMOVE FROM REG</div>
                                            <div class="administer-button" style="cursor: inherit; color: red" v-else>NO STOCK</div>
                                        </td>
                                        <td @click="displayPRNAdminModal = true, selectPRN(drug)"  style="width: 1em" v-if="((drug.alias.tags.includes('S8') || drug.alias.tags.includes('s8') || drug.stock_items?.length) && drug.removed) && !drug.completed">
                                            <div class="administer-button">ADMINISTER</div>
                                        </td>
                                        <td @click="displayPRNAdminModal = true, selectPRN(drug)"  style="width: 1em" v-if="((!drug.alias.tags.includes('S8') && !drug.alias.tags.includes('s8')) && !drug.stock_items?.length)  && !drug.completed">
                                            <div class="administer-button">ADMINISTER</div>
                                        </td>
                                        <td  style="width: 1em" v-if="drug.completed">
                                            <div class="administer-button" style="cursor: inherit">COMPLETED</div>
                                        </td>
                                        <td style="width: 0em"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                       
                        <div v-if="loadingPRNs">
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                            <h4>Loading PRNS...</h4>
                        </div>
                        <div v-if="(prns && !prns.length) && !loadingPRNs">
                            <h3>No PRNs available for this resident.</h3>
                        </div>
                    </div>
                    
                </div>
                </div>
              
           </div>
           
       </div>
            
       </div>
       <div v-else style="margin: 0 auto; padding-top: 5em; display: flex; align-items: center;">
            <i class="pi pi-spin pi-spinner" v-if="loadingPatient" style="font-size: 2rem !important;margin-top: 2em;"></i>
            <!-- <div v-else style="text-align: center;">
                <h2 style="color: black; margin: 0;" v-if="!loadingAdministration">{{noCharts ? 'No residents are due for administration at this location.' : 'No Location Selected'}}</h2>
                <p style="font-size: 1.2em; margin: 0;" v-if="!loadingAdministration">Please select {{noCharts ? 'another':'a'}} location to view residents due for administration.</p>
            </div> -->
            <div v-if="loadingAdministration">
                <h3 style="color: black; margin: 0;" >Waiting for patients to be retrieved from location/s.</h3>
                <p style="font-size: 1.2em; margin: 0;">Please wait...</p>
            </div>
            
       </div>
      </div>
      <!-- <DOCModal v-if="displayDOCModal" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload" :loop="loop" /> -->
      <!-- <PRNAdminModal v-if="displayPRNAdminModal" @completePRN="completePRN" @updatePRN="updatePRN" :selectedPRN="selectedPRN" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload" /> -->
      <PRNAdminModal v-if="displayPRNAdminModal" @completePRN="completePRN" @updatePRN="updatePRN" :selectedPRN="selectedPRN" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload"
      :patientId="patient && patient.uuid ? patient.uuid : ''"  @addInsulinReading="addInsulinReading" :readings="patient && patient.readings ? patient.readings : []"  />
      <ExpiredAdminNoteModal v-if="displayExpiredNoteModal" @close="close" :selectedExpiredDrug="selectedExpiredDrug" :patientId="patient && patient.uuid ? patient.uuid : ''" />
    </div>

</template>

<script>
// import DOCModal from '@/components/modals/DOCModal.vue';
import axios from 'axios';
import PRNAdminModal from '@/components/modals/PRNAdminModal.vue';
import ExpiredAdminNoteModal from '@/components/modals/ExpiredAdminNoteModal.vue';



export default{
props:['spec_cons','adminChart', 'patient', 'loadingPatient', 'loadingAdministration', 'prns', 'loadingPRNs', 'prnMode', 'hiddenTags', 'expiredCharts'],
components:{
// DOCModal,
PRNAdminModal,
ExpiredAdminNoteModal
},
data(){
return{
    displayPRNAdminModal: false,
    displayExpiredNoteModal: false,
    selectedExpiredDrug: null,
    loop:false,
    displayDOCModal: false,
    infoADRTab: 'INFO',
    removeFromSafe:[],
    selectedPRN:'',
    // removeFromRegisterPayload is the MAPPED payload of items within the removeFromSafe
    removeFromRegisterPayload:[],
    doseOmittedCode:[
      'A',
      'F',
      'H',
      'L',
      'S',
      'S/A',
      'V',
      'A/T',
      'C',
      'N',
      'N/R',
      'O',
      'R',
      'W',
      'W/R'
    ],
    packedPayload:[],
    submitPayload: {},
    requestedMeds: []
}
},
methods:{
    createExpiredNote(drug){
            this.selectedExpiredDrug = drug;
            this.displayExpiredNoteModal = false;
        },
        resupplyDrug(drug){
            console.log('this is the drug requesting to be resupplied', drug);
            drug.loadingSupply = true;
            
            axios.post('resupply/create', {prescription_id: drug.prescription_id}, this.$store.state.header).then(res=>{
                console.log('this is the res', res.data);
                drug.loadingSupply = false;
                drug.requestedSupply = true;
            }).catch(err=>{
                console.log('this is the resupply error', err);
                drug.loadingSupply = false;
                drug.requestedSupply = true;
                drug.alreadyRequested = true;
            })
        },
addEffective(effective, drug){
    console.log('thi is the effective arguments', effective, drug);
    let date = new Date();
    let adminDate;
    if(drug.last_chart_administrations && drug.last_chart_administrations.length){
        adminDate = new Date(drug.last_chart_administrations[0].administered_at);
    }
    
    let note = `PRN medication was ${effective ? 'effective' : 'ineffective'}.

Date/time: ${date.toLocaleDateString('en-AU')} ${date.toLocaleTimeString('en-AU')}
Drug name: ${drug.alias.name}
Administration route: ${drug.route}
Time of administration: ${adminDate ? adminDate.toLocaleDateString('en-AU')+' '+adminDate.toLocaleTimeString('en-AU') : 'N/A'}
`
    this.$emit('submitEffective', {
        effective: effective,
        prn_id: drug.administration_id,
        note: note

    })
},
expiryBool(date) {
    const targetDate = new Date(date)
    console.log(targetDate)
    const currentDate = new Date();
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    // Convert milliseconds to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (daysDifference <= 14) {
        return true
    } else {
        return false
    }
},
formatExpiry(date) {
    const targetDate = new Date(date)
    const currentDate = new Date();
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    // Convert milliseconds to days and hours remaining
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    const truncatedDaysDifference = Math.trunc(daysDifference);
    // we use ceiling value to prevent displaying 0 days and 0 hours when hoursDiff is < 0.5 but > 0
    const hoursDifference = Math.ceil((daysDifference - truncatedDaysDifference) * 24);
    const template = `
        ${truncatedDaysDifference} 
        ${((truncatedDaysDifference === 1 ? 'day' : 'days'))} 
        and ${hoursDifference} ${(hoursDifference === 1 ? 'hour' : 'hours')}
        `;
    return template; // displays as X day/s and Y hour/s
},
addInsulinReading(value){
    if(value){
        console.log('this is the insulin update (reading)', value);
        this.$emit('updatePatientReading', value);
    }
},
close(value){
    if(value){
        this.displayDOCModal = false;
        this.displayPRNAdminModal = false;
        this.displayExpiredNoteModal = false;
    }
},
retrievePRN(){
    this.$emit('retrievePRNs', true);
},

selectPRN(drug){
    this.selectedPRN = drug;
    this.selectMedLoad(drug, false);
},
getDrugId(value){
    if(value){
        console.log('this is the drug containing drug retrieval id', value);
        if(value.id){
            this.adminChart.forEach(chart=>{
                if(chart.uuid == value.id){
                    chart.removed = true;
                    chart.drug_safe_retrieval = value.drug_safe_retrieval;
                }
            })
        }
    }
},
updatePRN(value){
    if(value){
        console.log('this is the drug containing drug retrieval id', value);
        if(value.id){
            this.patient.prns.forEach(chart=>{
                if(chart.uuid == value.id){
                    chart.removed = true;
                    chart.drug_safe_retrieval = value.drug_safe_retrieval;
                }
            })
        }
    }
},
completePRN(value){
    if(value){
        console.log('this is the drug containing drug retrieval id', value);
        if(value){
            this.patient.prns.forEach(chart=>{
                if(chart.uuid == value.id){
                    chart.completed = true;
                    chart.administered_at = value.administered_at;
                    this.$emit('completePRN', value);
                    this.displayPRNAdminModal = false;
                    if(chart.drug_safe_retrieval){
                        chart.stock_items.forEach(stock_item=>{
                            localStorage.removeItem('DRET'+stock_item.uuid)
                        })
                    }
                }
            })
        } 
    }
},
receiveDOC(value){
    console.log('THIS IS THE DOC RECEIVED', value);
    if(value){
        this.adminChart.forEach(chart=>{
            if(chart.uuid == value.id){
                chart.doc = value.doc.value;
                chart.completed = true;
                    }
                })
            }
        },
        removeDrugFromRegister(drug){
            this.removeFromRegisterPayload = 
                {
                    prescriber_uuid:drug.prescriber_id,
                    stock_item_id:drug.stock_items[0].uuid,
                    quantity:drug.dose 
                }
            
        },
        selectDOC(drug){
            drug.status = document.getElementById(`${drug.chart_id}-doc-admin`).value;
            console.log('this is the drug doc and object', drug.status, drug);
        },

        submitPrimeSignature(drug, status){
            this.$emit('primeSignature', drug, status);
        },
        submitIsCompleted(value){
            console.log('RECEIVED THIS FROM SUBMIT IS COMPLETED', value);
            this.$emit('isCompleted', value);
            if(value){
                this.adminChart.forEach(chart=>{
                    console.log('this is the chart uuid', chart, chart.calendar_item_id, value.item);
                    if(chart.uuid == value.item){
                        console.log('match this');
                        chart.completed = true;
                    }
                })
            }
            this.removeFromSafe = [];
        },
        selectMedLoad(drug, administered){
            this.loop = false;
            this.submitPayload = {
                administered: administered,
                drug_safe_retrieval: drug.drug_safe_retrieval ? drug.drug_safe_retrieval : '',
                calendar_item_id: drug.uuid,
                chart_id: drug.chart_id,
                doc:'',
                dose: parseFloat(drug.dose).toFixed(2),
                notes:'',
                reading: '',
            }
        }
        },
mounted(){
    console.log('this is the patient', this.patient);
    if(this.patient.prns && this.patient.prns.length){
        console.log('patient has prns');
        if(this.$store.state.user?.role?.permissions?.includes('can_do_resupply')){
            axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res=>{
                this.requestedMeds = res.data;
                this.patient.prns.forEach(prn=>{
                    if(this.requestedMeds.includes(prn.prescription_id)){
                        prn.requestedSupply = true;
                    }
                })
            })
        }
        this.patient.prns.forEach(prn=>{
            console.log('looping through patient who has prns', prn.stock_items);
            if(prn.stock_items && prn.stock_items.length){
                console.log('looping through patient who has prns IF CONDITION');
                prn.stock_items = prn.stock_items.filter((item, index) => prn.stock_items.indexOf(item) === index);
                prn.stock_items.forEach(item=>{
                    if(localStorage.getItem('DRET'+item.uuid)){
                        prn.removed = true;
                        console.log('found a match in local storage', item);
                        prn.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                    }
                })
                console.log('looping through patient who has prns AFTER', prn.stock_items);
            }
        })
    }
    
},
computed:{
    uniqueSpecCons(){
            let b = this.spec_cons &&  this.spec_cons.special_considerations ?  this.spec_cons.special_considerations : [];
            const set = new Set();
            const result = b.filter((o) => {
                if (set.has(o.name)) return false;
                set.add(o.name);
                return true;
            });
            return result;
        }
}
}
</script>

<style lang="scss" scoped>

.tag {
  pointer-events: none;
  background-color: white;
  border: none;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
}

.dummy-icon{
width: 50px !important;
}
.administered{
background-color: #00AD50 !important;
color: white !important;
p{
margin: 0; 
padding: 0; 
font-weight: bold; 
width: fit-content
}
}

.not-administered{
background-color: #E04F39 !important;
color: white !important;
p{
margin: 0; 
padding: 0; 
font-weight: bold; 
width: fit-content
}
}
.medication-group-header{
h2{
font-size: 18px;
}
span{
font-size: 18px;
}
}

.medication-group{
table{
td{
    font-size: 16px;
    p{
        margin: 0;
    }
}
}
}
.admin-chart-header{
display: flex;
padding: 1em;
padding-top: 0;
}

.drug-image{
// height: 75px;
// width: 75px;
// background-color: white;
padding-left: 15px;
img{
max-height: 100px;
max-width:120px;
width: auto;
height: auto;
}
}

.administer-button{
box-sizing: border-box;
width: 187px;
background: #FFFFFF;
border: 2px solid #000000;
border-radius: 6px;
margin: 0 auto;
text-align: center;
font-size: 18px;
font-weight: bold;
cursor: pointer;
}
.grey-field{
background-color: #f3f4f4 !important;
border: none !important;
}

.four-table-row{
display: flex;
flex-wrap: wrap;
align-items: center;
}

.four-column-entry{
width: 22%;
min-width: 120px;
margin-right: 10px;
margin-bottom: 20px;
}

.two-column-row{
display: flex;
align-items: center;
flex-wrap: wrap;
}
.two-column-entry{
width: 45%;
min-width: 200px;
margin-right: 10px;
}

.medication-group{
margin-bottom: 10px;
h2{
color: white;
width: fit-content;
}
border-top-left-radius: 8px;
border-top-right-radius: 8px;
}

.packed-medication-row{
background-color: rgba(8, 112, 220, 0.2);  
td{
 padding: 10px;
}   
}

.scheduled-medication-row{
background-color: rgba(224, 79, 57, 0.2);
td{
 padding: 10px;
}   
}

.non-packed-medication-row{
background-color: #F3F4F4;
td{
 padding: 10px;
}   
}

.prn-medication-row{
background-color: #94D6D0;
td{
padding: 10px;
}
}




.pi-check, .pi-times, .pi-sign-out, .pi-lock-open, .pi-eject{
background-color: white;
padding: 10px;
font-weight: bold;
border-radius: 8px;
color: grey;
cursor: pointer;
}


</style>
