<template>
  <div class="drug-register-view view">
    <!-- <div class="actions-panel panel" style="width: 50px;">
      <h3 class="panel-header">Actions</h3>
      <ActionsMenu />
    </div> -->
    <div class="panel reports-panel">
      <div style="display: flex; align-items: flex-end; justify-content: flex-end">
          <!-- END RESIDENTIAL INFORMATION TITLE AND EDIT BUTTON -->
          <!-- MEDICATIONS, PATIENT INFO, OBSERVATIONS, ADMINISTRATION, MEDICATION HISTORY TABS -->
          <div class="parent-tabs-container" style="display: flex; align-items: flex-end">
              <h3 class="tab" :class="displayReport == 'Tx' ? 'selected-tab' : ''"
                @click="displayReport='Tx'; loadReport()" v-if='$store.state.user.ecdr_active && $store.state.user.role.permissions.includes("can_view_transactions_report")'>Transactions</h3>
              <h3 class="tab" :class="displayReport == 'Audit' ? 'selected-tab' : ''"
              @click="displayReport='Audit'" v-if='$store.state.user.ecdr_active && $store.state.user.role.permissions.includes("can_view_audit_report")'>Audit</h3>
              <h3 class="tab" :class="displayReport == 'Stock' ? 'selected-tab' : ''"
              @click="displayReport='Stock'" v-if='$store.state.user.ecdr_active && $store.state.user.role.permissions.includes("can_view_stock_report")'>Stock</h3>
              <h3 class="tab" :class="displayReport == 'IHI' ? 'selected-tab' : ''"
              @click="displayReport='IHI'" v-if="$store.state.user.eprescribing_active && $store.state.user.role.permissions.includes('can_view_ihi_report') && $store.state.company.type != 'warehouse' ">IHI</h3>
              <!-- <h3 class="tab" :class="displayReport == 'EPrescribing' ? 'selected-tab' : ''"
              @click="displayReport='EPrescribing'" v-if='$store.state.user.eprescribing_active && $store.state.user.role.permissions.includes("can_view_eprescribing_report")'>EPrescribing Log</h3> -->
              <h3 class="tab" :class="displayReport == 'Prescriptions' ? 'selected-tab' : ''"
              @click="displayReport='Prescriptions'" v-if="$store.state.user.ecdr_active && $store.state.user.role.permissions.includes('can_view_eprescribing_report') && $store.state.company.type != 'warehouse'">Medications</h3>
              <h3 class="tab" :class="displayReport == 'QI' ? 'selected-tab' : ''"
              @click="displayReport='QI'" v-if="$store.state.user.reporting_active && $store.state.company.type != 'warehouse'">Quality Indicators</h3>
              <h3 class="tab" :class="displayReport == 'QUM' ? 'selected-tab' : ''"
              @click="displayReport='QUM'" v-if="$store.state.user.reporting_active && $store.state.company.type != 'warehouse'">QUM</h3>
              <h3 class="tab" :class="displayReport == 'Previous' ? 'selected-tab' : ''"
              @click="displayReport='Previous'" v-if="$store.state.user.reporting_active && $store.state.company.type != 'warehouse'">Previous Reports</h3>
          </div>
          <!-- END MEDICATIONS, PATIENT INFO, OBSERVATIONS, ADMINISTRATION, MEDICATION HISTORY TABS -->
      </div>
      
      <div v-if="displayReport == 'Tx' && $store.state.user.role.permissions.includes('can_view_transactions_report')" class="drug-register-table two-row-filter-table table-container" style="width: 95.5vw; border-top-right-radius: 0;" :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
        <div class="filters" style="flex-wrap:wrap; height: fit-content">
            <div class="text-search-container search-container">
              <input v-model="form.filters.drug" placeholder="Medication..." style="position: relative" />
              <div class="drug-search-result-container" v-if='form.filters.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
                <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
              </div>
            </div>
            <div class="text-search-container search-container" v-if="$store.state.company.type !== 'warehouse'">
              <input v-model="form.filters.patient" placeholder="Patient..." style="position: relative" />
            </div>
            <div class="text-search-container search-container" v-if="$store.state.company.type !== 'warehouse'">
              <input v-model="form.filters.prescriber" placeholder="Prescriber..." style="position: relative" />
            </div>
            <div class="text-search-container search-container">
              <input v-model="form.filters.signatory" placeholder="Signed/Witnessed by..." style="position: relative" />
            </div>
            <div class="date-search-container search-container">
              <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
            </div>

            <div class="date-search-container search-container">
            <MultiSelect style="max-width: 250px;" v-model="selectedTransactions" :options="transactionSelect" @change="updateTransactionList" optionLabel="transaction" placeholder="Transactions" />
            </div>
            <div class="select-filters-container">
              <p @click="()=>{filters.s8 = !filters.s8; autoAll()}" 
                v-bind:class="[filters.s8 == true ? 'active-select-filter' : '']" class="select-filter-item">S8 Only</p>
            </div>
              <button class="button submit-button" @click="loadReport()">Search</button>
              <button class="button clear-button" style="margin-right: 10px;" @click="clearFilteredDrugRegister()">Clear</button>
              <button class="button clear-button" v-if="$store.state.user.role.permissions.includes('can_export_transactions_report')  && $store.state.currentLocation.state != 'WA'" style="background-color: #0870DC; color: white; margin-left: 0" @click="downloadCSVData()">Download CSV</button>
              <button class="button clear-button"  v-if="$store.state.user.role.permissions.includes('can_export_transactions_report')" @click="printElem('transaction-table')" style="margin-right: 2em; background-color: #0870DC; color: white">Print</button>
          </div>
        <div class="drug-variances-container" style="display: flex;" v-show='$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length'>
          <p style="padding: 5px 10px; margin-right: 10px;">Refine your search: </p>
          <p v-for="(item, index) in $store.state.drugEntries.drug_aliases" :key="index" @click="aliasSearch(item)" class="drug-variance-option">{{item}}</p>
          <!-- <p style="padding: 5px 10px; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer" @click="aliasSearch('Endone 10mg')" >Endone 10mg</p> -->
        </div>
        
        <div style="overflow-y: scroll">
          <!-- LOADING SPINNER -->
          <div v-if="$store.state.loadingReportData">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
          </div>
        <table v-else  class="data-table tx-table" cellspacing="0">
          <tr class="table-headers">
            <th>
              <h3 @click="sortBy('created_at', 'desc')">Date</h3>
              <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  date-sort" v-if="form.col =='created_at' && form.sort_direction =='asc'" @click="sortBy('created_at', 'desc')"></i>
              <i class="pi pi-sort date-sort" v-else @click="sortBy('created_at', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('drug', 'desc')">Medication</h3>
              <div v-if="form.col == 'drug'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort drug-sort" v-if="form.col =='drug' && form.sort_direction =='asc'" @click="sortBy('drug', 'desc')"></i>
                <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug', 'asc')"></i>
              </div>
            </th>
            <th v-if="$store.state.company.type !== 'warehouse'">
              <h3 @click="sortBy('resident', 'desc')">Resident</h3>
              <div v-if="form.col == 'resident'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort resident-sort" v-if="form.col =='resident' && form.sort_direction =='asc'" @click="sortBy('resident', 'desc')"></i>
                <i class="pi pi-sort resident-sort" v-else @click="sortBy('resident', 'asc')"></i>
              </div>
            </th>
            <th v-if="$store.state.company.type !== 'warehouse'">
              <h3 @click="sortBy('prescriber', 'desc')">Prescriber</h3>
              <div v-if="form.col == 'prescriber'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort prescriber-sort" v-if="form.col =='prescriber' && form.sort_direction =='asc'" @click="sortBy('prescriber', 'desc')"></i>
                <i class="pi pi-sort prescriber-sort" v-else @click="sortBy('prescriber', 'asc')"></i>
              </div>
            </th>

            <th>
              <h3 @click="sortBy('action', 'desc')">Action</h3>
              <div v-if="form.col == 'action'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort action-sort" v-if="form.col =='action' && form.sort_direction =='asc'" @click="sortBy('action', 'desc')"></i>
                <i class="pi pi-sort action-sort" v-else @click="sortBy('action', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('to_from', 'desc')">To/From</h3>
              <div v-if="form.col == 'to_from'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort to_from-sort" v-if="form.col =='to_from' && form.sort_direction =='asc'" @click="sortBy('to_from', 'desc')"></i>
                <i class="pi pi-sort to_from-sort" v-else @click="sortBy('to_from', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('signed_by', 'desc')">Signed By</h3>
              <div v-if="form.col == 'signed_by'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort signedby-sort" v-if="form.col =='signed_by' && form.sort_direction =='asc'" @click="sortBy('signed_by', 'desc')"></i>
                <i class="pi pi-sort signedby-sort" v-else @click="sortBy('signed_by', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('witnessed_by', 'desc')">Witnessed By</h3>
              <div v-if="form.col == 'witnessed_by'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort witnessedby-sort" v-if="form.col =='witnessed_by' && form.sort_direction =='asc'" @click="sortBy('witnessed_by', 'desc')"></i>
                <i class="pi pi-sort witnessedby-sort" v-else @click="sortBy('witnessed_by', 'asc')"></i>
              </div>
            </th>
            <th style="width: 80px;">
              <h3 @click="sortBy('qty_in', 'desc')">In</h3>
              <div v-if="form.col == 'qty_in'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort in-sort" v-if="form.col =='qty_in' && form.sort_direction =='asc'" @click="sortBy('qty_in', 'desc')"></i>
                <i class="pi pi-sort in-sort" v-else @click="sortBy('qty_in', 'asc')"></i>
              </div>
            </th>
            <th style="width: 80px;">
              <h3 @click="sortBy('qty_out', 'desc')">Out</h3>
              <div v-if="form.col == 'qty_out'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort out-sort" v-if="form.col =='qty_out' && form.sort_direction =='asc'" @click="sortBy('qty_out', 'desc')"></i>
                <i class="pi pi-sort out-sort" v-else @click="sortBy('qty_out', 'asc')"></i>
              </div>
            </th>
            <th style="width: 80px;">
              <h3 @click="sortBy('balance', 'desc')">Balance</h3>
              <div v-if="form.col == 'balance'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort balance-sort" v-if="form.col =='balance' && form.sort_direction =='asc'" @click="sortBy('balance', 'desc')"></i>
                <i class="pi pi-sort balance-sort" v-else @click="sortBy('balance', 'asc')"></i>
              </div>
            </th>
            <!-- <th>
              <h3 @click="sortBy('script_invoice', 'desc')">Script #</h3>
              <div v-if="form.col == 'script_invoice'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort script-sort" v-if="form.col =='script_invoice' && form.sort_direction =='asc'" @click="sortBy('script_invoice', 'desc')"></i>
                <i class="pi pi-sort script-sort" v-else @click="sortBy('script_invoice', 'asc')"></i>
              </div>
            </th> -->
          </tr>
          
          <tbody class="table-data" v-for="(item, index) in getDrugEntries.data" :key="index">
            <tr >
              <td>{{item.date}}</td>
              <td>{{item.drug}}</td>
              <td v-if="$store.state.company.type !== 'warehouse'">{{item.resident}}</td>
              <td v-if="$store.state.company.type !== 'warehouse'">{{item.prescriber}}</td>
              <td>
                {{
                  item.action 
                    ? (item.action == 'destruction')
                      ? `${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}` 
                      : this.$store.state.company.type == 'warehouse'
                        ? (item.action.charAt(0).toUpperCase() + item.action.slice(1))
                        : (item.action.charAt(0).toUpperCase() + item.action.slice(1)).replaceAll('destruction', 'disposal')
                    : ''
                }}
              </td>
              <td>{{item.to_from}}</td>
              <td>{{item.entered_by}}</td>
              <td>{{item.witnessed_by}}</td> 
              <td style="width: 80px;">{{item.in}}</td>
              <td style="width: 80px;">{{item.out}}</td>
              <td style="width: 80px;">{{item.balance}}</td>
              <!-- <td>{{item.script_invoice}}</td> -->
            </tr>
          </tbody>

        </table>
        <div v-if="$store.state.reportError">
          <h4 style="color: #E04F39">{{$store.state.reportError}}</h4>
        </div>
        </div>
          <div class="pagination-links">
            <p class="pagination-arrow" v-show=" getDrugEntries.current_page > 1 " @click=" displayPage(form.page - 1) "> &larr;
            </p>
            <p>Page {{ getDrugEntries.current_page }} of {{ getDrugEntries.page_count }}</p>
            <p class="pagination-arrow" v-show=" getDrugEntries.current_page < getDrugEntries.page_count "
              @click=" displayPage(getDrugEntries.current_page + 1) "> &rarr; </p>
          </div>
       </div>
       <!-- <div v-else class="drug-register-table table-container" style="padding-top: 10em; border-top-left-radius: 0px" >
          <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
        </div> -->

      <!-- PRINT TX TABLE -->
      <!-- PRINT TX TABLE -->
      <!-- PRINT TX TABLE -->
       <div v-if="displayReport == 'Tx'">
         <table class="data-table print-tx-table tx-table" cellspacing="0">
           <tr class="table-headers">
             <th>Date</th>
             <th>Drug</th>
             <th v-if="$store.state.company.type !== 'warehouse'">Resident</th>
             <th v-if="$store.state.company.type !== 'warehouse'">Prescriber</th>
             <th>Action</th>
             <th>To/From</th>
             <th>Sign By</th>
             <th>Witnessed By</th>
             <th>In</th>
             <th>Out</th>
             <th>Balance</th>
           </tr>
           <tr class="table-data" v-for="(item, index) in getDrugEntries.data" :key="index">
              <td class="left-side-table">{{item.date}}</td>
              <td>{{item.drug}}</td>
              <td v-if="$store.state.company.type !== 'warehouse'">{{item.resident}}</td>
              <td v-if="$store.state.company.type !== 'warehouse'">{{item.prescriber}}</td>
              <td>
                {{
                  item.action 
                    ? (item.action == 'destruction')
                      ? `${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}` 
                      : this.$store.state.company.type == 'warehouse'
                        ? (item.action.charAt(0).toUpperCase() + item.action.slice(1))
                        : (item.action.charAt(0).toUpperCase() + item.action.slice(1)).replaceAll('destruction', 'disposal')
                    : ''
                }}
              </td>
              <td>{{item.to_from}}</td>
              <td>{{item.entered_by}}</td>
              <td>{{item.witnessed_by}}</td> 
              <td style="width: 80px;">{{item.in}}</td>
              <td style="width: 80px;">{{item.out}}</td>
              <td style="width: 80px;">{{item.balance}}</td>
            </tr>
         </table>
       </div>
       <!-- PRINT TX TABLE -->
       <!-- PRINT TX TABLE -->
       <!-- PRINT TX TABLE -->

       <!-- STOCK -->
       <!-- STOCK -->
       <!-- STOCK -->
       
       <StockTable v-if="displayReport == 'Stock'" />
       <!-- END STOCK -->
       <!-- END STOCK -->
       <!-- END STOCK -->

       <!-- AUDIT -->
       <!-- AUDIT -->
       <!-- AUDIT -->
       <AuditTable v-if="displayReport == 'Audit'" />
       <!-- END AUDIT -->
       <!-- END AUDIT -->
       <!-- END AUDIT -->

       <!-- IHI -->
       <!-- IHI -->
       <!-- IHI -->
       <IHITable v-if="displayReport == 'IHI'" />
       <!-- END IHI -->
       <!-- END IHI -->
       <!-- END IHI -->

       <!-- EPrescribing -->
       <!-- EPrescribing -->
       <!-- EPrescribing -->
       <EPrescribingTable v-if="displayReport == 'EPrescribing'" />
       <!-- END EPrescribing -->
       <!-- END EPrescribing -->
       <!-- END EPrescribing -->

       <!-- Prescriptions -->
       <!-- Prescriptions -->
       <!-- Prescriptions -->
       <PrescriptionsTable v-if="displayReport == 'Prescriptions'" />
       <!-- END Prescriptions -->
       <!-- END Prescriptions -->
       <!-- END Prescriptions -->

       <!-- QI -->
       <!-- QI -->
       <!-- QI -->
       <QIReport v-if="displayReport == 'QI'" />
       <!-- END QI -->
       <!-- END QI -->
       <!-- END QI -->

       <!-- QUM -->
       <!-- QUM -->
       <!-- QUM -->
       <QUMReport v-if="displayReport == 'QUM'" />
       <!-- END QUM -->
       <!-- END QUM -->
       <!-- END QUM -->

       <!-- QUM -->
       <!-- QUM -->
       <!-- QUM -->
       <PreviousReports v-if="displayReport == 'Previous'" />
       <!-- END QUM -->
       <!-- END QUM -->
       <!-- END QUM -->

       <!-- ERROR LOG -->
       <!-- ERROR LOG -->
       <!-- ERROR LOG -->
      <!-- <ErrorTable v-if="displayReport == 'Error'" /> -->
       <!-- END ERROR LOG -->
       <!-- END ERROR LOG -->
       <!-- END ERROR LOG -->
    </div>
    
  </div>
</template>


<script>
// @ is an alias to /src
import StockTable from '@/components/tables/StockTable.vue';
import QIReport from '@/components/tables/QIReport.vue';
import QUMReport from '@/components/tables/QUMReport.vue';
import PreviousReports from '@/components/tables/PreviousReports.vue';
import AuditTable from '@/components/tables/AuditTable.vue';
import IHITable from '@/components/tables/IHITable.vue';
import EPrescribingTable from '@/components/tables/EPrescribingTable.vue';
import PrescriptionsTable from '@/components/tables/PrescriptionsTable.vue';
// import ErrorTable from '@/components/tables/ErrorTable.vue';

import axios from 'axios';

export default {
  components: {
    StockTable,
    QIReport,
    QUMReport,
    // ErrorTable,
    AuditTable,
    IHITable,
    EPrescribingTable,
    PrescriptionsTable,
    PreviousReports
  },
  data() {
    return {
      loadingError: '',
      loadingData: false,
      displayReport: 'Tx',
      drugNames: [],
      registerData: [],
      date: [],
      filters: {
        s8: false,
      },
      selectedTransactions: [
            {transaction:'Discard', action:'Discard'},
            {transaction:'Administration', action:'Administration'},
            {transaction:'Drug Safe Retrieval', action:'Drug Safe Retrieval'},
            {transaction:`${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}`, action:'Destruction'},
            {transaction:'Damage', action:'Damage'},
            {transaction:'Loss', action:'Loss'},
            {transaction:'Transfer Out', action:'Transfer Out'},
            {transaction:'Transfer In', action:'Transfer In'},
            {transaction:'Receipt', action:'Receipt'},
            {transaction:'Adjustment', action:'Adjustment'},
            {transaction:'Reversal', action:'Reversal'},
            // conditionally add transaction type Supply for warehouse users
            ...(this.$store.state.company.type == 'warehouse' ? [{transaction: 'Supply', action:'Supply'}] : [])
      ],
      transactionSelect: [
            {transaction:'Discard', action:'Discard'},
            {transaction:'Administration', action:'Administration'},
            {transaction:'Drug Safe Retrieval', action:'Drug Safe Retrieval'},
            {transaction:`${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}`, action:'Destruction'},
            {transaction:'Damage', action:'Damage'},
            {transaction:'Loss', action:'Loss'},
            {transaction:'Transfer Out', action:'Transfer Out'},
            {transaction:'Transfer In', action:'Transfer In'},
            {transaction:'Receipt', action:'Receipt'},
            {transaction:'Adjustment', action:'Adjustment'},
            {transaction:'Reversal', action:'Reversal'},
            // conditionally add transaction type Supply for warehouse users
            ...(this.$store.state.company.type === 'warehouse' ? [{transaction: 'Supply', action:'Supply'}] : [])
		  ],
      clearForm: {
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters: {
          tags:[],
          drug:'',
          patient:'',
          prescriber:'',
          signatory:'',
          // conditionally add supply option for warehouse users
          transactions:['dispensing', 'administration', 'destruction', 'damage', 'loss', 'transfer', 'receipt', 'adjustment', 'drug_safe_retrieval', 'reversal', ...(this.$store.state.company.type === 'warehouse' ? ['supply'] : [])],
          date: {
            start:'',
            end:''
          },
        }
      },
      form: {
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters: {
          tags:[],
          drug:'',
          patient:'',
          prescriber:'',
          signatory:'',
          transactions:['dispensing', 'administration', 'destruction', 'damage', 'loss', 'transfer', 'receipt', 'adjustment', 'drug_safe_retrieval', 'reversal', ...(this.$store.state.company.type === 'warehouse' ? ['supply'] : [])],
          date: {
            start:'',
            end:''
          },
        }
      },
      testData:[]
    }
  },
  methods: {
    // SORT DATA BY COLUMN AND ORDER
    autoAll() {
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if (value == true) {
                this.form.filters.tags.push(key)
            }
        }
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    sortBy(col, dir) {
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getReportEntries', this.form);
    },
    selectDrugName(name) {
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadReport(){
      console.log('this is the date', this.date);
      if (this.date.length){
        if (typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else {
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
        }
        this.$store.dispatch('getReportEntries', this.form);
      } else {
        this.form.filters.date.start ='';
        this.form.filters.date.end = '';
        this.$store.dispatch('getReportEntries', this.form);
      }
    },
    aliasSearch(alias){
      this.form.filters.drug = alias;
      this.$store.dispatch('getReportEntries', this.form);
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getReportEntries', this.form);
    },
    updateTransactionList(){
        this.form.filters.transactions = [];
        console.log('updating transaction list')
        this.selectedTransactions.forEach(transactionObject => {
          for (const [key, value] of Object.entries(transactionObject)) {
            if(key == 'action') {
              if(value == 'Drug Safe Retrieval'){
                this.form.filters.transactions.push('drug_safe_retrieval');
              } else{
                this.form.filters.transactions.push(value.toLowerCase());
              }
            }    
          }
        })
        console.log(this.form.filters.transactions)
    },
    clearFilteredDrugRegister() {
      this.form = {
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters: {
          tags:[],
          drug:'',
          patient:'',
          prescriber:'',
          signatory:'',
          transactions:['dispensing', 'administration', 'destruction', 'damage', 'loss', 'transfer', 'receipt', 'adjustment', 'drug_safe_retrieval', 'reversal', ...(this.$store.state.company.type === 'warehouse' ? ['supply'] : [])],
          date: {
            start:'',
            end:''
          },
        }
      };
      this.date = [];
      this.$store.dispatch('getReportEntries', this.form);
    },
    downloadCSVData() {
        let csv;

        axios.post('/export-transaction-report', this.form, this.$store.state.header).then((res) => {
        
          csv = res.data;
        
        }).then(() => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = `tx_report_${new Date().toLocaleDateString().split('/').join('')}_${this.$store.state.currentLocation.name}.csv`;
          anchor.click();
        })
    },
    printElem(elem){
        console.log('this is the eleme', elem);
        window.print();
    }
  },
   mounted() {
    this.loadReport();
    console.log('these are the location types', this.$store.getters.getLocationTypes)
    if (this.$store.state.company.type == 'warehouse') {
      this.selectedTransactions = this.selectedTransactions.filter(transaction => transaction.transaction !== 'Administration' && transaction.transaction !== 'Drug Safe Retrieval');
      this.transactionSelect = this.transactionSelect.filter(transaction => transaction.transaction !== 'Administration' && transaction.transaction !== 'Drug Safe Retrieval');
    }
  },
  computed: {
    getDrugEntries() {
      // getTransactionsReport
      // getDrugEntries
      console.log('this is the transactions report stuff', this.$store.getters.getTransactionsReport);
      return this.$store.getters.getTransactionsReport
    },
    getDrugNames() {
      return this.$store.getters.getDrugNames
    }
  }
}
</script>
