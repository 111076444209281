<template>
  <div v-if="loadingPatientProfile" class="overlay">
    <div style="width: 100%; margin-top: 12em;">
      <p style="font-size: 1em; font-weight: bold;">Fetching Resident...</p>
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
    </div>
  </div>
  <div class="view" style="margin-left: 40px">
    <!-- MAIN VIEW -->
    <!-- PRESCRIBER VIEW -->
    <div
      v-if="$store.state.userType == 'prescriber'"
      class="view dashboard-view prescriber-dashboard table-container"
      style="background-color: transparent; box-shadow: none; width: 95.5vw; padding: 0; margin: 0;">
      <div class="prescriber-patient-panel panel" style="margin-right: 20px; width: 60vw">
        <h3 class="panel-header">Patients</h3>
        <PatientPrescriber :patients="patients" @loadPatientProfile="loadPatientProfile" @loadPrescriberPatients="loadPrescriberPatients" :facilities="facilities" :loading="loading" />
      </div>
      <div class="prescriber-cr-panel panel">
        <h3 class="panel-header">Chart Reviews</h3>
        <ChartReviews :patients="patients" :loading="loading" />
      </div>
      <!-- <div class="prescriber-rmmrs-panel panel" >
        <h3>RMMRS</h3>
        <div class="dashboard-container"></div>
      </div> -->
      <!-- <div class="prescriber-anti-panel panel" >
        <h3>Residents on Antipsychotics</h3>
        <ResidentsOnDrug />
      </div> -->
      <!-- <div class="prescriber-reports-panel panel" >
        <h3>Reports</h3>
        <div class="dashboard-container"></div>
      </div> -->
    </div>
    <!-- PHARMACY VIEW -->
    <div
      v-if="$store.state.userType == 'pharmacy'"
      class="view dashboard-view pharmacy-dashboard table-container"
      style="background-color: white; box-shadow: none; padding: 35px 25px;">
      <div class="panel" style="width: 308px">
        <!-- <h3>Resident Search</h3> -->
        <PatientPharmacy :patients="patients" @loadPharmacyPatients="loadPharmacyPatients" :facilities="facilities" :loading="loading" />
      </div>
      <div class="panel">
        <!-- <h3>Task log</h3> -->
        <PharmacyLog :patients="patients" :loadingPatients="loading"/>
      </div>
    </div>

  <WarehouseDetails v-if="$store.state.userType == 'warehouse'"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PatientPrescriber from '../components/dashboard/PatientPrescriber.vue';
import PatientPharmacy from '../components/dashboard/PatientPharmacy.vue';
import ChartReviews from '../components/dashboard/ChartReviews.vue';
// import ResidentsOnDrug from '../components/dashboard/ResidentsOnDrug.vue';
import PharmacyLog from '../components/dashboard/PharmacyLog.vue';
import axios from 'axios';
import WarehouseDetails from '../components/dashboard/WarehouseDetails.vue';

export default {
  name: 'Home',
  components: {
    PatientPrescriber,
    PatientPharmacy,
    ChartReviews,
    // ResidentsOnDrug,
    PharmacyLog,
    WarehouseDetails
  },
  data() {
    return {
      loading: false,
      patients: [],
      prescriber: false,
      facilities: [],
      pharmacy: true,
      loadingPatientProfile: false,
    }
  },
  methods: {

    loadPharmacyPatients(value) {
      if (value) {
        this.loading = true;
        console.log('loading pharmacy patients now');
        this.$store.dispatch('getRefreshToken');
        this.patients = [];
        this.facilities =[];
        axios.post('/get-pharmacy-residents', { name: null }, this.$store.state.header).then(res => {
          this.loading = false;
          for(const key in res.data){
            this.facilities.push(key);
            res.data[key].forEach(patient=>{
              patient.facility = key;
              this.patients.push(patient);
            })
          }
        })
      }
    },
    loadPrescriberPatients(value) {
      if (value) {
        this.loading = true;
        console.log('loading prescriber patients now');
        this.$store.dispatch('getRefreshToken');
        this.patients = [];
        this.facilities =[];
        axios.post('/get-prescriber-residents', { name: null, facility_id: null }, this.$store.state.header).then(res => {
          this.loading = false;
          for(const key in res.data){
            this.facilities.push(key);
            res.data[key].forEach(patient=>{
              patient.facility = key;
              this.patients.push(patient);
            })
          }
        })
      }
    },
    loadPatientProfile(value) {
      this.loadingPatientProfile = true;
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.prescriber-dashboard {
  display: grid !important;
  grid-gap: 5px;
  background-color: white;
  padding: 10px 10px;
  border-radius: 12px;
  box-shadow: 0px 0 5px -2px rgb(155, 155, 155);
  height: 90vh;

  div {
    padding: 3px;

    p {
      margin: 0;
    }

    border-radius: 12px;
  }

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

}

.prescriber-patient-panel {
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 3;
}

.prescriber-cr-panel {
  grid-column-start: 12;
  grid-column-end: 19;
  grid-row-start: 1;
  grid-row-end: 3;
  margin-right: 0 !important;
}

// .prescriber-rmmrs-panel{
//   grid-column-start: 14;
//   grid-column-end: 19;
//   grid-row-start: 1;
//   grid-row-end: 1;
// }

.prescriber-anti-panel {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
}

.prescriber-reports-panel {
  grid-column-start: 5;
  grid-column-end: 19;
  grid-row-start: 2;
  grid-row-end: span 2;
}

.dashboard-view {
  .panel {
    h4 {
      margin: 0;
      text-align: left;
      padding-left: 20px;
    }

    .dashboard-container {
      border-radius: 12px;
      background-color: white;
      height: 92%;
      box-shadow: 0px 0 5px -2px rgb(155, 155, 155);
    }
  }
}

.pharmacy-dashboard {
  width: 95vw !important;
  display: grid !important;
  grid-gap: 5px;
  background-color: white;
  padding: 10px 10px;
  border-radius: 12px;
  box-shadow: 0px 0 5px -2px rgb(155, 155, 155);
  height: 90vh;

  div {
    padding: 3px;

    p {
      margin: 0;
    }

    border-radius: 12px;
  }

  grid-template-columns: 308px 3fr;

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5); /* Gray background with transparency */
  z-index: 1000; /* Ensure it appears above other content */
  pointer-events: none; /* Allow clicks to pass through if not used correctly */
}

/* Optional: Ensure content below the overlay is unclickable */
.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5); /* Gray overlay */
  pointer-events: auto; /* Make the overlay block interactions */
  z-index: 999; /* Place above other content but below the overlay */
}
</style>