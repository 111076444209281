<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 50% ">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Transfer {{ transferType == 'Receive' ? 'In' : 'Out' }} </h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">
                                    <!-- SELECT DROPDOWN LOCATION -->
                                    <!-- SELECT DROPDOWN LOCATION -->
                                    <!-- SELECT DROPDOWN LOCATION -->
                                    <p v-if=" $store.state.company.type == 'warehouse'"  class="submit-button blue-button" style="display: flex;justify-content: center;align-items: center;margin-bottom: 5px" @click="openCreatedModal()">Add Warehouse</p>
                                        <p style="text-align: left">{{ transferType == 'Receive' ? 'Location to receive from': 'Location to send to' }} <span style="font-size: 10px; color:red">*</span></p>
                                        <div style="display: flex; align-items: center; margin-bottom: 5px;">
                                                   <Dropdown v-model="searchedLocation" :placeholder="transferType == 'Receive' ? 'Type in location to receive from': 'Type in location to send to'"
                                                    ref="location" @change="locationSelect()" @keyup="searchLocation(), showLocations()" 
                                                  :loading="loadingLocation" :options="refinedLocations"     optionLabel="name"
                                                  :editable="true"
                                                  style="width: 14em; display: flex"/>
                                               
                                        </div>

                                    <div >
                                        <p style="text-align: left">Notes <span style="font-size: 10px; color:red">*</span></p>
                                        <textarea name="notes" id="note-modal" cols="44" rows="16"
                                            placeholder="Please enter notes here..."
                                            style="border-top-left-radius: 0px; border-top-right-radius: 0px;"
                                            v-model="form.submit.notes"></textarea>
                                    </div>
                                  </div>
                                <div class="right-form-section-panel">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">

                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p
                                                v-if='patient.regulatory_information && patient.regulatory_information.length'>
                                                <b>{{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].regulatory_type : ''
                                                }}:</b>
                                                {{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].data : ''
                                                }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information &&
                                                    patient.personal_information.dob ? patient.personal_information.dob :
                                                    'N/A'
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>

                                    <div v-if="patient">
                                        <div style="display: flex; margin-bottom: 10px;" :style="!selectedPrescriber ? {alignItems: 'flex-end'}:{alignItems: 'center'}">
                                            <div v-if="!selectedPrescriber"
                                                class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                                                <label for="first_name"
                                                    style="text-align: left; padding-left: 10px">Search for
                                                    Prescriber</label>
                                                <input v-model="gp" v-on:keyup="gpSearch(gp)"
                                                    placeholder="Enter Prescriber No. or Name"
                                                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                                <div class="drug-search-result-container" v-if="gp"
                                                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6; z-index:9">
                                                    <p class="drug-search-result"
                                                        style="text-align: left; padding-left: 20px; cursor:pointer"
                                                        v-for="(item, index) in prescriberResults" :key="index"
                                                        @click="selectPrescriber(item)">{{ item.regulatory_numbers.length
                                                                ? item.regulatory_numbers[0].data : 'Reference only'
                                                        }} - {{ item.first_name }}
                                                        {{ item.last_name }}</p>
                                                </div>
                                                
                                            </div>
                                            <div v-if="!selectedPrescriber" style="margin-bottom: 2px;">
                                                <p class="blue-button" style="margin: 0; font-size: 1em;" @click="displayReferencePrescriberModal = true">Create prescriber</p>
                                            </div>
                                            <div v-if="selectedPrescriber"
                                                style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 15px;">
                                                <p style="font-size: 0.9em; text-align: left;"><b>Prescriber:</b>
                                                    {{ selectedPrescriber.first_name }} {{ selectedPrescriber.last_name }}
                                                </p>
                                                <p style="font-size: 0.9em" v-if="selectedPrescriber.prescriber_no"><b>Prescriber Number:
                                                    </b>{{ selectedPrescriber.prescriber_no }}</p>
                                            </div>
                                            <p style="text-align: left;  cursor:pointer; width: fit-content;
                                        border-bottom: 1px solid grey; margin-top: 0; height: fit-content"
                                                v-if="selectedPrescriber.uuid" @click="changePrescriber()">Change
                                                Prescriber</p>
                                        </div>
                                    </div>

                                    <div v-if="$store.state.company.type !== 'warehouse' && !imprest_pom">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or resident medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div class="pom-imprest-select">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']">
                                                    Resident Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                   >
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- AFTER THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- <div v-if="imprest_pom == 'imprest' || (patient && transferType != 'Send')"
                                        class="right-form-section-drug-entry">
                                        <div style="width: 40%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 40%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkAndAddStock(stock) } }">Add</p>
                                    </div> -->

                                    <!-- PATIENT MEDICATION SEARCH -->
                                    <div class="right-form-section-drug-entry"
                                        v-if="patient && transferType != 'Receive'">
                                        <div style="width: 100%; margin-right: 10px">

                                            <select style="position: relative; width:100%; padding: 10px; "
                                                id="pom-select" @change="changeStock()">
                                                <option value="" disabled selected>Select medication</option>
                                                <option :value="index" v-for="(item, index) in patientMeds"
                                                    :key="index" :disabled="item.quantity ===0">{{ item.alias ? item.alias.name : '' }}
                                                    ({{ Number(item.quantity).toFixed(2) }} left)</option>
                                            </select>

                                            <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="searchDrugs(stock), show()" :loading="loadingDrugs" :options="patientMeds" optionLabel="full_name" :editable="true"
                                            style="width: 25em; display: flex" optionDisabled="disabled" placeholder="Type medication name here"/> -->
                                        </div>

                                        <input v-model="qty" min="0" type="number" placeholder="Quantity"
                                            style="position: relative; width: 20%; margin-right: 10px;" />
                                        <p class="submit-button blue-button" @click="() => { if (qty) { aliasSelect(stock) } }">
                                            Add</p>
                                    </div>

                                    <!-- <div v-if="transferType == 'Receive'">
                                        <div class="drug-variances-container" style="display: flex; height: fit-content"
                                            v-show='refinedDrugs && refinedDrugs.length'>
                                            <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                            <p v-for="(item, index) in refinedDrugs" :key="index"
                                                @click="aliasSelect(item)"
                                                style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                                {{ item.name.slice(0, 1).toUpperCase() + item.name.slice(1) }}
                                            </p>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="drug-variances-container" style="display: flex; height: fit-content"
                                            v-if='refinedDrugs && refinedDrugs.length'>
                                            <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                            <p v-for="(item, index) in refinedDrugs" :key="index"
                                                @click="aliasSelect(item)"
                                                style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                                {{ item.alias.name }} <span style="font-weight: bold">In Stock:
                                                    {{ item.qty_left }}</span>
                                            </p>
                                        </div>
                                        <div v-if="Array.isArray(refinedDrugs) && refinedDrugs.length == 0">
                                            <p style="font-size: 1em;">No stock found for that item.</p>
                                        </div>
                                    </div> -->

                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <div class="right-form-section-drug-entry" v-if="(($store.state.company.type == 'warehouse' ? $store.state.company.type == 'warehouse' && imprest_pom == '' : imprest_pom == 'imprest') && !checkItemBoxView) || (patient && transferType != 'Send') && !checkItemBoxView" style="gap: 5px;">
                                        <div style="display: flex; align-items: center;" >
                                            <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="searchDrugs(stock), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                            style="width: 25em; display: flex" optionDisabled="disabled" placeholder="Type medication name here"/> -->
                                            <Dropdown
                                                id="transfer-med-search"
                                                v-model="stock"
                                                ref="med"
                                                @change="drugSelect(stock)" @input="debounceSearchDrugs(stock), show()"
                                                :loading="loadingDrugs"
                                                :options="drugList"
                                                optionLabel="full_name"
                                                :editable="true"
                                                style="width: 25em; display: flex" placeholder="Select Medication"
                                            />
                                        </div>
                                        <input v-model="qty" min="0" type="number" placeholder="Enter qty"
                                            style="position: relative; width: 20%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkIfExistingItem(selectedDrug.full_item) } }">Add</p>
                                        <!-- <p class="submit-button grey-button" style="background-color: black !important"
                                        @click="() => { if (qty) { imprest_pom = ''; stock=''; qty=''; refinedDrugs = []; patient = '' } }">Cancel</p> -->
                                    </div>

                                    <div v-if="addingMed" style="display: flex; align-items: center; justify-content: center;">
                                        <span>Adding medication...</span>
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                    </div>
                                    <div class="right-form-section-drug-container" v-if="!checkItemBoxView">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{ item.stock.slice(0, 1).toUpperCase() + item.stock.slice(1) }}</p>
                                                </td>

                                                <td>
                                                    <p>
                                                        <!-- IF ENTERED VALUE EXCEEDS STOCK QTY -->
                                                        <s v-if="item.qty != item.entered_qty"
                                                            >{{ item.entered_qty }}</s>
                                                        <span style="font-weight: bold;">&nbsp;
                                                            {{ item.qty }}</span> <span style="font-weight: bold;">{{ item.qty != 'None in stock.' && (item.form[item.form.length -1] != 'y' && item.qty > 1) ?
                                                                    item.form : ''
                                                            }}{{ item.qty > 1 && (item.form[item.form.length -1] != 'y') ? 's' : (item.form[item.form.length -1] == 'y' && item.qty >1) ?  item.form.slice(0,item.form.length-1)+'ies' : item.form }}</span> {{ transferType
        == 'Send' ? (item.qty_left ? '(Stock qty:' + item.qty_left + ')' :
            '(Stock qty: 0)') : ''
}}
                                                    </p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times" style='padding: 0'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>
                                    <div class="right-form-section-drug-container" v-if="checkItemBoxView">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td v-if="checkItemBoxView" style="min-width: 15px;">
                                                                <div @click.stop="item.selected = !item.selected" style="cursor: pointer">
                                                                <div v-if="item.selected && (item.selected == true)" class="check-circle circle-on"></div>
                                                                <div v-else class="circle-off"></div>
                                                               </div>
                                                             </td>
                                                <td style="width: 50%;">
                                                    <p>{{ item.stock }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        {{ item.qty }}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>


                            </div>



                        </form>
                    </slot>
                </div>
                <p style="color: red; font-size: 1em;margin: 0px;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px" @click="closeModal">Cancel</p>
                        <!-- submitTransferAction() -->
                        <!-- <p v-if="$store.state.company.type == 'warehouse'" class="regular-button grey-button" style=" margin-right: 10px;" @click="submitTransferAndCreateNew()">{{ transferType }}
                            Transfer + New</p> -->
                        <p :class="buttonchangeUi()" style="margin-right: 10px;"
                            @click="submitTransferAction()">{{ transferType }} Transfer</p>
                    </slot>
                </div>
            </div>
        </div>
        <NewLocationModal v-if="addNewLocation" @closeLocationModal="closeLocationModal"
            @addNewLocationToRefined="addNewLocationToRefined" />
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <SignatureModal v-if="displaySignatureModal" @close="close" @isSuccessful="isSuccessful"
            :saveContext="saveContext" :payload='realSubmit' endpoint="/transfers/new-transfer" :manual_loop="imprest_pom == 'imprest'  && $store.state.currentLocation.state == 'WA'" />
        <ReferencePrescriberModal @close="close" v-if="displayReferencePrescriberModal" @prescriber="getPrescriber" />
        <AddNewSupplierModal buttonName="Warehouse" v-if="openaddNewWarehouse" @closeLocationModal="closNewModal"/>
        <SuccessModal v-if="displayErrorModal" :first_spec_line="errorMessage" :statusMessage="errorTitle" @close="closeError" />
    </div>
</template>

<script>
import SuccessModal from './SuccessModal.vue';
import NewLocationModal from '@/components/modals/NewLocationModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import ReferencePrescriberModal from '@/components/modals/ReferencePrescriberModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import AddNewSupplierModal from '@/components/modals/AddNewSupplierModal.vue';
import axios from 'axios';
import { debounce } from 'debounce';
import { sortMedicationList } from '../../services/SortMedicationList'

export default {
    props: ['transferType','checkItemBoxView','actionParentdata'],
    components: {
        SuccessModal,
        NewLocationModal,
        SignatureModal,
        PatientSearchModal,
        ReferencePrescriberModal,
        AddNewSupplierModal
    },
    data() {
        return {
            displayReferencePrescriberModal: false,
            addingMed: false,
            loadingLocation: false,
            loadingDrugs:false,
            searchedLocation: '',
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            noStock: false,
            prescriberResults: [],
            addNew: false,
            patientMeds: [],
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            errorTitle: '',
            displayErrorModal: false,
            selectedDrug:{},
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            qty: null,
            location: '',
            addNewLocation: false,
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: '',
            drugList:[],
            form: {
                submit: {
                    type: '',
                    to_from: '',
                    drugs: [],
                    notes: '',
                }
            },
            realSubmit: {
                type: '',
                to_from: '',
                drugs: [],
                notes: '',
            },
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'Transfer',
                patient: '',
                location: '',
                drugs: [],
                doubleSig: false
            },
            openaddNewWarehouse:false,
        }
    },
    methods: {
        getPrescriber(value){
            console.log('getprescriber function is called', value);
            if(value){
                this.selectedPrescriber = value;
                this.displayReferencePrescriberModal = false;
            }
            
        },
        showLocations(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingLocation && this.refinedLocations) {
                    clearInterval(interval);
                    this.$refs.location.show();
                }
            }, 100)
        },
        debounceSearchDrugs : debounce(function() {
                this.searchDrugs()
        },[1000]),
        getDrugOptionLabel(){
          if (this.transferType === 'Send') {
                return 'alias.generic_name'
            } else {
                return 'name';
            }
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        searchLocation : debounce (function(){
            if (this.searchedLocation) {
                this.loadingLocation = true;
                if (this.$store.state.company.type == 'warehouse') {
                    const payload={
                        location_type: [],
                        filters: {
                            party:this.searchedLocation,
                        }
                    }
                axios.post('/warehouse/retrieve-locations', payload , this.$store.state.header).then(async res => {
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    let refined = await res.data.data;
                    // res.data.locations.forEach(location=>{
                    //     if(location.uuid != JSON.parse(localStorage.getItem('currentLocation')).uuid){
                    //         refined.push(location);
                    //     }
                    // })
                    this.refinedLocations = refined;
                    if(this.refinedLocations.length){
                        this.$refs.location.show();
                    }

                    if (this.refinedLocations.length == 1) {
                        this.form.submit.to_from = this.refinedLocations[0].uuid;
                        this.location = this.refinedLocations[0].name;
                    }
                    this.loadingLocation = false;
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                });
                }else{
                    axios.post('/get-locations', { location: this.searchedLocation}, this.$store.state.header).then(res => {
                    
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    let refined =[];
                    res.data.locations.forEach(location=>{
                        if(location.uuid != JSON.parse(localStorage.getItem('currentLocation')).uuid){
                            refined.push(location);
                        }
                    })
                    this.refinedLocations = refined;
                    if(this.refinedLocations.length){
                        this.$refs.location.show();
                    }

                    if (this.refinedLocations.length == 1) {
                        this.form.submit.to_from = this.refinedLocations[0].uuid;
                        this.location = this.refinedLocations[0].name;
                    }
                    this.loadingLocation = false;
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                });
                }
            }
        },[1000]),
        drugSelect(item) {
            this.loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = this.transferType == 'Send' ? item.alias.alias_id : item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = this.transferType == 'Receive' ? item.form[0].toUpperCase() + item.form.slice(1) : item.alias.form[0].toUpperCase() + item.alias.form.slice(1);
            this.selectedDrug.strength = `${this.transferType == 'Send' ? item.alias.strength : item.strength}`;
            this.selectedDrug.measurement_unit = `${this.transferType == 'Send' ? item.alias.measurement_unit : item.measurement_unit}`;
            this.selectedDrug.name = this.transferType == 'Send' ?  item.alias.name[0].toUpperCase() + item.alias.name.slice(1) : item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = this.transferType == 'Send' ? item.alias.tags : item.tags;
            this.loadingDrugs = false;
        },
        // searchDrugs() {
            
        //     if(this.stock){
        //         this.loadingDrugs = true;
        //         axios.post('/get-drug-aliases', { drug: this.stock, generics: parseInt(this.drugSearchFilter) }, this.$store.state.header).then(res => {
        //             console.log('this is the res data', res.data);
        //             res.data.drug_aliases.forEach(item=>{
        //                 item.full_name = `(${item.generic_name}) ${item.name}`
        //             })
        //             this.drugList = res.data.drug_aliases;

        //             this.drugSelect(this.drugList[0]);
        //             this.loadingDrugs = false;
        //             // document.getElementsByClassName('p-dropdown-panel')[0].style.display = 'block';
        //         }).catch(err => {
        //             console.log('this is the err', err);
        //             this.loadingDrugs = false;
        //             if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
        //                 console.log('yes, it does include expired')
        //                 this.$router.push('/login');
        //             }
        //         })
        //     }
        // },
        openCreatedModal() {
                this.openaddNewWarehouse = true
        },
        locationSelect() {
            this.location = this.searchedLocation.name;
            this.form.submit.to_from = this.searchedLocation.uuid;
            this.$refs.location.hide();
        },
        changeStock() {
            this.stock = this.patientMeds[document.getElementById('pom-select').value];
            this.qty = Number(this.stock.quantity).toFixed(1);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`, 
            // params: `query=${prescriber}&hitsPerPage=5`,
            { 
              requests:[
                { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
                { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}&facetFilters=company_uuid:${this.$store.state.company.uuid}` },
              ]  
            },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);
                    res.data.results.forEach(indexResult=>{
                        console.log('these are the results for this particular index', indexResult);
                        if(indexResult.hits.length){

                            // results.push(indexResult.hits);
                            indexResult.hits.forEach(hit=>{
                                this.prescriberResults.push(hit);
                            })
                            console.log('this is the local results varaible', this.prescriberResults);
                        }
                        
                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        isSuccessful(value) {
            if (value) {
                console.log('IS SUCCESSFUL!');
                // if (this.$route.name == 'Drug Register') {
                //     console.log('loading the drug register stuff');
                //     this.$store.dispatch('getDrugRegister', {
                //         sort_direction: 'desc',
                //         col: 'created_at',
                //         page: 1,
                //         filters: {
                //             transaction_id: '',
                //             to_from: '',
                //             entered_by: '',
                //             date: {
                //                 start: '',
                //                 end: ''
                //             },
                //             drug: '',
                //             imprest_only: false
                //         }
                //     });
                // } else if (this.$route.name == 'Stock') {
                //     console.log('loading the stock stuff');
                // } else
                 if (this.$route.name == 'Transfers') {
                    console.log('loading the transfer stuff');
                    this.$store.dispatch('getTransfers', {
                        sort_direction: 'desc',
                        col: 'created_at',
                        page: 1,
                        location: this.$store.state.uuid,
                        filters: {
                            status: ['pending', 'reversed', 'rejected', 'completed'],
                            completed_by: '',
                            location: '',
                            type: ['send', 'receive'],
                            date: {
                                start: '',
                                end: ''
                            },
                        }
                    });
                } 
                // else if (this.$route.name == 'Stock Take') {
                //     console.log('loading the stock take version stuff');
                //     this.$store.dispatch('getStockTake', {
                //         sort_direction: '',
                //         col: '',
                //         page: 1,
                //         location: this.$store.state.uuid,
                //         filters: {
                //             tags: ["s8"],
                //             show_inactive: false,
                //             in_stock: false,
                //             date: {
                //                 start: '',
                //                 end: ''
                //             },
                //             product_name: '',
                //         }
                //     });
                // }

                if (this.addNew) {
                    this.form.submit.drugs = [];
                    this.stock = '';
                    this.imprest_pom = '';
                    this.patient = '';
                    this.patient_uuid = '';
                    this.prescriber_uuid = '';
                    this.selectedPrescriber = '';
                    this.refinedDrugs = '';
                } else {
                    this.$emit('close', true,'success');
                }
            }
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.displayReferencePrescriberModal = false;
                // this.dual = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewLocation = false;
            }
        },
        closNewModal(){
            this.openaddNewWarehouse = false
        },
        addNewLocationToRefined(value) {
            this.form.submit.to_from = value.uuid;
            this.refinedLocations = [value];
            console.log('RECEIVING FROM ADD LOCATION', value);
            this.location = value.name;
        },
        checkIfExistingItem(item){
            let exit = false;
            if(this.form.submit.drugs.length){
                this.form.submit.drugs.forEach(drugItem => {
                    console.log('LOOPING THROUGH LIST', drugItem, item);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit ) {
                        console.log('HIT A MATCH', drugItem);
                        drugItem.qty = this.qty;
                        exit = true;
                    } 
                })
                if (!exit) {
                    this.aliasSelect(item);
                }
            } else{
                this.aliasSelect(item);
            }
        },
        aliasSelect(item) {
            console.log('this is the alias select item', item);
            this.addingMed = true;
                    if (this.transferType == 'Receive') {
                        if (((this.imprest_pom == 'imprest' || this.imprest_pom == '') && this.transferType != 'Receive')) {
                            axios.post('/get-stock', { alias_id: item.alias_id ?? item.uuid, location_id: this.$store.state.uuid }, this.$store.state.header).then(res => {

                                console.log('this is the quantity if condition', this.qty, res.data.qty_left, this.qty > res.data.qty_left);
                                
                                this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, form: item.form, drug: item.alias_id || item.uuid, entered_qty: this.qty, qty_left: res.data.qty_left, qty: this.qty <= Number(res.data.qty_left) ? Number(this.qty).toFixed(1) : Number(res.data.qty_left).toFixed(1) });

                                // this.form.submit.drugs.unshift({tags: item.alias.tags,stock:res.data.drug_alias, 'drug': item.alias_id, form: res.data.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: res.data.qty_left});
                                this.$store.dispatch('getRefreshToken');
                                this.qty = null;
                                this.stock = '';
                                this.refinedDrugs = [];
                                this.selectedDrug = {};
                                this.noStock = false;
                                this.addingMed = false;
                            }).catch(err => {
                                console.log('err is', err.response.data);
                                if (err.response.data == 'No stock has been recorded on this location for that drug alias.') {
                                    console.log('hitting the if condition');
                                    this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id || item.uuid, form: item.form, entered_qty: this.qty, qty: 'None in stock.', qty_left: 0 });
                                }
                                this.qty = null;
                                this.stock = '';
                                this.refinedDrugs = [];
                                this.selectedDrug = {};
                                this.addingMed = false;
                            })
                        } else {
                            console.log('hitting else condition')
                            if (this.transferType == 'Receive') {
                                this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, form: item.form, drug: item.alias_id || item.uuid, entered_qty: this.qty, qty: this.qty });

                                // this.form.submit.drugs.unshift({tags: item.alias.tags,stock:res.data.drug_alias, 'drug': item.alias_id, form: res.data.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: res.data.qty_left});
                                this.$store.dispatch('getRefreshToken');
                                this.qty = null;
                                this.stock = '';
                                this.refinedDrugs = [];
                                this.selectedDrug = {};
                                this.addingMed = false;
                                this.noStock = false
                            } else {
                                this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: item.alias.name, form: item.alias.form, qty_left: item.quantity, drug: item.alias.alias_id, entered_qty: this.qty, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity) });
                                this.selectedDrug = {};
                                this.stock = '';
                                this.addingMed = false;
                            }
                            // console.log('this is the quantity else condition', this.qty, Number(res.data.qty_left), this.qty > Number(res.data.qty_left))

                        }
                    } else {
                        if ((this.imprest_pom == 'imprest' || this.imprest_pom == '')) {
                            this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: item.alias.name, drug: item.uuid, form: item.alias.form, qty: this.qty, qty_left: item.qty_left });
                            this.qty = null;
                            this.refinedDrugs = '';
                            this.stock = '';
                            this.selectedDrug = {};
                            this.noStock = false;
                            this.addingMed = false;
                        } else {
                            this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: item.alias.name, form: item.alias.form, qty_left: item.quantity, drug: item.uuid, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity) });
                            this.selectedDrug = {};
                            this.stock = '';
                            this.addingMed = false;
                        }
                    }
        },
        algoliaDrugSearch(searchedDrugName) {
            var searchName = '';
            this.drugList = [];
            if (typeof searchedDrugName === 'object') {
                searchName = searchedDrugName?.name;
            } else {
                searchName = searchedDrugName;
            }
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`,
                { params: `typoTolerance=false&query=${searchName}${this.$store.state.company.type == 'warehouse' ? "&facetFilters=deprecated:false" : ""}` },
                { headers })
            .then(async res => {
                console.log('this is the algolia res', res.data.hits);
                this.$store.dispatch('getRefreshToken');
                res.data.hits.forEach(item => {
                    item.generic_name = item.generic_name.charAt(0).toUpperCase() + item.generic_name.slice(1);
                    item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                    item.full_name = `${item.name} [In stock: ${item.quantity}]`;
                    if (item.quantity == 0) {
                        item.disabled = true;
                    } else {
                        item.disabled = false;
                    }
                })
                this.drugList = await sortMedicationList(res.data.hits)
                this.errorMessage = '';
                this.loadingDrugs = false;
                this.$refs.med.show();
            }).then(() => {
                const medListDropdown = document.querySelector('#transfer-med-search_list');
                medListDropdown.querySelectorAll('.p-dropdown-item.p-focus').forEach((med, index) => {
                    med.classList.remove('p-focus');
                });
            }).then(() => {
                const medListDropdown = document.querySelector('#transfer-med-search_list');
                const firstMed = medListDropdown.querySelector('.p-dropdown-item');
                if(firstMed) {
                    firstMed.classList.add('p-focus');
                }
            })
        },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;
                 if (this.transferType == 'Receive') {
                    if (this.$store.state.company.type == 'warehouse') {
                    this.algoliaDrugSearch(this.stock)
                    }else{
                        this.drugList = [];
                        axios.post('/get-drug-aliases', { drug: this.stock }, this.$store.state.header).then(res => {
                            console.log('this is the res data', res.data);
                            this.$store.dispatch('getRefreshToken');
                            res.data.drug_aliases.forEach(item=>{
                                item.generic_name = item.generic_name.charAt(0).toUpperCase() + item.generic_name.slice(1);
                                item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                                item.full_name = `(${item.generic_name}) ${item.name}`;
                            })
                            this.drugList = sortMedicationList(res.data.drug_aliases);
                            this.errorMessage = '';
                            this.loadingDrugs = false;
                        })
                        .then(() => {
                            const medListDropdown = document.querySelector('#transfer-med-search_list');
                            medListDropdown.querySelectorAll('.p-dropdown-item.p-focus').forEach((med, index) => {
                                med.classList.remove('p-focus');
                            });
                        }).then(() => {
                            const medListDropdown = document.querySelector('#transfer-med-search_list');
                            const firstMed = medListDropdown.querySelector('.p-dropdown-item');
                            if(firstMed) {
                                firstMed.classList.add('p-focus');
                            }
                        })
                        .catch(err => {
                            this.errorMessage = err.response.data;
                            console.log('err', err.response.data);
                        })
                    }
                } else {
                    this.drugList = [];
                    axios.post('/get-stock-items', { drug: this.stock }, this.$store.state.header).then(res => {
                        console.log('this is the res data getstockitems', res.data);
                        this.$store.dispatch('getRefreshToken');
                        res.data.forEach(item=>{
                            item.alias.generic_name = item.alias.generic_name.charAt(0).toUpperCase() + item.alias.generic_name.slice(1);
                            item.alias.name = item.alias.name.charAt(0).toUpperCase() + item.alias.name.slice(1);

                            item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`;
                            // add name key to the data array as comparion key for the sort
                            item.name = `${item.alias.name}`;

                            if(item.qty_left == 0){
                                item.disabled = true;
                            } else{
                                item.disabled = false;
                            }
                        })
                        this.drugList = sortMedicationList(res.data);
                        this.errorMessage = '';
                        this.loadingDrugs = false;
                    })
                    .then(() => {
                        const medListDropdown = document.querySelector('#transfer-med-search_list');
                        medListDropdown.querySelectorAll('.p-dropdown-item.p-focus').forEach((med, index) => {
                            med.classList.remove('p-focus');
                        });
                    }).then(() => {
                        const medListDropdown = document.querySelector('#transfer-med-search_list');
                        const firstMed = medListDropdown.querySelector('.p-dropdown-item');
                        if(firstMed) {
                            firstMed.classList.add('p-focus');
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                        console.log('this is the transfer stock items error', err.response.data);
                        this.errorMessage = err.response.data;
                    })
                }
            }
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        closeError(value) {
            if (value) {
                this.errorTitle = '';
                this.errorMessage = '';
                this.displayErrorModal = false;
            }
        },
        submitTransferAction() {

            if (this.form.submit.notes.trim() == '') {
                this.displayErrorModal = true;
                this.errorTitle = 'Error';
                this.errorMessage = 'Notes is required';
                return;
            }

            let newDrugSub = [];
            let autoDrugs = [];
            this.addNew = false;
            if (this.checkItemBoxView) {
                const filterdata = this.form.submit.drugs.filter((item)=> item?.selected && item.selected === true)
               filterdata.forEach(item => {
                let p = {
                    batch_uuid: item?.batch_uuid,
                    drug: item.drug,
                    alias: item.stock,
                    qty: Number(item.qty),
                    tags: item?.tags,
                    batch_item_id:item?.batch_item_id
                }
                let ad = {
                    drug: item.stock,
                    qty: Number(item.qty),
                    tags: item?.tags,
                    batch_item_id:item?.batch_item_id,
                    batch_uuid: item?.batch_uuid,
                }
                autoDrugs.push(ad);
                // if(item.tags && item.tags.length){
                //     this.dual = true;
                // }
                if (item.qty > 0) {
                    newDrugSub.push(p);
                }
            });
            }else{
                this.form.submit.drugs.forEach(item => {
                    let p = {
                        drug: item.drug,
                        alias: item.stock,
                        qty: Number(item.qty),
                        tags: item.tags
                    }
                    let ad = {
                        drug: item.stock,
                        qty: Number(item.qty),
                        tags: item.tags
                    }
                    autoDrugs.push(ad);
                    // if(item.tags && item.tags.length){
                    //     this.dual = true;
                    // }
                    if (item.qty > 0) {
                        newDrugSub.push(p);
                    }
                });
            }
            this.realSubmit = {
                type: this.transferType === 'Receive' ? 'receive' : 'send',
                to_from: this.form.submit.to_from,
                drugs: this.form.submit.drugs,
                notes: this.form.submit.notes,

                prescriber_uuid: this.selectedPrescriber ? this.selectedPrescriber.uuid : '',
                patient_uuid: this.patient ? this.patient.uuid : ''
            };
            this.saveContext = {
                drugs: autoDrugs,
                location: JSON.parse(localStorage.getItem('currentLocation')).name,
                patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : 'Imprest/Emergency Stock',
                supplemental: this.patient && this.patient.regulatory_information.length ? `${this.patient.regulatory_information[0].regulatory_type}: ${this.patient.regulatory_information[0].data}` : ``,
                doubleSig: true,
                type: `Transfer ${this.transferType == 'Receive' ? 'In' : 'Out'}`
            }

            this.realSubmit.drugs = newDrugSub;
            console.log('this is the form to submit', this.form, this.saveContext);
            console.log(this.form.submit.drugs.length && this.form.submit.type && this.form.submit.to_from)
            if (this.checkItemBoxView) {
                if (this.realSubmit.drugs?.length > 0 && this.form.submit.to_from && this.realSubmit.type) {
                    this.displaySignatureModal = true;
                    this.errorMessage = '';
                } else {
                    this.errorMessage = 'Drugs and a location is required.'
                }
            }else{
                if (this.form.submit.drugs.length && this.realSubmit.type && this.form.submit.to_from) {
                    this.displaySignatureModal = true;
                    this.errorMessage = '';
                } else {
                    this.errorMessage = 'Drugs and a location is required.'
                }
            }
        },
        buttonchangeUi(){
        if (this.$store.state.company.type == 'warehouse') {
            if (this.checkItemBoxView) {
                    const selectData2 = this.form.submit.drugs.filter((item)=> item?.selected && item.selected === true)
                    if (selectData2?.length > 0 && this.form.submit.to_from && this.form.submit.to_from) {
                        return 'regular-button green-button'
                    } else {
                        return 'regular-button grey-button'
                    }
                }else{
                    if (this.form.submit.to_from && this.form.submit.drugs?.length > 0) {
                        return 'regular-button green-button'
                    } else {
                        return 'regular-button grey-button'
                    }
                }
        }else{
            return 'regular-button green-button'
        }
        },
        selectPrescriber(prescriber) {
            console.log('this is the selected result', this.nonGP);
            this.prescriberResults = [];
            this.selectedPrescriber = {
                prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
                uuid: prescriber.uuid,
                first_name: prescriber.first_name,
                last_name: prescriber.last_name,
                phone: prescriber.phone,
                after_hours: prescriber.after_hours,
                address: prescriber.address,
                email: prescriber.email,
                fax: prescriber.fax,
                primary_gp: false
            }
        },
        changePrescriber() {
            this.selectedPrescriber = '';
            this.gp = '';
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                // {uuid: this.patient.uuid}

                axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                    console.log('this is the patient register', res);
                    this.patientMeds = res.data;
                })
            }
        },
        submitTransferAndCreateNew() {
            this.realSubmit = {
                type: this.form.submit.type.toLowerCase(),
                to_from: this.form.submit.to_from,
                drugs: this.form.submit.drugs,
                notes: this.form.submit.notes,
                prescriber_uuid: this.selectedPrescriber ? this.selectedPrescriber.uuid : '',
                patient_uuid: this.patient ? this.patient.uuid : '',
            };
            if (this.form.submit.drugs.length && this.form.submit.type && this.form.submit.to_from) {
                this.addNew = true;
                this.form.submit.type = this.transferType;
                console.log('this is the form to submit', this.form);
                this.form.submit.drugs.forEach(drug => {
                    delete drug.qty_left;
                    drug.qty = Number(drug.qty);
                })
                // this.$store.dispatch('submitDelivery',this.form);
                // this.refinedLocations = [];

                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Drugs and location are required.'
            }
        },
    },
    mounted() {
        if (this.actionParentdata?.items) {
            if (this.actionParentdata?.items?.length > 0) {
                this.form.submit.drugs = this.actionParentdata?.items
                return this.actionParentdata?.items
            }
        }
        this.form.submit.type = this.transferType;
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
    // td:first-child{
    //     border-top-left-radius: 12px;
    //     border-bottom-left-radius: 12px;
    // }
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}
</style>