<template>
    <div class="drug-register-table table-container"
        style="width: auto; box-shadow: none; padding-left: 0; margin-top: -23px; grid-template-rows: [row1-start] 90px [row1-end] minmax(200px, 75vh) [third-line] 50px [fourth-line]">
        <div class="filters">
            <div class="text-search-container search-container" style="display: flex; align-items: flex-end">
                <!-- <input v-model="searchName" placeholder="Resident..."
                    style="position: relative; margin-right: 10px;" /> -->

                <div>
                    <p style="font-size: 1em; margin-left: 15px; text-align: left; font-weight: 500">Select a Resident</p>
                    <Dropdown
                        :options="residentList"
                        @change="loadNotifications"
                        optionLabel="fullName"
                        optionValue="uuid"
                        v-model="form.resident_id"
                        :editable="true"
                        style="width: 330px; display: flex"
                        placeholder="Select Resident"
                    />
                </div>

                <div>
                    <p style="font-size: 1em; margin-left: 15px; text-align: left; font-weight: 500">Select a Facility</p>
                    <MultiSelect v-model="searchFacilities" :options="facilities" placeholder="Select a Facility" @change="updateResults()" style="width: 330px;" />
                </div>
                
                <!-- <button class="button clear-button" @click="clearSearch()" style="margin-right: 2em">Clear</button> -->

                <div>
                    <p style="font-size: 1em; margin-left: 15px; text-align: left; font-weight: 500">View</p>
                    <SelectButton style="display: flex; padding: 0; border-radius: 6px; width: 200px;" class="read-select"
                    v-model="form.read" :options="displayOptions" optionLabel="name" optionValue="value" @click="loadNotifications()" />
                </div>
            </div>
        </div>
        <div style="overflow-y: auto; margin-top: -1.5em;">
            <div>
                <table class="data-table" cellspacing="0">
                    <!-- LOADING SPINNER -->
                    <!-- <div  v-if="!getDrugEntries" >
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                </div> -->
                <!-- v-else  -->
                <tr class="table-headers" >
                    <th style="width: 7em;">
                        <h3 style="padding-left: 20px">Status</h3>
                    </th>
                    <th style="width: 8em;">
                        <h3 style="padding: 10px">Date</h3>
                    </th>
                    <th style="width: 8em;"> 
                        <h3 >Resident</h3>
                    </th>
                    <th> 
                        <h3>Info</h3>
                    </th>
                    <th style="width: 9em;"> 
                        <h3 style="padding: 10px">Prescriber</h3>
                    </th>
                    <th style="width: 10em"> 
                        <h3>Facility</h3>
                    </th>
                    <th style="min-width: 7em"> 
                        <h3>Medication Time (Dose)</h3>
                    </th>
                    <th style="width: 8em"> 
                        <h3>Action</h3>
                    </th>
                    <th style="width: 3em">
                    </th>
                </tr>
                
                <tbody class="table-data" v-for="(item, index) in filteredTasks.length ? filteredTasks: tasks" :key="index">
                    <tr>
                        <td style="padding-left: 20px;">
                            <p class="button task-status-indicator" style="background-color:#EF9600" v-if="item.text == 'Prescription Resupply.'">
                                Resupply
                            </p>
                            <!-- Remove phone tagging as status and replace it with a green tag in info column -->
                            <p v-else class="button task-status-indicator"
                            :style="/*item.fullPrescription?.phone_order ? {backgroundColor: '#2c3e50'} : */(item.status == 'New' || item.status == 'new' ) && !item.fullPrescription.old_prescription_id ? {backgroundColor: '#00AD50'}:item.status == 'Cancel' || item.status == 'cancel' ? {backgroundColor: '#E04F39'}: 
                            item.status == 'Hold' || item.status == 'hold' ? {backgroundColor: '#EF9600'}: item.status == 'Update' || item.status == 'update' || item.fullPrescription.old_prescription_id ? {backgroundColor: '#9F5CC0'}:
                            item.status == 'Draft' || item.status == 'draft' ? {backgroundColor: '#0870DC'}:''">
                                {{ /*item.fullPrescription?.phone_order ? 'Phone' : */(item.status == 'New' || item.status == 'new' ) && item.fullPrescription.old_prescription_id ? 'Update' : item.status.slice(0,1).toUpperCase()+item.status.slice(1)}}
                            </p>
                        </td>
                        <td><p style="padding: 10px">{{item.date}}</p></td>
                        <td><p style="font-weight: bold; color: #0870DC; cursor: pointer;" @click="choosePatient(item.patient.uuid)">{{item.patient.first_name}} {{item.patient.last_name}}</p></td>
                        <td>
                            {{item.info}}
                            <span style="margin-right: 5px" v-if="item.fullPrescription?.phone_order" class="green-tag">PHONE</span>
                        </td>
                        <td style="padding: 10px">{{item.prescriber}}</td>
                        <td>{{item.facility }}</td>
                        <td>{{item.frequency_shorthand}} ({{item.dose}} {{item.dose_unit}}/s)</td>
                        <!-- <td><span v-if="item.times"><b>{{item.frequency}}: </b>[<span v-for="(time, i) in item.times" :key="'p'+i">{{time}}({{ item.dose }}){{i == (item.times.length -1) ? '':', '}} </span>]</span></td> -->
                        
                        <td v-if="item.status && item.status != 'Hold' && item.status != 'Stop' && item.status != 'Draft'"><button class="blue-button" @click="expandPatientSummaryModal=true, patientId=item.patient.uuid, reviewedPrescription = item.fullPrescription, toMarkRead=item.uuid, itemIndex=index" style="width: 90px; ">Review</button></td>
                        <td v-else><p class="blue-button" style="text-align: center; width: 90px; margin-left: 10px;" @click="removeFromList(item.uuid, index)">Mark as Read</p></td>
                        <td >
                            <i v-if="item.status && !form.read" class="pi pi-check confirm-check" style="border: 1px solid #dadada; color: #ababab; font-weight: bold; background-color: white" @click="confirmTask(item, index)" :v-tooltip="item.toAction ? 'Confirm' : 'Acknowledge'" ></i>
                        </td>
                    </tr>
                </tbody>
                </table>
                <!-- <div v-if="searchedPatient && !filteredTasks.length">
                    <p>No residents found.</p>
                </div> -->
                <div v-if="loadingTasks">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 2em;"></i>
                </div>
                <div v-if="!loadingTasks && !form.read && !tasks.length">
                    <p style="font-size: 1em; margin-top: 3em;">No unread tasks.</p>
                </div>
                <div v-if="!loadingTasks && form.read && !tasks.length">
                    <p style="font-size: 1em; margin-top: 3em;">No read tasks.</p>
                </div>
            </div>
        </div>

        <div class="pagination-links">
            <p class="pagination-arrow" v-show="current_page > 1"
                @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{ current_page }} of {{ page_count }}</p>
            <p class="pagination-arrow"
                v-show="current_page < page_count"
                @click="displayPage(current_page + 1)"> &rarr; </p>
        </div>
        <PatientSummaryModal v-if="expandPatientSummaryModal" :itemIndex="itemIndex" @markAsRead="markAsRead" @close="close" :notification_id="toMarkRead" :patientId="patientId" :reviewedPrescription="reviewedPrescription" :viewOnly="true" />
    </div>
</template>

<script>
import axios from 'axios';
import PatientSummaryModal from '../modals/PatientSummaryModal.vue';


export default {
    props: [
        'patients',
        'loadingPatients'
    ],
    components: {
        PatientSummaryModal
    },
    data() {
        return {
            toMarkRead:'',
            items: [{
                label: 'Update',
                icon: 'pi pi-refresh',
                command: () => {
                    this.$toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.$toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                }
            }
            ],
            form: {
               page: 1,
               read: false,
               resident_id: ''
            },
            actionTypes: [
                'Packed',
                'Non-packed'
            ],
            displayOption:'',
            displayOptions:[
                {name:'Read',
                value: true},
                {name:'Unread',
                value: false},
            ],
            loadingTasks: false,
            patientId: '',
            expandPatientSummaryModal: false,
            facilities: [],
            searchFacilities: [],
            searchedByPatientName:[],
            searchedPatient: false,
            tasks: [],
            filteredTasks:[],
            current_page: 1,
            page_count: 1,
            searchName:'',
            reviewedPrescription:''
        }
    },
    methods: {
        markAsRead(value){
            this.$store.dispatch('getRefreshToken');
            console.log('hitting mark as read here', value);
            this.tasks.splice(value.index, 1);
            axios.post('/read-notifications', {
                notification_ids: [value.id]
            }, this.$store.state.header).then(res => {
                console.log('market item as read', res);
                this.expandPatientSummaryModal = false;
                this.loadNotifications();
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        displayPage(page){
            this.$store.dispatch('getRefreshToken');
            this.$store.dispatch('getRefreshToken');
            this.form.page = page;
            this.loadNotifications();
        },
        confirmTask(item, index) {
            this.$store.dispatch('getRefreshToken');
            console.log('will send this', item.toAction, item.chart_id);
            if(item.prescription && item.toAction){
                console.log('this is a prescription, so will be adding chart tag');
                axios.post(`/chart/${item.chart_id}/add-tags`, {
                    tags: [item.toAction.toLowerCase()]
                }, this.$store.state.header).then(res => {
                    console.log('added tags to chart', res);
                }).catch(err => {
                    console.log('this is the err', err);
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
            this.removeFromList(item.uuid, index);
        },
        removeFromList(id, index) {
            this.$store.dispatch('getRefreshToken');
            this.tasks.splice(index, 1);
            console.log('will be removing this from list', id);
            axios.post('/read-notifications', {
                notification_ids: [id]
            }, this.$store.state.header).then(res => {
                console.log('market item as read', res);
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired');
                    this.$router.push('/login');
                }
            })
        },
        close(value) {
            this.$store.dispatch('getRefreshToken');
            if (value) {
                this.expandPatientSummaryModal = false;
                this.loadNotifications()
            }
        },
        choosePatient(id) {
            this.$store.dispatch('getRefreshToken');
            this.patientId = id;
            // this.expandPatientSummaryModal = true;
            this.$router.push(`/patient/${id}`);
        },
        clearSearch(){
            this.$store.dispatch('getRefreshToken');
            this.searchName = [];
            this.searchFacilities = [];
            this.form.resident_id = '';
            this.loadNotifications();
        },
        loadNotifications() {
            this.$store.dispatch('getRefreshToken');
            this.loadingTasks = true;
            this.searchedPatient = false;
            this.tasks = [];
            this.filteredTasks = [];
            axios.post('/get-notifications', this.form, this.$store.state.header).then(res => {
                console.log('THESE ARE THE NOTIFICATIONS', res.data);
                this.current_page = res.data.header.current_page;
                this.page_count = res.data.header.page_count;
                
                res.data.notifications.forEach(notification => {
                    // if(this.form.read){
                        if (notification.subject && notification.context != 'Draft' && notification.context != 'draft') {
                            console.log('this is the notification being mapped', notification, notification.context)
                            // draft -> signed (via chart review) comes up as new
                            // but when reviewed again the current signed from drafts are deleted and will contain subject.deleted_at
                            // so - we skip these notifications from the task log; else they will come up as cancelled
                            if(notification.context.toLowerCase() == 'new' && notification.subject.deleted_at != null){
                                return;
                            }
                            
                            this.tasks.push({
                                status: notification.subject && notification.subject.deleted_at ? 'Cancel' : notification.context,
                                text: notification.text,
                                fullPrescription: notification.prescription? {
                                    alias_name: notification.prescription?.alias?.name ? notification.prescription.alias.name : '',
                                    alias_generic: notification.prescription?.alias?.generic_name ? notification.prescription.alias.generic_name : '',
                                    // strength: notification.prescription.alias.strength,
                                    doctor: notification.prescription?.doctor ? notification.prescription.doctor : '',
                                    phone_order: notification.prescription?.phone_order ? true : false,
                                    alias_tags: notification.prescription?.alias?.tags ? notification.prescription.alias.tags : '',
                                    chart_tags: notification.prescription?.chart?.tags ? notification.prescription.chart.tags : '',
                                    start_date: notification.prescription && notification.prescription.chart ? notification.prescription.chart.start_date : '',
                                    stop_date: notification.prescription && notification.prescription.chart ? notification.prescription.chart.stop_date : '',
                                    created_date: notification.prescription && notification.prescription.created_at ? notification.prescription.created_at : '',
                                    pbs_code: notification?.prescription?.pbs_code ? notification.prescription.pbs_code : '',
                                    authority_prescription_number: notification?.prescription?.authority_prescription_number ? notification.prescription.authority_prescription_number : '',
                                    route: notification.prescription && notification.prescription.route ? notification.prescription.route  : '',
                                    chart_type: notification.prescription && notification.prescription.chart ? notification.prescription.chart.type : '',
                                    dose: notification.prescription && notification.prescription.chart ? notification.prescription.chart.dose_amount : '',
                                    dose_times: notification.prescription ? notification.prescription.dose_times : '',
                                    frequency: notification.prescription ? notification.prescription.frequency : '',
                                    frequency_shorthand: notification.prescription ? notification.prescription.frequency_shorthand : '',
                                    instructions: notification?.prescription?.instructions?.text ? notification.prescription.instructions.text : '',
                                    max_dose: notification.prescription ? notification.prescription.max_dose : '',
                                    interval: notification?.prescription?.interval ? notification.prescription.interval : '',
                                    repeats: notification?.prescription?.num_repeats_authorised ? notification.prescription.num_repeats_authorised : 0,
                                    repeat_interval: notification?.prescription?.repeat_interval ? notification.prescription.repeat_interval : 0,
                                    old_prescription_id: notification.prescription && notification.prescription.has_old_prescription ? notification.prescription.has_old_prescription : '',
                                    chart_uuid: notification.prescription && notification.prescription.chart ? notification.prescription.chart.uuid : '',
                                    alias_uuid: notification.prescription ? notification.prescription.alias.alias_id : '',
                                    prescription_id: notification.prescription && notification.prescription.uuid ? notification.prescription.uuid : '',
                                    brand_sub: notification?.prescription?.subtitution_not_permitted ? 'Not permitted' : 'Allowed',
                                    prescription_number: notification.prescription && notification.prescription.prescription_id ? notification.prescription.prescription_id : '',
                                    prescribed_quantity: notification.prescription?.prescribed_quantity ? notification.prescription.prescribed_quantity : '',
                                    notes: notification.prescription?.chart_note.length > 0 ? notification.prescription?.chart_note[notification.prescription.chart_note.length -1].text : '',
                                    latest_dispensed_item: notification.prescription?.latest_dispensed_item ? notification.prescription.latest_dispensed_item : ''
                                } 
                                    : null,

                                prescription: notification.prescription ? notification.prescription.uuid : null,
                                prescription_id: notification.prescription && notification.prescription.prescription_id ? notification.prescription.prescription_id : null,
                                info: notification.prescription.alias.name,
                                uuid: notification.uuid,
                                chart_id: notification.prescription.chart ? notification.prescription.chart.uuid : '',
                                date:new Date(notification.created_at).toLocaleDateString('en-AU')+ ', '+ notification.created_at.split(',')[2], 
                                times: notification.prescription ? notification.prescription.times : '',
                                dose: notification.prescription ? notification.prescription.dose : '',
                                dose_unit: notification.prescription ? notification.prescription.dose_unit : '',
                                frequency: notification.prescription ? notification.prescription.frequency : '',
                                frequency_shorthand: notification.prescription ? notification.prescription.frequency_shorthand : '',
                                patient: {
                                    first_name: notification.prescription ? notification.prescription.patient.personal_information.first_name : 'N/A',
                                    last_name: notification.prescription ? notification.prescription.patient.personal_information.last_name : '',
                                    uuid: notification.prescription ? notification.prescription.patient.uuid : ''
                                },
                                prescriber: notification.prescription?.doctor ? `${notification.prescription.doctor.personal_information.first_name.slice(0,1)}. ${notification.prescription.doctor.personal_information.last_name}` : '',
                                facility: notification.prescription && notification.prescription.patient.physical_location ? notification.prescription.patient.physical_location.location.facility.name : 'N/A',
                                toAction: ''
                            })
                            if(notification.prescription && notification.prescription.patient.physical_location && notification.prescription.patient.physical_location.location.facility.name){
                                this.facilities.push(notification.prescription.patient.physical_location.location.facility.name);
                            }
                        } else {
                            if (notification.context != 'Draft' && notification.context != 'draft') {
                                this.tasks.push({
                                    status: '',
                                    context: notification.context,
                                    info: notification.text,
                                    text: notification.text,
                                    uuid: notification.uuid,
                                    chart_id: '',
                                    date: new Date(notification.created_at).toLocaleDateString('en-AU')+ ', '+ notification.created_at.split(',')[2],
                                    patient: '',
                                    prescriber: '',
                                    facility: 'N/A',
                                    toAction: ''
                                })
                            }

                        }
                    // }
                    
                })
            }).then(()=>{
                let onlyPrescriptions = this.tasks.filter(task=> task.uuid);
                let uniqueTasks = [...new Map(onlyPrescriptions.map((item) => [item["prescription"], item])).values()];
                console.log('THESE ARE THE UNIQUE TASKS', uniqueTasks, onlyPrescriptions);
                this.tasks = uniqueTasks;
                this.facilities = [...new Set(this.facilities)];
                let uniqueFac = [];
                // this.facilities = this.facilities.filter(facility => uniqueFac.push({name: facility.name}));
                // console.log('THESE ARE THE FILTERED FACILITIES', uniqueFac);
                this.facilities = this.facilities.filter(this.onlyUnique());
                this.loadingTasks = false;
                this.filteredTasks = this.tasks;
                // setTimeout(this.updateResults(), 300);
            }).catch(err=>{
                this.loadingTasks = false;
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        },
        updateResults(){
            this.$store.dispatch('getRefreshToken');
            this.filteredTasks = [];
            if(this.searchFacilities.length){
                this.tasks.forEach(task=>{
                    if(this.searchFacilities.includes(task.facility)){
                        this.filteredTasks.push(task);
                    }
                })
            }
        },
        
    },
    mounted() {
        this.loadNotifications();
    },
    computed: {
        residentList() {
            let patientList = [...this.patients];
            patientList.forEach(patient => {
                patient.fullName = patient.personal_information.first_name + " " + patient.personal_information.last_name
            })
            return patientList;
        }
    }
}
</script>

<style lang="scss">
.confirm-check {
    cursor: pointer;
    font-weight: bold;
    border-radius: 50px;
    background-color: #f3f4f4;
    padding: 10px !important;

}

.confirm-check:hover {
    background-color: #00AD50 !important; //$correctborder !important;
    color: white !important;
}

.task-status-indicator {
    padding: 5px;
    font-weight: bold;
    text-align: center;
    margin-right: 15px;
    border-radius: 5px;
    color: white;
    width: 80px;
}
.p-selectbutton{
    .p-button.p-component{
        padding: 8px;
    }
}
.p-selectbutton.read-select{
    width: 200px;
    div{
        width: 100px;
    }
}

.p-multiselect .p-multiselect-label.p-placeholder{
    // color: #6c757d;
    font-size: 1em;
    text-align: left;
    padding: 5px;
}
</style>