<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 50%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">{{ buttonName == 'New Deliveries' ? 'New Delivery' : buttonName }}</h3>
                        <button @click="closeModal()" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 2fr 4fr; grid-gap: 0px">


                                <div class="left-form-section-panel">
                                    <div>
                                        <!-- NEW LOCATION -->
                                        <p class="blue-button" style="margin-bottom: 10px; font-weight: bold"
                                            @click="openNewSupplier()">
                                            {{buttonName === 'New Deliveries' ? 'Add Supplier' : buttonName==='New Returns'?'Create New Return':  buttonName === 'New Orders' ? 'Add New Client' : 'Add Warehouse'}}
                                        </p>

                                        <!-- SELECT DROPDOWN SUPPLIER -->
                                        <!-- SELECT DROPDOWN SUPPLIER -->
                                        <!-- SELECT DROPDOWN SUPPLIER -->
                                        <p style="text-align: left">{{ buttonName === 'New Deliveries' ? 'Supplier' : buttonName === 'New Returns' ? 'Client' : buttonName === 'New Orders' ? 'Client' : 'Warehouse' }} <span style="font-size: 10px; color:red">*</span></p>
                                        <div style="display: flex; align-items: center; margin-bottom: 5px;">
                                            <Dropdown v-model="searchedSupplier" :placeholder="buttonName === 'New Deliveries' ? 'Enter supplier name' : buttonName === 'New Returns' ? 'Search Client...' : buttonName === 'New Orders' ? 'Search Client...' : 'Search Warehouse...' " ref="supplier" @change="locationSelect()" @keyup="searchSupplier(), showLocations()" 
                                            :loading="loadingSupplier" :options="refinedLocations" optionLabel="name" :editable="true"
                                            style="width: 20em; display: flex"/>
                                        </div>

                                        <!-- SELECT LOCATION -->
                                        <div style="display: flex; align-items: center; margin-bottom: 5px;" v-if="$store.state.company.type == 'warehouse'">
                                            <Dropdown v-model="searchedLocation" placeholder="Location (defaults to current location)" ref="location" @change="selectGetLocation()" @keyup="searchLocation()" 
                                            :loading="loadingLocation" :options="refinedGetLocations" optionLabel="name" :editable="true"
                                            style="width: 20em; display: flex"/>
                                        </div>

                                        <!-- DATE RECEIVED -->
                                        <p style="text-align: left">Date <span style="font-size: 10px; color:red">*</span></p>
                                        <Calendar v-model="form.submit.delivery_date" placeholder="Please select date"
                                            dateFormat="dd/mm/yy" class="text-search-container search-container"
                                            style="display: flex; margin-bottom: 5px;" />
                                        <!-- <input v-model="form.submit.delivery_date" placeholder="Date Received e.g. 30/10/2021" style="position: relative; width: 100%; margin-bottom: 5px; background-color:#E5F1FE" /> -->
                                        
                                        <!-- INVOICE NUMBER -->
                                        <p style="text-align: left">{{ buttonName === 'New Deliveries' ? 'Invoice Number' : 'Order Number' }} <span style="font-size: 10px; color:red">*</span></p>
                                        <input v-model="form.submit.invoice_number" :placeholder="buttonName === 'New Deliveries' ? 'Please enter invoice number' : 'Please enter order number'"
                                            style="position: relative; width: 100%; margin-bottom: 5px; background-color:#E5F1FE" />
                                        
                                        <p style="text-align: left">Notes <span style="font-size: 10px; color:red">*</span></p>
                                        <textarea name="notes" id="note-modal" rows="16"
                                            placeholder="Please enter new delivery notes"
                                            style="width: 100%; border-top-right-radius: 0px; border-top-left-radius: 0px;"
                                            v-model="form.submit.notes"></textarea>
                                    </div>
                                    <div>
                                        <!-- <DualVerify @user="getUsername" @pass="getPassword" :password="form.submit.password"  :username="form.submit.username"
                            @user2="getUsername2" @pass2="getPassword2" :password2="form.submit.password2"  :username2="form.submit.username2" class="single-width-verify" style='justify-content: flex-start'/> -->
                                    </div>
                                </div>
                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">
                                        <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">

                                </div> -->
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p v-if="patient.regulatory_information.length"><b >Medicare Number:</b> {{ patient.regulatory_information[0].data }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>

                                    <div v-if="patient">
                                        <div style="display: flex; " :style="!selectedPrescriber ? {alignItems: 'flex-end'}:{alignItems: 'center'}">
                                            <div v-if="!selectedPrescriber"
                                                class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                                                <label for="first_name"
                                                    style="text-align: left; padding-left: 10px">Search for
                                                    Prescriber</label>
                                                <input v-model="gp" v-on:keyup="gpSearch(gp)"
                                                    placeholder="Enter Prescriber No. or Name"
                                                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                                <div class="drug-search-result-container" v-if="gp"
                                                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                                                    <p class="drug-search-result"
                                                        style="text-align: left; padding-left: 20px; cursor:pointer"
                                                        v-for="(item, index) in prescriberResults" :key="index"
                                                        @click="selectPrescriber(item)">{{ item.regulatory_numbers.length
                                                                ? item.regulatory_numbers[0].data : 'Reference only'
                                                        }} - {{ item.first_name }}
                                                        {{ item.last_name }}</p>
                                                </div>
                                                
                                            </div>
                                            <div v-if="!selectedPrescriber" style="margin-bottom: 2px;">
                                                <p class="blue-button" style="margin: 0; font-size: 1em;" @click="displayReferencePrescriberModal = true">Create prescriber</p>
                                            </div>
                                            <div v-if="selectedPrescriber"
                                                style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 15px;">
                                                <p style="font-size: 0.9em; text-align: left;"><b>Prescriber:</b>
                                                    {{ selectedPrescriber.first_name }} {{ selectedPrescriber.last_name }}
                                                </p>
                                                <p style="font-size: 0.9em" v-if="selectedPrescriber.prescriber_no"><b>Prescriber Number:
                                                    </b>{{ selectedPrescriber.prescriber_no }}</p>
                                            </div>
                                            <p style="text-align: left;  cursor:pointer; width: fit-content;
                                        border-bottom: 1px solid grey; margin-top: 0; height: fit-content"
                                                v-if="selectedPrescriber.uuid" @click="changePrescriber()">Change
                                                Prescriber</p>
                                        </div>
                                    </div>
                                    <div v-if="!imprest_pom && $store.state.company.type != 'warehouse'">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div
                                                style="width: 100%; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Resident Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="right-form-section-drug-entry"
                                        v-if="imprest_pom == 'imprest' || (patient)">
                                        <div style="width: 40%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 40%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkAndAddStock(stock) } }">Add</p>
                                    </div>


                                    <div class="drug-variances-container" style="display: flex; height: fit-content"
                                        v-show='refinedDrugs.length'>
                                        <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                        <p v-for="(item, index) in refinedDrugs" :key="index" @click="aliasSelect(item)"
                                            style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                            {{ item.name }}
                                        </p>
                                    </div> -->


                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                   
                                    <div class="right-form-section-drug-entry" v-if="imprest_pom == 'imprest' || (imprest_pom == 'pom' && selectedPrescriber?.uuid)">
                                        <!-- <div style="width: 50%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div> -->
                                        <div style="display: flex; align-items: center;">
                                            <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock)" @keyup="searchDrugs(), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                            style="width: 25em; display: flex" placeholder="Type medication name here"/> -->

                                            <!-- <Dropdown
                                                v-model="stock"
                                                ref="med"
                                                @change="drugSelect(stock)" @keyup="debounceSearchDrugs(stock), show()"
                                                :loading="loadingDrugs"
                                                :options="drugList"
                                                optionLabel="full_name"
                                                :editable="true"
                                                style="width: 25em; display: flex" placeholder="Select Medication"
                                            /> -->
                                            <Dropdown
                                                id="prescription-med-search"
                                                v-model="stock"
                                                ref="med"
                                                @input="algoliaDrugSearch(stock)"
                                                @change="drugSelect(stock)"
                                                :loading="loadingDrugs"
                                                :options="drugList"
                                                optionLabel="name"
                                                :editable="true"
                                                style="width: 25em; display: flex" placeholder="Search Medication"
                                            />
                                        </div>

                                        <input v-model="qty" min="0" type="number" placeholder="Enter qty"
                                            style="position: relative; width: 20%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { aliasSelect(selectedDrug.full_item) } }">Add</p>
                                        <!-- <p class="submit-button grey-button" style="background-color: black !important"
                                        @click="() => { if (qty) { imprest_pom = ''; stock=''; qty=''; refinedDrugs = []; patient = '' } }">Cancel</p> -->
                                    </div>
                                    <div v-if="addingMed" style="display: flex; align-items: center; justify-content: center;">
                                        <span>Adding medication...</span>
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                    </div>
                                    <div class="left-form-section-drug-container"
                                        v-if="form.submit.drugs && form.submit.drugs.length > 0">
                                        <table style="border-collapse: collapse" v-if="!locationLoading">
                                            <tr>
                                                <th style="width: 60%">Drug Name, Strength, Form</th>
                                                <th style="width: 15%">Stock Qty</th>
                                                <th style="width: 15%">Qty Added</th>
                                                <th></th>
                                            </tr>
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td>
                                                    <p>{{ item.stock }}</p>
                                                </td>
                                                <td>
                                                    <p>{{ item.qty_left ? item.qty_left : 0 }}</p>
                                                </td>
                                                <td>
                                                    <p>{{ item.qty }}</p>
                                                </td>
                                                <td>
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times"
                                                                style='padding: 0; padding-top: 3.5px;'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                        <div v-if="locationLoading" style="display: flex; align-items: center; justify-content: center;">
                                          <span>Updating medication...</span>
                                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                       </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <p style="color: red;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p :class="buttonchangeUi()" v-if="deliveryMode === 'pharmacy-delivery'" style="display: flex;align-items: center; margin-right: 10px"
                            @click="submitTransfer('pending')">
                            Pending Delivery
                        </p>
                        <p :class="(this.$store.state.company.type == 'warehouse' && !this.checkPermission()) ? 'regular-button grey-button' : buttonchangeUi()" v-if="deliveryMode !== 'pharmacy-delivery'" style="display: flex;align-items: center"
                            @click="submitTransfer('complete')">
                            {{
                                buttonName === 'New Deliveries' ? 'Receive Delivery' : 
                                buttonName === 'New Returns' ? 'Confirm Return' : 
                                buttonName === 'New Orders' ? 'Confirm Order' : transferType
                            }}
                        </p>
                        <!-- <p class="regular-button complete-button" @click="submitTransferAndCreateNew()">Receive Delivery
                            + New</p> -->
                            <p :class="buttonchangeUi()" style="display: flex;align-items: center;margin-left: 10px;" v-if="$store.state.company.type == 'warehouse'" @click="SaveAsPending()"><i class="pi pi-spin pi-spinner" v-if="loadingSubmit"></i>Save as Pending {{buttonName === 'New Deliveries' ? 'Delivery' : 'Order'}}</p>
                    </slot>
                </div>
            </div>
        </div>
        <NewCompanyModal v-if="addNewSupplier" @closeLocationModal="closeLocationModal"
            @addNewCompanyToRefined="addNewCompanyToRefined" companyType='Supplier' />
        <SignatureModal @close="close" v-if="displaySignatureModal" @isSuccessful="isSuccessful" :endpoint="$store.state.company.type == 'warehouse' ? '/warehouse/create-single-invoice-order' : '/new-delivery'" :endpoint2="getEndpoint()" :payload="realSubmit" :payload2="realSubmit2" :manual_loop="imprest_pom == 'imprest'  && $store.state.currentLocation.state == 'WA'" :saveContext="saveContext" :dual="$store.state.company.type == 'warehouse' ? !$store.state.user.role.permissions.includes('signature_primary') ? $store.state.user.role.permissions.includes('signature_witness') ? true : false : false : true" :forceSingleSig="this.forceSingleSig" :isWarehouse="$store.state.company.type == 'warehouse' ? true : false"/>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <ReferencePrescriberModal @close="close" v-if="displayReferencePrescriberModal" @prescriber="getPrescriber" />
        <AddNewSupplierModal :buttonName="buttonName" v-if="openaddNewSupplier" @closeLocationModal="closNewModal"/>
        <SuccessModal v-if="displayErrorModal" :first_spec_line="errorMessage" :statusMessage="errorTitle" @close="closeError" />
    </div>
</template>

<script>
import SuccessModal from './SuccessModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import NewCompanyModal from '@/components/modals/NewCompanyModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import ReferencePrescriberModal from '@/components/modals/ReferencePrescriberModal.vue';
import AddNewSupplierModal from '@/components/modals/AddNewSupplierModal.vue';
import axios from 'axios';
import { debounce } from 'debounce';
import { sortMedicationList } from '../../services/SortMedicationList';

export default {
    props: ['transferType','buttonName', 'deliveryMode'],
    components: {
        SuccessModal,
        SignatureModal,
        NewCompanyModal,
        PatientSearchModal,
        ReferencePrescriberModal,
        AddNewSupplierModal
    },
    data() {
        return {
            displayReferencePrescriberModal: false,
            addingMed: false,
            imprest_pom: '',
            saveContext: {},
            openaddNewSupplier:false,
            pom: '',
            patient: '',
            selectedDrug:{},
            drugList:[],
            gp: '',
            prescriberResults: [],
            addNew: false,
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            errorTitle: '',
            displayErrorModal: false,
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            qty: null,
            loadingSupplier: false,
            loadingLocation:false,
            locationLoading:false,
            searchedSupplier:'',
            // searchedLocation: {name: this.$store.state.currentLocation?.name, uuid : this.$store.state.currentLocation?.uuid},
            searchedLocation: this.$store.state.currentLocation?.name,
            refinedLocations: [],
            refinedGetLocations:[],
            supplier: '',
            loadingDrugs:false,
            addNewSupplier: false,
            noLocationFound: false,
            refinedDrugs: [],
            realSubmit: {},
            realSubmit2:{},
            form: {
                submit: {
                    type: this.buttonName === 'New Deliveries' ? "invoice" : "order",
                    invoice_number:'',
                    delivery_date: '',
                    supplier_id: '',
                    location_uuid: '',
                    notes: '',
                    reference_number: '',
                    drugs: [],
                    patient_uuid: '',
                }
            },
            timeout:null,
            loadingSubmit:false,
            date:'',
            forceSingleSig: false,
        }
    },
    methods: {
        algoliaDrugSearch(searchedDrugName) {
            var searchName = '';
            this.drugList = [];
            // Check if the search term is the full object or just a string
            if (typeof searchedDrugName === 'object') {
                searchName = searchedDrugName.name;
            } else {
                searchName = searchedDrugName;
            }

            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }

            // Check if there is brand/generic searching
            // If searching for all meds
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`,
                { params: `typoTolerance=false&query=${searchName}${this.$store.state.company.type == 'warehouse' ? "&facetFilters=deprecated:false" : ""}` },
                { headers })
            .then(res => {
                console.log('this is the algolia res', res.data.hits);
                this.drugList = sortMedicationList(res.data.hits);
                this.drugList.forEach(drug => {
                    drug.generic_name = drug.generic_name.charAt(0).toUpperCase() + drug.generic_name.slice(1);
                    drug.name = drug.name.charAt(0).toUpperCase() + drug.name.slice(1);
                });
                // state.commit('setDrugNames', res.data.hits);
            }).then(() => {
                const medListDropdown = document.querySelector('#prescription-med-search_list');
                medListDropdown.querySelectorAll('.p-dropdown-item.p-focus').forEach((med, index) => {
                    med.classList.remove('p-focus');
                });
            }).then(() => {
                const medListDropdown = document.querySelector('#prescription-med-search_list');
                const firstMed = medListDropdown.querySelector('.p-dropdown-item');
                if(firstMed) {
                    firstMed.classList.add('p-focus');
                }
            })
            
        },
        showLocations(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingSupplier && this.refinedLocations) {
                    clearInterval(interval);
                    this.$refs.supplier.show();
                }
            }, 100)
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        closNewModal(){
            this.addNewSupplier = false;
            this.openaddNewSupplier = false
        },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;
                axios.post('/get-drug-aliases', { drug: this.stock, generics: parseInt(this.drugSearchFilter) }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    res.data.drug_aliases.forEach(item=>{
                        item.full_name = `(${item.generic_name}) ${item.name}`;
                    })
                    
                    this.drugList = res.data.drug_aliases;

                    this.drugSelect(this.drugList[0]);
                    this.loadingDrugs = false;
                    // document.getElementsByClassName('p-dropdown-panel')[0].style.display = 'block';
                }).catch(err => {
                    console.log('this is the err', err);
                    this.loadingDrugs = false;
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
        },
        searchLocation: debounce(function () {
            this.loadingLocation = true;
                axios.post('/get-locations', { drug_register: true }, this.$store.state.header).then(res => {
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    let refined = [];
                    res.data.locations.forEach(location => {
                        if (location.uuid != JSON.parse(localStorage.getItem('currentLocation')).uuid) {
                            refined.push(location);
                        }
                    })
                    this.refinedGetLocations = refined;
                    // if (this.refinedGetLocations.length) {
                    //     this.$refs.location.show();
                    // }
                    if (this.refinedGetLocations.length == 1) {
                        this.form.submit.location_uuid = this.$store.state.currentLocation.uuid;
                    }
                    this.searchedLocation = this.$store.state.currentLocation?.name
                    this.form.submit.location_uuid = this.$store.state.currentLocation?.uuid
                    this.selectGetLocation(this.$store.state.currentLocation?.uuid)
                    this.loadingLocation = false;
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                });
        }, [500]),
        searchSupplier(){
            if (this.$store.state.company.type == 'warehouse') {                
            if (this.transferType == 'Deliveries') {
            if (this.searchedSupplier) {
                this.loadingSupplier = true;
                const payload={
                        location_type: this.buttonName === "New Deliveries" ?  ["supplier"] : (this.buttonName === "New Orders" || this.buttonName === "New Returns") ? ["client"] : ["warehouse"],
                        filters: {
                            party:this.searchedSupplier,
                        }
                    }
                axios.post('/warehouse/retrieve-locations',payload, this.$store.state.header).then(res => {
                    
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedLocations = res.data.data;
                    if(this.refinedLocations.length){
                    this.$refs.supplier.show();
                    }
                    this.loadingSupplier = false;
                    
                });
                if (this.refinedLocations.length == 1) {
                    this.form.submit.supplier_id = this.refinedLocations[0].uuid;
                    this.supplier = this.refinedLocations[0].name;
                }
            }
        }else{
                if (this.searchedSupplier) {
                this.loadingSupplier = true;
                const payload = {
                sort_direction: "desc",
                col: "created_at",
                page: 1,
                filters: {
                    batch_name: this.searchedSupplier,
                    created_date: '',
                    drug:'',
                    status: this.status ? [this.status] : [],
                    show_active:true
                        }
                };
                axios.post('/warehouse/retrieve-batches',payload, this.$store.state.header ).then(res => {
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedLocations = res.data.data;
                    if(this.refinedLocations.length){
                        this.$refs.batch.show();
                    }
                    this.loadingSupplier = false;
                    
                });
            }
            }
        }else{
            if (this.searchedSupplier) {
                this.loadingSupplier = true;
                axios.post('/get-companies', { name: this.searchedSupplier, type: 'Supplier' }, this.$store.state.header).then(res => {
                    
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedLocations = res.data;
                    if(this.refinedLocations.length){
                        this.$refs.supplier.show();
                    }
                    this.loadingSupplier = false;
                    
                });
                if (this.refinedLocations.length == 1) {
                    this.form.submit.supplier = this.refinedLocations[0].uuid;
                    this.supplier = this.refinedLocations[0].name;
                }
            }
        }

        },
        drugSelect(item) {
            this.loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.selectedDrug.strength = `${item.strength}`;
            this.selectedDrug.measurement_unit = `${item.measurement_unit}`;
            this.selectedDrug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = item.tags;
            this.loadingDrugs = false;
            console.log('this is the selected drug item', this.selectedDrug);
            // this.drugList = [];
            // this.searchedDrugName = item.name;
            // this.clearDrug = true;
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.displayReferencePrescriberModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                   
                    this.imprest_pom = '';
                }
            }
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`, 
            // params: `query=${prescriber}&hitsPerPage=5`,
            { 
              requests:[
                { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
                { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}&facetFilters=company_uuid:${this.$store.state.company.uuid}` },
              ]  
            },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);
                    res.data.results.forEach(indexResult=>{
                        console.log('these are the results for this particular index', indexResult);
                        if(indexResult.hits.length){

                            // results.push(indexResult.hits);
                            indexResult.hits.forEach(hit=>{
                                this.prescriberResults.push(hit);
                            })
                            console.log('this is the local results varaible', this.prescriberResults);
                        }
                        
                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        selectPrescriber(prescriber) {
            console.log('this is the selected result', this.nonGP);
            this.prescriberResults = [];
            this.selectedPrescriber = {
                prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
                uuid: prescriber.uuid,
                first_name: prescriber.first_name,
                last_name: prescriber.last_name,
                phone: prescriber.phone,
                after_hours: prescriber.after_hours,
                address: prescriber.address,
                email: prescriber.email,
                fax: prescriber.fax,
                primary_gp: false
            }
        },
        changePrescriber() {
            this.selectedPrescriber = '';
            this.gp = '';
        },
        getPrescriber(value){
            console.log('getprescriber function is called', value);
            if(value){
                this.selectedPrescriber = value;
                this.displayReferencePrescriberModal = false;
            }
            
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
            }
        },
        isSuccessful(value) {
            console.log('IS SUCCESSFUL IS BEING CALLEDs', value,this.$route.name,this.addNew);
            if (!this.addNew) {
                console.log('IS SUCCESSFUL IS BEING CALLED -- IF', value);
                if(this.$route.name == 'Deliveries'){
                    this.$store.dispatch('getDeliveries', {
                        sort_direction: 'desc',
                        col: 'completed_date',
                        page: 1,
                        filters: {
                            tags: [],
                            status: ['completed', 'reversed'],
                            supplier: '',
                            drug: '',
                            date: {
                                start: '',
                                end: ''
                            }
                        },
                    });
                }else if ( this.$route.name ==  'Invoices') {
                    this.$store.dispatch('getPurchaseInvoices', {
                        sort_direction: 'desc',
                        col: 'created_at',
                        facility_id: this.$store.state.currentLocation.uuid,
                        page: 1,
                        filters: {
                            status: [],
                            party: '',
                            drug: '',
                            date:{
                                start:'',
                                end:''
                            },
                            invoice_number: '',
                            show_active: true,
                        },

                    })
                }else if(this.$route.name ==  'Orders'){
                    this.$store.dispatch('getOrders', {
                        sort_direction: 'desc',
                        facility_id: this.$store.state.currentLocation.uuid,
                        col: 'created_at',
                        page: 1,
                        filters: {
                            status: [],
                            party: '',
                            drug: '',
                            date:{
                                start:'',
                                end:''
                            },
                            invoice_number: '',
                            show_active: true,
                        },

                    });
                } else if (this.$route.name == 'Drug Register') {
                    console.log('loading the drug register stuff');
                    this.$store.dispatch('getDrugRegister', {
                        sort_direction: 'desc',
                        col: 'created_at',
                        page: 1,
                        filters: {
                            transaction_id: '',
                            to_from: '',
                            entered_by: '',
                            date: {
                                start: '',
                                end: ''
                            },
                            drug: '',
                            imprest_only: false
                        }
                    });
                }
                
                this.closeModal();
            } else if (this.addNew) {
                console.log('IS SUCCESSFUL IS BEING CALLED -- ELSE IF', value);
                this.form.submit.drugs = [];
                this.imprest_pom = '';
                this.patient = '';
                this.selectedPrescriber = '';
                this.stock = '';
            }
        },
        closeModal() {
            console.log('hitting this closemodal functionsss');
            this.$emit('close', true);
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewSupplier = false;
            }
        },
        getEndpoint(){
            if (this.$store.state.company.type == 'warehouse') {
                if (this.buttonName === 'New Deliveries') {
                 return "/warehouse/sign-off-invoices"   
                }else if (this.buttonName === 'New Orders') {
                    return "/warehouse/sign-off-orders"
                }else{
                    return "/warehouse/sign-off-invoices"
                }
            }else{
                return false;  
            }
        },
        addNewCompanyToRefined(value) {
            this.form.submit.supplier_id = value.uuid;
            this.refinedLocations = [value];
            console.log('RECEIVING FROM ADD LOCATION', value);
            this.supplier = value.name;
        },
        async selectGetLocation(defaultUUID) {
            try {
                this.locationLoading = true
                this.form.submit.location_uuid = defaultUUID ? defaultUUID : this.searchedLocation.uuid;
                if (this.form.submit.drugs.length > 0) {
                    const checkData = this.form.submit.drugs.map(item => {
                        return axios.post('/get-stock',
                            { alias_id: item.alias_id, location_id: this.form.submit.location_uuid },
                            this.$store.state.header
                        ).then(res => {
                            return { data: res.data };
                        }).catch(error => {
                            return { data: { alias: { alias_id: item.alias_id }, qty_left: 0 } };
                        });
                    });
    
                    const results = await Promise.allSettled(checkData);
    
                    results.forEach(result => {
                        if (result.status === 'fulfilled') {
                            this.locationLoading = false
                            const drug = this.form.submit.drugs.find(d => d.alias_id === result.value.data.alias.alias_id);
                            if (drug) {
                                drug.drug = result.value.data.uuid
                                drug.qty_left = result.value.data.qty_left;
                            }
                        } else {
                            this.locationLoading = false
                            console.error(`Error fetching stock for drug with UUID`, result.reason);
                        }
                    });
                }else{
                    this.locationLoading = false
                }
            } catch (error) {
                this.locationLoading = false
                this.errorMessage = 'An unexpected error occurred. Please try again later.';
            }
        },
        locationSelect() {
            this.supplier = this.searchedSupplier.name;
            this.form.submit.supplier_id = this.searchedSupplier.uuid;
            // this.$refs.supplier.hide();
        },
        // aliasSelect(item) {
        //     console.log(item);
        //     // this.form.submit.drugs.unshift({tags: item.alias.tags,stock:item.name, drug: item.alias_id, form: item.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: 0});
        //     // this.qty = null;
        //     // this.refinedDrugs = []; 
        //     // this.$store.dispatch('getRefreshToken');
        //     if (this.form.submit.drugs.length) {
        //         console.log('a drug is in the list now');
        //         this.form.submit.drugs.forEach(drugItem => {
        //             if (item.alias_id == drugItem.drug) {
        //                 drugItem.qty = this.qty;
        //                 this.$store.dispatch('getRefreshToken');
        //                 this.qty = null;
        //                 this.refinedDrugs = [];
        //                 this.noStock = false;
        //             } else {
        //                 this.addingAliasToList(item);

        //             }
        //         })
        //     } else {
        //         console.log('no drug is in the list yet');
        //         this.addingAliasToList(item);
        //     }


        // },
        aliasSelect(item) {
            
            console.log('ALIAS SELECT ITEM',item,"patient>>",this.patient);
            this.drugList = [];
            let drug =  item;
            this.stock = '';
            this.selectedDrug = {};

            // Checking if the item selected exists in the list already, if so, update the quantity number

            if (this.form.submit.drugs.length) {
                let matched = false;
                this.form.submit.drugs.forEach(drugItem => {
                    if ((drug.alias_id == drugItem.drug) || (drug.uuid == drugItem.drug) ) {
                        if(this.patient && drugItem.patient &&  this.patient.uuid == drugItem.patient.uuid){
                            console.log('HITTING MATCH FOR PATIENT AND DRUG');
                            drugItem.qty = this.qty;
                            this.$store.dispatch('getRefreshToken');
                            this.qty = null;
                            this.refinedDrugs = [];
                            this.noStock = false;
                            this.selectedDrug = {};
                            this.stock = '';
                            matched = true;
                        }  else if(!drugItem.patient && !this.patient){
                            console.log('HITTING MATCH FOR IMPREST');
                            drugItem.qty = this.qty;
                            this.$store.dispatch('getRefreshToken');
                            this.qty = null;
                            this.refinedDrugs = [];
                            this.noStock = false;
                            this.selectedDrug = {};
                            this.stock = '';
                            matched = true;
                        } 
                    } 
                })
                if (!matched) {
                    console.log('NO MATCH FOR PATIENT OR DRUG');
                    this.addingAliasToList(drug);
                }
            } else {
                console.log('no drug is in the list yet');
                this.addingAliasToList(drug);
            }
        },
        addingAliasToList(item) {
             this.addingMed = true;
            console.log('addingAliasToList function is running', item, this.$store.state.currentLocation.uuid);
            if ((this.imprest_pom == 'imprest' || this.imprest_pom == '')) {
                console.log('addingAliasToList -- IMPREST')
                axios.post('/get-stock', { alias_id: item.alias_id ?? item.uuid, location_id: this.form.submit.location_uuid ? this.form.submit.location_uuid : this.$store.state.currentLocation.uuid}, this.$store.state.header).then(res => {
                    if (this.qty) {
                        console.log('this is the quantity if condition', res, this.qty, res.data.qty_left, this.qty > res.data.qty_left);
                        // note that we're using the stock id of the drug instead of alias id 
                        // because we need to validate stock quantity before saving invoice in warehouse
                        this.form.submit.drugs.unshift({ 
                            tags: item.tags, 
                            stock: res.data.alias.name, 
                            form: item.form,
                            alias_id:res.data.alias.alias_id, 
                            drug: res.data.uuid, 
                            qty_left: res.data.qty_left, 
                            qty: this.qty,
                            balance_confirmed: false, 
                            actual_balance: Number(res.data.qty_left) + Number(this.qty)
                        });
                    }

                    // this.form.submit.drugs.unshift({tags: item.alias.tags,stock:res.data.drug_alias, 'drug': item.alias_id, form: res.data.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: res.data.qty_left});
                    this.$store.dispatch('getRefreshToken');
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.noStock = false;
                    this.selectedDrug = {};
                    this.addingMed = false;
                }).catch(err => {
                    console.log('err is', err, err.response.data);
                    // if (err.response.data == 'No stock has been recorded on this location for that drug alias.' || err.response.data == 'For some reason this location does not have a stock register'
                    // || (err.response.data.errors && err.response.data.errors.locationUUID && err.response.data.errors.locationUUID[0] == "There is no stock in the register.")) {
                    //     console.log('hitting the if condition');
                    //     if (this.qty) {
                    //         this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: 0 });
                    //     }
                    // }
                    if (this.qty) {
                        this.form.submit.drugs.unshift({ 
                            tags: item.tags, 
                            stock: item.name, 
                            alias_id: item.alias_id || item.uuid, 
                            drug: item.alias_id || item.uuid, 
                            form: item.form, 
                            qty: this.qty, 
                            qty_left: 0,
                            balance_confirmed: false, 
                            actual_balance: Number(this.qty) // qty_left (0) + this.qty
                        });
                    }
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.selectedDrug = {};
                    this.addingMed = false;
                })
            } else {
                console.log('addingAliasToList -- ROM')

                axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                    // axios.post('/get-stock', {alias_id:item.alias_id, location_id: JSON.parse(localStorage.getItem('currentLocation')).uuid, patient_id: this.patient.uuid }, this.$store.state.header).then(res=>{
                    console.log('this is the quantity receive condition', res, item, this.qty, res.data.qty_left, this.qty > res.data.qty_left);
                    if (res.data.length) {
                        for (var i = 0; i < res.data.length; i++) {
                            if (this.qty) {
                                if (res.data[i].alias.alias_id == item.alias_id) {

                                    if (this.form.submit.drugs[this.form.submit.drugs.indexOf(item)] > -1) {
                                        this.form.submit.drugs[this.form.submit.drugs.indexOf(item)].qty = this.qty;
                                        break;
                                    } else {
                                        this.form.submit.drugs.unshift({ 
                                            tags: res.data[i].alias.tags, 
                                            stock: res.data[i].alias.name, 
                                            form: res.data[i].alias.measurement_unit, 
                                            drug: res.data[i].alias.alias_id, 
                                            qty_left: res.data[i].quantity, 
                                            qty: this.qty,
                                            balance_confirmed: false, 
                                            actual_balance: Number(res.data[i].quantity) + Number(this.qty) 
                                        });
                                        break;
                                    }
                                } else {
                                    if (this.form.submit.drugs[this.form.submit.drugs.indexOf(item)] > -1) {
                                        this.form.submit.drugs[this.form.submit.drugs.indexOf(item)].qty = this.qty;
                                        break;
                                    } else {
                                        this.form.submit.drugs.unshift({ 
                                            tags: item.tags, 
                                            stock: item.name, 
                                            drug: item.alias_id, 
                                            form: item.form, 
                                            qty: this.qty, 
                                            qty_left: 0,
                                            balance_confirmed: false, 
                                            actual_balance: Number(this.qty) 
                                        });
                                        break;
                                    }
                                }
                            }

                        }
                    } else {
                        console.log('no drugs in array, hitting else condition here');
                        if (this.qty) {
                            this.form.submit.drugs.unshift({ 
                                tags: item.tags, 
                                stock: item.name, 
                                drug: item.alias_id, 
                                form: item.form, 
                                qty: this.qty, 
                                qty_left: 0,
                                balance_confirmed: false, 
                                actual_balance: Number(this.qty) 
                            });
                        }

                    }
                    // this.form.submit.drugs.unshift({tags: item.tags,stock:res.data.drug_alias, 'drug': item.alias_id, form: res.data.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: res.data.qty_left});
                    this.$store.dispatch('getRefreshToken');
                    this.noStock = false;
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.selectedDrug = {};
                    this.addingMed = false;

                }).catch(err => {
                    console.log('err is', err);
                    if (err.response?.data == 'No stock has been recorded on this location for that drug alias.') {
                        console.log('hitting the if condition');
                        if (this.qty) {
                            this.form.submit.drugs.unshift({ 
                                tags: item.tags, 
                                stock: item.name, 
                                drug: item.alias_id, 
                                form: item.form, 
                                qty: this.qty, 
                                qty_left: 0,
                                qty_balance_confirmed: false, 
                                qty_balance: Number(this.qty)
                            });
                        }

                    }
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.selectedDrug = {};
                    this.addingMed = false;
                })

                // }
                //  else{
                //     this.form.submit.drugs.unshift({tags: item.alias.tags,stock: item.alias.name, form:item.alias.form, qty_left:item.quantity, drug:item.alias.alias_id, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity)});
                // }
                // console.log('this is the quantity else condition', this.qty, Number(res.data.qty_left), this.qty > Number(res.data.qty_left))

            }
        },
        checkAndAddStock(drugName) {
            if (drugName) {
                axios.post('/get-drug-aliases', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the drug alias names', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedDrugs = res.data.drug_aliases;
                })

            }
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        openNewSupplier(){
           if (this.$store.state.company.type == 'warehouse') {
            this.openaddNewSupplier = true
           }else{
            this.addNewSupplier = true
           }
        },
        buttonchangeUi() {
            if (this.$store.state.company.type == 'warehouse') {
                if (this.form.submit.notes && this.form.submit && this.form.submit.supplier_id && this.form.submit.delivery_date && this.form.submit.invoice_number && this.form.submit.drugs?.length > 0 && this.form.submit.location_uuid) {
                    return 'regular-button complete-button'
                } else {
                    return 'regular-button grey-button'
                }
            }else{
                return 'regular-button complete-button'
            }
        },
        checkPermission() {
            if (!this.$store.state.user.role.permissions.includes('signature_primary') && !this.$store.state.user.role.permissions.includes('signature_witness')) {
                if (this.$store.state.user.role.permissions.includes('single_signature_primary')) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        SaveAsPending() {
            if (this.form.submit.drugs?.length > 0) {
                const existdrug = this.form.submit.drugs.map(item => ({
                    drug: item?.drug,
                    qty: item.qty
                }));
                this.form.drugs = existdrug;
            }
            if (this.form.submit.delivery_date) {
                this.form.submit.delivery_date = new Date(this.form.submit.delivery_date).toLocaleDateString('en-AU')
            }
            if (this.form && this.form.submit?.notes && this.form.submit?.supplier_id && this.form.submit?.delivery_date && this.form.submit?.invoice_number && this.form.submit?.drugs.length > 0 && this.form.submit.location_uuid) {
                this.errorMessage = ''
                this.loadingSubmit = true
                axios.post('/warehouse/create-single-invoice-order', this.form.submit, this.$store.state.header)
                    .then(async response => {
                        if (response?.data === 200 || response?.status === 200) {
                            this.loadingSubmit = false
                            this.$emit('close', true)
                            if (this.$route.name == 'Invoices') {
                                this.$store.dispatch('getPurchaseInvoices', {
                                    sort_direction: 'desc',
                                    col: 'created_at',
                                    facility_id: this.$store.state.currentLocation.uuid,
                                    page: 1,
                                    filters: {
                                        status: [],
                                        party: '',
                                        drug: '',
                                        date: {
                                            start: '',
                                            end: ''
                                        },
                                        invoice_number: '',
                                        show_active: true,
                                    },

                                })
                            } else if (this.$route.name == 'Orders') {
                                this.$store.dispatch('getOrders', {
                                    sort_direction: 'desc',
                                    facility_id: this.$store.state.currentLocation.uuid,
                                    col: 'created_at',
                                    page: 1,
                                    filters: {
                                        status: [],
                                        party: '',
                                        drug: '',
                                        date: {
                                            start: '',
                                            end: ''
                                        },
                                        invoice_number: '',
                                        show_active: true,
                                    },

                                });
                            } else if (this.$route.name == 'Drug Register') {
                                this.$store.dispatch('getDrugRegister', {
                                    sort_direction: 'desc',
                                    col: 'created_at',
                                    page: 1,
                                    filters: {
                                        transaction_id: '',
                                        to_from: '',
                                        entered_by: '',
                                        date: {
                                            start: '',
                                            end: ''
                                        },
                                        drug: '',
                                        imprest_only: false
                                    }
                                });
                            }
                        }
                    })
                    .catch(error => {
                        this.loadingSubmit = false
                        if (error.response && error.response.data && error.response.data.message) {
                            this.errorMessage = error.response.data.errors ? error.response.data.message + ' ' + Object.values(error.response.data.errors).flat()[0] : error.response.data.message;
                        } else {
                            this.errorMessage = error.response.data;
                        }

                    })
            } else {
                this.errorMessage = 'Drugs, Invoice Number, Location ID, and Date Received are required.'
            }
        },
        closeError(value) {
            if (value) {
                this.errorTitle = '';
                this.errorMessage = '';
                this.displayErrorModal = false;
            }
        },
        submitTransfer(mode) {

            if (this.form.submit.notes.trim() == '') {
                this.displayErrorModal = true;
                this.errorTitle = 'Error';
                this.errorMessage = 'Notes is required';
                return;
            }
            
            if (this.$store.state.company.type == 'warehouse') {
                if (this.form.submit.drugs?.length > 0) {
                    const existdrug = this.form.submit.drugs.map(item => ({
                            drug: item?.alias_id || item?.uuid,
                            qty: item.qty,
                            alias: item.stock,
                            tags: item.tags
                    }));
                    this.form.drugs = existdrug;
                }
                if (this.form.submit.delivery_date) {
                    this.form.submit.delivery_date = new Date(this.form.submit.delivery_date).toLocaleDateString('en-AU')
                }
                if (this.form && this.form.submit?.notes && this.form.submit?.supplier_id && this.form.submit?.delivery_date && this.form.submit?.invoice_number && this.form.submit?.drugs.length > 0 && this.form.submit.location_uuid && this.checkPermission()) {
                    this.errorMessage = ''
                    this.realSubmit = this.form.submit
                    this.displaySignatureModal = true;
                }else{
                    this.errorMessage = !this.checkPermission() ? "You do not have single_signature_primary permissions to sign this order, please contact admin." : 'Drugs, Invoice Number,  Location ID, and Date Received are required.'
                }
            } else {
                console.log('this is the form to submit', this.form);

                let newDrugSub = [];
                this.addNew = false;
                this.form.submit.drugs.forEach(item => {
                    let p = {
                        drug: this.imprest_pom == 'pom' ? item.drug : item.alias_id,
                        qty: Number(item.qty),
                        alias: item.stock,
                        tags: item.tags,
                        balance_confirmed: item.balance_confirmed,
                        actual_balance: item.actual_balance,
                    }
                    if (item.tags && item.tags.length) {
                        this.dual = true;
                    }

                    if (item.qty > 0) {
                        newDrugSub.push(p);
                    }
                });
                this.refinedLocations = [];



                if (newDrugSub.length && this.form.submit.invoice_number && this.form.submit.delivery_date) {

                    this.realSubmit = {
                        drugs: newDrugSub,
                        notes: this.form.submit.notes,
                        patient_uuid: this.patient ? this.patient.uuid : '',
                        delivery_date: new Date(this.form.submit.delivery_date).toLocaleDateString('en-AU'),
                        reference_number: this.form.submit.invoice_number,
                        supplier: this.form.submit.supplier_id,
                        mode: mode,
                    };
                    this.saveContext = {
                        notes: this.form.submit.notes,
                        delivery_date: new Date(this.form.submit.delivery_date).toLocaleDateString('en-AU'),
                        reference_number: this.form.submit.invoice_number,
                        supplier: this.searchedSupplier.name
                    }

                    if(mode === 'pending') {
                        this.forceSingleSig = true;
                    }
                    this.displaySignatureModal = true;
                    this.errorMessage = '';
                } else {
                    this.errorMessage = `Drugs, Invoice Number and Date Received are required.`
                }
            }
        },
        submitTransferAndCreateNew() {
            if (this.form.submit.drugs.length && this.form.submit.invoice_number && this.form.submit.delivery_date) {
                this.addNew = true;
                console.log('this is the form to submit', this.form);
                let newDrugSub = [];
                this.form.submit.drugs.forEach(item => {
                    let p = {
                        drug: item.drug,
                        qty: Number(item.qty),
                        alias: item.stock,
                        balance_confirmed: item.balance_confirmed,
                        actual_balance: item.actual_balance,
                    }
                    if (item.tags && item.tags.length) {
                        this.dual = true;
                    }

                    if (item.qty > 0) {
                        newDrugSub.push(p);
                    }
                });
                
                if (this.patient) {
                    this.form.submit.patient_uuid = this.patient.uuid;
                    this.realSubmit = {
                        drugs: newDrugSub,
                        notes: this.form.submit.notes,
                        patient_uuid: this.patient ? this.patient.uuid : '',
                        delivery_date: new Date(this.form.submit.delivery_date).toLocaleDateString('en-AU'),
                        invoice_number: this.form.submit.invoice_number,
                        supplier: this.form.submit.supplier_id
                    };
                    

                }


                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Drugs, Invoice Number and Date Received are required.'
            }
        }
    },
    mounted() {
        if (this.$store.state.company.type === 'warehouse') {
            this.searchLocation()
            this.imprest_pom = 'imprest';
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>