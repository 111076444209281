<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 80%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Administration</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; width: 100%; padding-left: 0;">

                                <!-- <div class="left-form-section-panel">
                            <div>
                                <p class="note-header">Notes</p>
                                <textarea style="border-top-left-radius: 0; border-top-right-radius: 0; width: 100%;" name="notes" id="note-modal" cols="23" rows="20" placeholder="Enter notes here..." v-model="form.submit.notes"></textarea>
                            </div>
                        </div> -->

                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel" style="display: flex; flex-direction: column;">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">
                                        <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">

                                </div> -->
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p><b>Medicare Number:</b> {{ patient.regulatory_information && patient.regulatory_information.length ? patient.regulatory_information[0].data : 'N/A' }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>
                                    <div v-if="patient">
                                        <div style="display: flex; margin-bottom: 10px;" :style="!selectedPrescriber ? {alignItems: 'flex-end'}:{alignItems: 'center'}">
                                            <div v-if="!selectedPrescriber"
                                                class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                                                <label for="first_name"
                                                    style="text-align: left; padding-left: 10px">Search for
                                                    Prescriber</label>
                                                <input v-model="gp" v-on:keyup="gpSearch(gp)"
                                                    placeholder="Enter Prescriber No. or Name"
                                                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                                <div class="drug-search-result-container" v-if="gp"
                                                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6; z-index:9">
                                                    <p class="drug-search-result"
                                                        style="text-align: left; padding-left: 20px; cursor:pointer"
                                                        v-for="(item, index) in prescriberResults" :key="index"
                                                        @click="selectPrescriber(item)">{{ item.regulatory_numbers.length
                                                                ? item.regulatory_numbers[0].data : 'Reference only'
                                                        }} - {{ item.first_name }}
                                                        {{ item.last_name }}</p>
                                                </div>
                                                
                                            </div>
                                            <div v-if="!selectedPrescriber" style="margin-bottom: 2px;">
                                                <p class="blue-button" style="margin: 0; font-size: 1em;" @click="displayReferencePrescriberModal = true">Create prescriber</p>
                                            </div>
                                            <div v-if="selectedPrescriber"
                                                style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 15px;">
                                                <p style="font-size: 0.9em; text-align: left;"><b>Prescriber:</b>
                                                    {{ selectedPrescriber.first_name }} {{ selectedPrescriber.last_name }}
                                                </p>
                                                <p style="font-size: 0.9em" v-if="selectedPrescriber.prescriber_no"><b>Prescriber Number:
                                                    </b>{{ selectedPrescriber.prescriber_no }}</p>
                                            </div>
                                            <p style="text-align: left;  cursor:pointer; width: fit-content;
                                        border-bottom: 1px solid grey; margin-top: 0; height: fit-content"
                                                v-if="selectedPrescriber.uuid" @click="changePrescriber()">Change
                                                Prescriber</p>
                                        </div>
                                    </div>

                                    <div v-if="!patient">
                                        <div class="right-form-section-drug-entry">
                                            <div
                                                style="width: 100%; display: grid; grid-template-columns: 1fr; grid-gap: 10px;">
                                                <p @click="() => {displayPatientSearchModal = true }"
                                                    style="width: 50%; margin: 0 auto; margin-top: 2em;"
                                                    class="pom-imprest-choice-button">
                                                    Search for Resident</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="!imprest_pom && patient">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div class="pom-imprest-select">
                                                <p @click="() => { imprest_pom = 'pom';}"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Resident Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="right-form-section-drug-entry" v-if="imprest_pom == 'imprest'">
                                        <div style="width: 100%; margin-right: 10px">
                                            <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="searchDrugs(stock), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                            style="width: 25em; display: flex" optionDisabled="disabled" placeholder="Type medication name here"/> -->
                                            <Dropdown
                                                v-model="stock"
                                                ref="med" @keyup="debounceSearchDrugs(stock), show()"
                                                :loading="loadingDrugs"
                                                :options="drugList"
                                                optionLabel="full_name"
                                                :editable="true"
                                                style="width: 100%; display: flex" placeholder="Select Medication"
                                            />
                                        </div>
                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 30%; margin-right: 10px;"
                                            @change="() => { if (qty && !form.submit.drugs.length) { aliasSelect(stock) }}" />
                                    </div>
                                    <!-- PATIENT MEDICATION SEARCH -->
                                    <div v-if="loadingDrugs && imprest_pom != 'imprest'">
                                        <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem !important;margin-top: 2em;"></i>
                                    </div>
                                    <div class="right-form-section-drug-entry"
                                        v-if="patient && !form.submit.drugs.length && !loadingDrugs && imprest_pom == 'pom'">
                                        <div style="width: 100%; margin-right: 10px">

                                            <Dropdown
                                                v-model="stock"
                                                ref="med" @input="changeStock(), show()"
                                                :loading="loadingDrugs"
                                                :options="patientMeds"
                                                optionLabel="full_name"
                                                :editable="false"
                                                style="width: 100%; display: flex" placeholder="Select Medication"
                                            />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Quantity"
                                            style="position: relative; width: 30%; margin-right: 10px;" 
                                            @change="() => { if (qty && !form.submit.drugs.length) { aliasSelect(stock) }}" />
                                    </div>


                                    <div class="right-form-section-drug-container" v-if="patient">
                                        <table style="border-collapse: collapse">
                                            <tr>
                                                <th >Medication Name</th>
                                                <th >Stock Qty</th>
                                                <th >Supplied</th>
                                                <th >Remaining Qty</th>
                                                <th v-if="$store.state.currentLocation.state == 'TAS'">Count</th>
                                                <th v-if="$store.state.currentLocation.state == 'TAS'" style="width: 80px">Diff</th>
                                                <th v-if="$store.state.currentLocation.state == 'TAS'" style="text-align: center">Confirm</th>
                                                <th></th>
                                            </tr>
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p style="font-size: 1em;">{{ item.stock }}</p>
                                                </td>
                                                <td style="text-align: center">
                                                    <p>{{ item.qty_left }}</p>
                                                </td>
                                                <td style="text-align: center">
                                                    <p>{{ item.entered_qty }}</p>
                                                </td>
                                                <td style="text-align: center">
                                                    <p>{{ item.qty_left - item.entered_qty }}</p>
                                                </td>
                                                <td v-if="$store.state.currentLocation.state == 'TAS'" style="text-align: left">
                                                    <input style="width: 8em; background-color: white;" v-model="item.qty_remaining" type="number" min="0" @input="onQtyRemainingChange(item)" />
                                                </td>
                                                <td v-if="$store.state.currentLocation.state == 'TAS'" style="text-align: left">
                                                    <span :class="(item.qty_remaining - (item.qty_left - item.entered_qty) >= 0) ? 'green-tag' : 's8-tag'">{{ item.qty_remaining - (item.qty_left - item.entered_qty) }}</span>
                                                </td>
                                                <td v-if="$store.state.currentLocation.state == 'TAS'" style="text-align: center; width: 40px">
                                                    <Checkbox :binary="true" v-model="item.qty_remain_confirmed"/>
                                                </td>
                                                <!-- <td v-if="!removedDrug">
                                            <p class="blue-button" style="font-weight: 500; padding: 5px 10px; text-align: center; margin-right: 10px;" @click="removeDrugReg(item)">Remove from Register</p>
                                        </td>
                                        <td v-else>
                                            <div style="display: flex" v-if="item.administered === true || (item.administered != false && !item.doc)">
                                                <div  @click="item.administered = true" style="cursor: pointer;height: 20px; width: 20px; border-radius: 50%; border: 1.5px solid green; margin-right: 10px;" :style="[item.administered === true ? {backgroundColor: 'green'}:{}]"></div>
                                                <div @click="item.administered = false" style="cursor: pointer;height: 20px; width: 20px; border-radius: 50%; border: 1.5px solid #E04F39"></div>
                                            </div>
                                            
                                            <div v-if="item.administered === false" style="width: 70px" class="doc-select-container">
                                                <select name="DOC" id="doc-admin" @change="selectDOC(item)" :style="item.status ? {backgroundColor: '#E04F39', color: 'white'}:{backgroundColor: '#E5E8EA'}"
                                                style="padding: 10px;width: 70px;border-radius: 20px;">
                                                    <option value="" disabled selected>DOC</option>
                                                    <option v-for="(reason, listIndex) in doseOmittedCode" :key="listIndex" :value="reason">{{reason}}</option>
                                                </select>
                                            </div>
                                        </td> -->
                                                <td>
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times" style="padding: 0"></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr class="drug-entry-transfer"
                                                v-if="
                                                $store.state.currentLocation.state != 'WA' && form.submit && form.submit.drugs && form.submit.drugs.length && !Number.isInteger(Number(form.submit.drugs[0].qty)) && (stock.quantity ? (stock.quantity > Number(form.submit.drugs[0].qty)) : (stock.qty_left > Number(form.submit.drugs[0].qty)))">
                                                <td>
                                                    <p style="font-size: 1em;">Remainder:</p>
                                                </td>
                                                <td><input type="number" min="0"
                                                        :max="form.submit.drugs[0].stock_quantity"
                                                        style="background-color: white; width: 10em"
                                                        v-model="discard_amount"
                                                        :placeholder="difference">
                                                </td>

                                                <td>
                                                    <div style="display: flex;">
                                                        <Checkbox id="binary" v-model="discard" :binary="true"
                                                            style="margin-right: 5px;" />
                                                        <label for="binary"
                                                            style="margin-right: 10px; white-space: pre">Discard Remainder</label>
                                                    </div>

                                                </td>
                                                <td></td>
                                            </tr>
                                            </table>
                                            <table style="border-collapse: collapse">
                                                <tr class="drug-entry-transfer" v-if="
                                                    $store.state.currentLocation.state == 'WA' && form.submit && form.submit.drugs && form.submit.drugs.length">
                                                    <td style="padding-left: 15px; width: 22em;">
                                                        <p style="font-size: 1em; padding:5px">Administered:</p>
                                                        <input type="number" min="0"
                                                            v-model="waNote.administered"
                                                            style="background-color: white; width: 10em; margin-right: 5px;"
                                                            placeholder="Amount">
                                                            <Dropdown
                                                                id="dose_unit-type"
                                                                placeholder="Dose Unit"
                                                                :options="doseTypes"
                                                                v-model="dose_unit"
                                                            />
                                                    </td>
                                                    <td style="padding-left: 15px;">
                                                        <p style="font-size: 1em; padding:5px">Discarded:</p>
                                                        <input type="number" min="0"
                                                            v-model="waNote.discarded"
                                                            style="background-color: white; width: 10em; margin-right: 5px;"
                                                            placeholder="Amount">
                                                            <Dropdown
                                                                id="dose_unit-type"
                                                                placeholder="Dose Unit"
                                                                :options="doseTypes"
                                                                v-model="dose_unit"
                                                            />
                                                    </td>
                                                </tr>
                                            </table>
                                    </div>
                                    <div v-if="errorMessage">
                                        <p style="color: red; font-weight: 500; font-size: 1em;">{{ errorMessage }}</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style="margin-right: 10px;"
                            v-if="patient && form.submit.drugs.length" @click="removeDrugReg(form.submit.drugs[0])">
                            Complete</p>
                        <!-- <p class="regular-button complete-button" v-if="patient && form.submit.drugs.length"
                            @click="removeDrugReg(form.submit.drugs[0]), addNew = true">Complete + New</p> -->
                    </slot>
                </div>
            </div>
        </div>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <ReferencePrescriberModal @close="close" v-if="displayReferencePrescriberModal" @prescriber="getPrescriber" />
        <SignatureModal v-if="displaySignatureModal" :drugReg="drugReg" :notes="notes" :payload="payload" :saveContext="saveContext"
            :displaySaveContext='$store.state.currentLocation.state == "WA"'
            :payload2="payload2" @isSuccessful="isSuccessful" @close="close" :endpoint="endpoint" :endpoint2="endpoint2" @drugSafeRetrievalId="drugSafeRetrievalId"
            dual="true" :warning="form.submit.drugs[0].status == 'N' ? 'Medication not available - Please notify prescriber' : form.submit.drugs[0].status == 'R' ?
        'Resident Refused - Please notify prescriber' : form.submit.drugs[0].status == 'W' ?
            'Medication withheld for clinical reasons - Please notify prescriber' : ''" />
    </div>
</template>

<script>
import SignatureModal from '@/components/modals/SignatureModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import ReferencePrescriberModal from '@/components/modals/ReferencePrescriberModal.vue';
import axios from 'axios';

export default {
    components: {
        SignatureModal,
        PatientSearchModal,
        ReferencePrescriberModal
    },
    data() {
        return {
            displayReferencePrescriberModal: false,
            waNote:{
                administered:null,
                discarded:null,
            },
            saveContext:{
                notes: ''
            },
            endpoint: '',
            endpoint2: '',
            drugReg: [],
            discard: false,
            notes: false,
            discard_amount: '',
            loadingDrugs: false,
            payload: '',
            payload2: '',
            removedDrug: false,
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            prescriberResults: [],
            addNew: false,
            patientMeds: [],
            prescriber: '',
            selectedPrescriber: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            reasonCat: '',
            reasonSubCat: '',
            errorMessage: '',
            qty: null,
            dose_unit:'',
            aliasId: '',
            refinedDrugs: [],
            drugList:[],
            doseOmittedCode: [
                'A',
                'F',
                'H',
                'L',
                'S',
                'S/A',
                'V',
                'A/T',
                'C',
                'N',
                'N/R',
                'O',
                'R',
                'W',
                'W/R'
            ],
            form: {
                submit: {
                    prescriber_uuid: '',
                    patient_id: '',
                    notes: '',
                    drugs: [],
                    doc: '',
                    administered: '',
                    chart_id: '',

                }
            },
            doseTypes:[]
        }
    },
    methods: {
        getDoseTypes(drug){
            if (drug.alias.form == 'Patch') {
                this.doseTypes = ['Patch']
            } else if(drug.alias.name.includes('drop')){
                this.doseTypes = ['drop']
            }else if(drug.alias.name.includes('cream') || drug.alias.name.includes('ointment') || drug.alias.name.includes('gel')){
                this.doseTypes = ['application']
            }else {
                this.doseTypes = [
                    drug.alias.form,
                    drug.alias.measurement_unit.split('/')[0]
                ]

                if (drug.alias.measurement_unit.split('/')[1] != 'each') {
                    this.doseTypes.push(drug.alias.measurement_unit.split('/')[1]);
                }
            }
        },
        drugSafeRetrievalId(value){
            if(value){
                console.log('this is the drug safe retrieval id in quick admin', value);
                // this.form.submit.drug_safe_retrieval = value;
                // this.submitAdmin();
                this.$emit('close', true, 'success');
            }
        },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;
                 if (this.transferType == 'Receive') {
                    axios.post('/get-drug-aliases', { drug: this.stock }, this.$store.state.header).then(res => {
                        console.log('this is the res data', res.data);
                        this.$store.dispatch('getRefreshToken');
                        res.data.drug_aliases.forEach(item=>{
                            item.full_name = `(${item.generic_name}) ${item.name}`
                        })
                        this.drugList = res.data.drug_aliases;
                        this.errorMessage = '';
                        this.loadingDrugs = false;
                    }).catch(err => {
                        this.errorMessage = err.response.data;
                        console.log('err', err.response.data);
                    })
                } else {
                    axios.post('/get-stock-items', { drug: this.stock }, this.$store.state.header).then(res => {
                        console.log('this is the res data getstockitems', res.data);
                        this.$store.dispatch('getRefreshToken');
                        res.data.forEach(item=>{
                            item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`;
                            if(item.qty_left == 0){
                                item.disabled = true;
                            } else{
                                item.disabled = false;
                            }
                        })
                        this.drugList = res.data;
                        this.errorMessage = '';
                        this.loadingDrugs = false;
                    }).catch(err => {
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                        console.log('this is the transfer stock items error', err.response.data);
                        this.errorMessage = err.response.data;
                    })
                }
            }
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        
        getPrescriber(value){
            console.log('getprescriber function is called', value);
            if(value){
                this.selectedPrescriber = value;
                this.displayReferencePrescriberModal = false;
            }
            
        },
        selectDOC(drug) {
            drug.status = document.getElementById(`doc-admin`).value;
            console.log('this is the drug doc and object', drug.status, drug);
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`, 
            // params: `query=${prescriber}&hitsPerPage=5`,
            { 
              requests:[
                { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
                { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}&facetFilters=company_uuid:${this.$store.state.company.uuid}` },
              ]  
            },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);
                    res.data.results.forEach(indexResult=>{
                        console.log('these are the results for this particular index', indexResult);
                        if(indexResult.hits.length){

                            // results.push(indexResult.hits);
                            indexResult.hits.forEach(hit=>{
                                this.prescriberResults.push(hit);
                            })
                            console.log('this is the local results varaible', this.prescriberResults);
                        }
                        
                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        removeDrugReg(drug) {
            this.drugReg = [drug];
            let submission = {
                quantity: (this.form.submit.drugs[0].qty).toString(),
                stock_item_id: this.form.submit.drugs[0].drug,
                prescriber_uuid: this.selectedPrescriber.uuid,
                patient_id: this.patient.uuid,
                notes:'',
            }
            
            if(this.$store.state.currentLocation.state == 'TAS') {
                submission.balance_confirmed = this.form.submit.drugs[0].qty_remain_confirmed
                submission.actual_balance = (this.form.submit.drugs[0].qty_remaining).toString()
            }

            this.notes = true;
            this.payload = submission;
            this.endpoint = '/drug-safe-retrieval';
            this.errorMessage = '';
            if(this.waNote.administered){
                this.payload.notes = `[ADMINISTERED- ${this.waNote.administered} ${this.dose_unit}] - [DISCARDED - ${this.waNote.discarded+' '+this.dose_unit+'.'}]`,
                this.saveContext.notes = this.payload.notes;
            }
            if(this.$store.state.currentLocation.state != 'WA'){
                this.displaySignatureModal = true;
                this.removedDrug = true;
                if (this.discard) {
                    console.log('this is the current location', `/location/${this.$store.state.currentLocation.uuid}/outgoing`)
                    this.endpoint2 = `/location/${this.$store.state.currentLocation.uuid}/outgoing`;
                    this.payload2 = {
                        notes: 'Discarding administration remainder for ' + this.patient.personal_information.first_name + ' ' + this.patient.personal_information.last_name + ': ' + this.difference ,
                        drugs: [
                            {
                                drug: this.form.submit.drugs[0].drug,
                                qty: this.discard_amount ? this.discard_amount : this.difference 
                            }
                        ],
                        lost_damaged: 'discard',
                        prescriber_uuid: this.selectedPrescriber.uuid,
                        patient_uuid: this.imprest_pom != 'imprest' ? this.patient.uuid : ''
                    }
                } else {
                    this.payload2 = '';
                }
            } else{
                console.log('this is the discared:', this.waNote.discarded);
                if(!this.waNote.administered || !this.dose_unit || (isNaN(this.waNote.discarded) || (this.waNote.discarded === null) || (this.waNote.discarded === '') )){
                    this.errorMessage = 'Administered and discarded values and dose unit must be filled in.'
                } else{
                    this.displaySignatureModal = true;
                }
            }
            // this.displaySignatureModal = true;
            
            
            
        },
        changeStock() {
            console.log('this is the pom select', this.patientMeds[document.getElementById('pom-select').value]);
            this.stock = this.patientMeds[document.getElementById('pom-select').value];
            // this.qty = Number(this.stock.quantity).toFixed(1);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
            if (this.imprest_pom != 'imprest' && !this.patient) {
                this.displayPatientSearchModal = false;
                this.imprest_pom = '';
            }
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewLocation = false;
            }
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.displayReferencePrescriberModal=false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        isSuccessful(value) {
            if (value) {
                if (!this.addNew) {
                    console.log('this is the value returned', value);
                    this.$emit('close', true, 'success');
                }
                else {
                    if (this.drugReg.length) {
                        this.removedDrug = true;
                    }
                    // this.form.submit.drugs = [];
                    this.discard_amount = '';
                    this.discard = false;
                }
                
            }
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                this.loadingDrugs = true;
                axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                    console.log('this is the patient register', res);
                    
                    res.data.forEach(item=>{
                        item.full_name = `(${item.alias.generic_name}) ${item.alias.name}`
                    })

                    this.patientMeds = res.data
                    this.loadingDrugs = false;
                }).catch(err=>{
                    console.log('this is the error', err.response.data);
                    this.errorMessage = err.response.data;
                    console.log('err', err.response.data);
                })
            }
        },
        addToPending() {
            this.form.submit.pending = !this.form.submit.pending;
        },
        onQtyRemainingChange(item) {
            console.log('Quantity remaining changed:', item.qty_remaining);
        },
        aliasSelect(item) {
            if (this.imprest_pom != 'imprest') {
                this.form.submit.drugs.unshift({ 
                    stock: item.alias.name, 
                    form: item.alias.form, 
                    entered_qty: this.qty, 
                    stock_quantity: item.quantity, 
                    drug: item.uuid, 
                    qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), 
                    qty_left: Number(item.quantity), 
                });

                console.log('item', this.form.submit.drugs[0])
            } else {
                this.form.submit.drugs.unshift({ 
                    stock: item.alias.name, 
                    form: item.alias.form, 
                    drug: item.uuid, 
                    entered_qty: this.qty, 
                    stock_quantity: item.quantity, 
                    qty: this.qty, qty_left: Number(item.qty_left), 
                });
            }

            if(this.$store.state.currentLocation.state == 'TAS') {
                const drug = this.form.submit.drugs[0];
                this.form.submit.drugs[0].qty_remain_confirmed = false 
                this.form.submit.drugs[0].qty_remaining = Number(drug.qty_left) - Number(drug.qty)
            }

            this.getDoseTypes(item);
            this.aliasId = item.alias.alias_id;
            this.qty = null;
            this.refinedDrugs = [];
        },
        checkAndAddStock(drugName) {
            if (drugName) {
                axios.post('/get-drug-aliases', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedDrugs = res.data.drug_aliases;
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                });
            }
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            console.log('this is the stock', this.stock);
            this.stock = '';
            this.form.submit.drugs.splice(index, 1);

            this.discard = false;
            this.discard_amount = '';
        },
        submitAdmin() {
            this.drugReg = [];
            console.log('this is the submitAdmin func being called')
            if (this.patient && this.form.submit.drugs.length) {
                setTimeout(()=>{
                    this.payload = {
                        patient_id: this.patient.uuid,
                        drug_safe_retrieval: this.form.submit.drug_safe_retrieval ? this.form.submit.drug_safe_retrieval: '',
                        dose: (this.form.submit.drugs[0].entered_qty).toString(),
                        notes:'',
                        stock_item_id:this.form.submit.drugs[0].drug,
                    }
                    this.notes = true;
                    this.endpoint = '/administer-standalone';
                    if (this.discard) {
                        console.log('this is the current location', `/location/${this.$store.state.currentLocation.uuid}/outgoing`)
                        this.endpoint2 = `/location/${this.$store.state.currentLocation.uuid}/outgoing`;
                        this.payload2 = {
                            notes: 'Discarding administration remainder: ' + this.discard_amount + ' else,[' + ((Number(this.form.submit.drugs[0].stock_quantity) > 1 ? 1 : Number(this.form.submit.drugs[0].stock_quantity).toFixed(2)) - Number(this.form.submit.drugs[0].qty).toFixed(2)) + ']',
                            drugs: [
                                {
                                    drug: this.aliasId,
                                    qty: this.discard_amount ? Number(this.discard_amount) : ((Number(this.form.submit.drugs[0].stock_quantity) > 1 ? 1 : Number(this.form.submit.drugs[0].stock_quantity).toFixed(2)) - Number(this.form.submit.drugs[0].qty).toFixed(2))
                                }
                            ],
                            lost_damaged: 'discard',
                            prescriber_uuid: this.selectedPrescriber.uuid,
                            patient_uuid: this.patient.uuid
                        }
                    }
                    if(this.waNote.administered){
                        this.payload.notes = `${this.form.submit.drugs[0].stock} has been administered ${this.waNote.administered}${this.dose_unit} ${this.waNote.discarded ? ' Discarded amount: '+this.waNote.discarded+' '+this.dose_unit :'.'}`
                    }
                    if(this.$store.state.currentLocation.state != 'WA'){
                        this.displaySignatureModal = true;
                    } else{
                        if(!this.waNote.administered || !this.dose_unit){
                            this.errorMessage = 'Administered value and dose unit must be filled in.'
                        }
                    }
                    
                    this.errorMessage = '';
                },100)
                
            } else {
                this.errorMessage = 'A drug must be added into this submission.'
            }
            console.log('this is the form to submit', this.form);
        },
        selectPrescriber(prescriber) {
            this.prescriberResults = [];
            this.selectedPrescriber = {
                prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
                uuid: prescriber.uuid,
                first_name: prescriber.first_name,
                last_name: prescriber.last_name,
                phone: prescriber.phone,
                after_hours: prescriber.after_hours,
                address: prescriber.address,
                email: prescriber.email,
                fax: prescriber.fax,
                primary_gp: false
            }
        },
        changePrescriber() {
            this.selectedPrescriber = '';
            this.gp = '';
        },
    },
    mounted() {
    },
    computed:{
        difference() {
            return Math.abs(Math.ceil(this.form.submit.drugs[0].entered_qty) - this.form.submit.drugs[0].entered_qty);
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
